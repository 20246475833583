import React from "react";
import NewConferenceSpelialistsList from "./NewConferenceSpelialistsList";
import { NewConferenceState } from "./NewConferenceState";
import { conciliumLink } from "../../Config";
import { NewConferenceSpelialistsSelectDateTime } from "./NewConferenceSpelialistsSelectDateTime";
import { NewConferenceRequiredAdmin } from "./NewConferenceRequiredAdmin";
import { UserService } from "../../domain/services/UserService";
import { userServiceFactory } from "../../services/UserServiceImpl";

// TODO :: pass appropriate properties
export class NewConferenceRequiredLang extends NewConferenceState {
    userService: UserService = userServiceFactory();
    static getNextStepComponentConstructor = (cart): any => {
        if (cart.requiredAdmin && !cart.skipAdmin) {
            return NewConferenceRequiredAdmin;
        }

        return NewConferenceSpelialistsSelectDateTime;
    };

    goToNextStep = async () => {
        this.cartService.cart.setSkipLang();
        await this.cartService.saveAndUpdate();

        this.props.context.transitionTo(
            NewConferenceRequiredLang.getNextStepComponentConstructor(this.cartService.cart)
        );
    };

    goToPrevStep(): void {
        this.props.context.transitionTo(NewConferenceSpelialistsList);
    }

    navigateToTranslatorSearchPage(): void {
        this.props.context.handlerCancel();
        window.location.replace(
            conciliumLink + `/search/lang?lang_auto_filter=true` + this.userService.user.getLanguageFilterStr()
        );
    }

    render() {
        const t = this.props.t;
        return (
            <div className="content content_margin-top">
                <div className="modal active" id="searchCM">
                    <div className="modal__body_ns advice_ns">
                        <div className="advice__top_ns">
                            <img src="/static/images/mm2.png" alt="" />
                            <div className="advice__top--headline_ns">
                                {t("newConferenceProcess.requiredLang.langHeader")}
                            </div>
                        </div>
                        <div className="advice__body_ns">
                            <div className="advice__body--text_ns">
                                {t("newConferenceProcess.requiredLang.langText")}
                            </div>
                            <div className="advice__buttons_ns">
                                <a onClick={() => this.goToNextStep()}>
                                    <div className="advice__button_ns">{t("newConferenceProcess.extra.next")}</div>
                                </a>
                                <a onClick={() => this.navigateToTranslatorSearchPage()}>
                                    <div className="advice__button_ns advice__button--row">
                                        {t("newConferenceProcess.requiredLang.langButton")}
                                    </div>
                                </a>
                            </div>
                            <a onClick={() => this.goToPrevStep()}>
                                <div className="advice__button_ns advice__button--orange">
                                    {t("newConferenceProcess.requiredLang.specButton")}
                                </div>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
