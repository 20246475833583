import { NotificationModel } from "../domain/models/NotificationModel";
import { BaseModelImpl } from "./BaseModelImpl";

export class NotificationModelImpl extends BaseModelImpl<NotificationModel> implements NotificationModel{
    id: string;
    userId: string;
    isPersonal: boolean;
    title: string;
    message: string;
    isRead: boolean;
    created: Date;
    localTimeCreated: Date;
}
