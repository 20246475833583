import React, { Component } from "react";
import { Cart } from "../../domain/models/Cart";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import WeekCalendar, { IWeekCalendar } from "./WeekCalendar";
import * as _ from "lodash";

export interface MultipleWeeksSwiperCalendarProps {
    cart: Cart;
    weeks: moment.Moment[][][];
    activeWeekIndex: number;
    handleTimeCellClick: (time: moment.Moment) => void;
}

export interface MultipleWeeksSwiperCalendarState {
    activeIntervalIndex: number;
    currentScrollTop: number;
}

export default class MultipleWeeksSwiperCalendar extends Component<MultipleWeeksSwiperCalendarProps, MultipleWeeksSwiperCalendarState> {
    calendarRef: any;
    swiperRef: React.RefObject<any>;
    constructor(props) {
        super(props);
        this.calendarRef = React.createRef<IWeekCalendar>();
        this.swiperRef = React.createRef<any>();
        this.state = {
            activeIntervalIndex: 0,
            currentScrollTop: 0,
        };
    }

    makeActiveSlideAndScroll = (slide) => {
        if (!slide || !slide.swiper) {
            return;
        }
        var activeIntervalIndex = slide.swiper.slides.indexOf(slide);
        slide.swiper.slideTo(activeIntervalIndex);
        const currentScrollTop = this.calendarRef.current
            ? this.calendarRef.current.scrollToInterval(activeIntervalIndex)
            : undefined;
        return { activeIntervalIndex, currentScrollTop };
    };

    onSlideClick = (event) => {
        this.setState(this.makeActiveSlideAndScroll(event.currentTarget));
    };

    visibleIntervalIndexChanged = (intervalIndex: number, currentScrollTop: number) => {
        if (!_.get(this, "swiperRef.current.swiper")) {
            return;
        }
        this.swiperRef.current.swiper.slideTo(intervalIndex);
        this.setState({ activeIntervalIndex: intervalIndex, currentScrollTop });
    };

    render() {
        // const i = this.props.i;
        return (
            <div className="calendar__body calendar__body_ns calendar__body_ns--modal">
                <Swiper
                    ref={this.swiperRef}
                    className="calendar__intervals calendar__intervals--modal"
                    spaceBetween={22}
                    slidesPerView={"auto"}
                    onTouchEnd={(sw) => {
                        const currentSlide = sw.slides[sw.activeIndex];
                        this.makeActiveSlideAndScroll(currentSlide);
                    }}
                    onInit={(sw) => {
                        sw.slides.forEach(function (slide) {
                            (slide as any).swiper = sw;
                        });
                        console.log("Swiper.onInit", this.state.activeIntervalIndex);
                    }}
                    centeredSlides={true}
                    watchSlidesProgress={true}
                    breakpoints={{
                        // when window width is >= 320px
                        320: {
                            spaceBetween: 10,
                        },
                    }}
                >
                    <SwiperSlide
                        className="calendar__interval calendar__interval-night swiper-slide"
                        onClick={this.onSlideClick}
                    >
                        00:00 - 05:30
                    </SwiperSlide>
                    <SwiperSlide
                        className="calendar__interval calendar__interval-morning swiper-slide"
                        onClick={this.onSlideClick}
                    >
                        06:00 - 11:30
                    </SwiperSlide>
                    <SwiperSlide
                        className="calendar__interval calendar__interval-day swiper-slide"
                        onClick={this.onSlideClick}
                    >
                        12:00 - 17:30
                    </SwiperSlide>
                    <SwiperSlide
                        className="calendar__interval calendar__interval-evening swiper-slide"
                        onClick={this.onSlideClick}
                    >
                        18:00 - 23:30
                    </SwiperSlide>
                </Swiper>
                {this.props.weeks.map(
                    (week, i) =>
                        i == this.props.activeWeekIndex && (
                            <WeekCalendar
                                key={`week_${i}`}
                                ref={this.calendarRef}
                                week={week}
                                handleTimeCellClick={this.props.handleTimeCellClick}
                                cart={this.props.cart}
                                visibleIntervalIndexChanged={this.visibleIntervalIndexChanged}
                                initialScrollTop={this.state.currentScrollTop}
                            ></WeekCalendar>
                        )
                )}
            </div>
        );
    }
}
