import { Skill } from "../domain/models/Skill";
import { BaseModelImpl } from "./BaseModelImpl";

export class SkillModel extends BaseModelImpl<Skill> implements Skill {
    id: string;
    userId: string;
    skill: string;
    validate: boolean;
    added: string;

    constructor(data: Skill) {
        super(data);
    }

    get isInformationComplete(): boolean {
        return !!this.skill;
    }
}
