import { ConciliumStatus } from "./domain/enums/ConciliumStatus";
import { EventType } from "./domain/enums/EventType";
import { PaymentMethod } from "./domain/enums/PaymentMethod";
import { RecordStatus } from "./domain/enums/RecordStatus";
import { SpecialistType } from "./domain/enums/SpecialistType";
import { SupportTicketCustomerRole } from "./domain/enums/SupportTicketCustomerRole";
import { SupportTicketSubject } from "./domain/enums/SupportTicketSubject";
import { TransactionStatus } from "./domain/enums/TransactionStatus";
import { BalanceType } from "./enums/balance-type-enum";
import { CurrencyType } from "./enums/currency-type-enum";
import { TransactionType } from "./enums/transaction-type-enum";

export const translations = {
    uk: {
        translation: {
            newConferenceProcess: {
                createConsultation: "Замовити консультацію",
                createConcilium: "Скликати консиліум",
                dateTime: {
                    concilium: "Дата та час проведення консиліуму",
                    consultation: "Дата та час проведення консультації",
                    at: "о",
                    edit: "Редагувати",
                    choiseDateTime: "Виберіть дату та час",
                    conciliumLabel: "консиліуму",
                    consultationLabel: "консультації",
                    dateTimeText:
                        "У таблиці відображається дата та час, який підходить для всіх учасників",
                    duration: "Тривалість",
                    hour_1: "1 година",
                    hour_2: "2 години",
                    hour_3: "3 години",
                    warning: "Ви не обрали час",
                    selectedRangeIsNotValid:
                        "Вибраний час консультації некоректний",
                },
                details: {
                    step_1: "Крок 1.",
                    basic: "Основне",
                    step_3: "Крок 3",
                    payment: "Оплата",
                    proposal: "Заявка на ",
                    concilium: "консиліум",
                    consultation: "консультацію",
                    placeholder: "Опишіть причини звернення",
                    addFile: "Прикріпити файл",
                    addAudio: "Запис звуку",
                    addVideo: "Запис відео",
                },
                extra: {
                    text: 'Для створення консультації/консиліуму Ви обрали час, який тарифікується спеціалістами як "Термінова"!',
                    next: "Продовжити",
                    prev: "Вибрати інший час",
                },
                latest: {
                    concilium: "Консиліум",
                    consultation: "Консультація",
                    text_1: "Ваша заявка на консультацію від ",
                    text_2: "року успішно направлена, і буде розглянута найближчим часом.",
                },
                payment: {
                    step_2: "Крок 2.",
                    detailsText: "Деталі",
                    consultationText: "Консультації (Кількість: ",
                    translatorText: "Послуги перекладача (Кількість: ",
                    adminText: "Послуги адміністратора (Кількість: ",
                    allText: "Усього",
                    warningText:
                        "У вас недостатньо коштів, щоб замовити вибрану консультацію/консиліум. Для завершення (продовження) оформлення замовлення просимо поповнити свій рахунок у валюті сайту - Medical Concilium Credits.",
                    warningButton: "поповнити баланс",
                    confirm:
                        "Ви підтверджуєте свій контракт, згідно з яким Ви повинні здійснити покупку послуг, перерахованих вище. Ви також підтверджуєте, що Ви маєте повну право- та дієздатність для укладення та виконання контракту, і Ви прочитали, розумієте та згодні з <1>Умовами надання послуг</1> та <3>Політикою конфіденційності</3> сайту Medical Concilium.",
                    next: "Оформити замовлення",
                },
                requiredAdmin: {
                    admin: "Адміністратор консиліуму",
                    adminHeader: "Потрібен Адміністратор консиліуму!",
                    adminText:
                        "Для покращення якості та допомоги у консультуванні пропонуємо скористатися послугами Адміністратора консиліуму. Відповідно до Умов надання послуг та Політики конфіденційності Medical Concilium, відмова від послуг Адміністратора консиліуму покладає всі зобов'язання щодо якості та повноти його проведення на ініціатора консиліуму.",
                    adminButton: "Перейти до вибору Адміністратора",
                },
                requiredLang: {
                    lang: "Перекладач",
                    langHeader: "Потрібен Перекладач!",
                    langText:
                        "Ви обрали для консультації спеціаліста, мова спілкування якого не співпадає з Вашою.",
                    langButton: "Перейти до вибору Перекладача",
                    specButton: "Вибрати іншого Медичного спеціаліста",
                },
                requiredSpec: {
                    specText:
                        "Для оформлення заявки на Консиліум/Консультацію необхідно вибрати медичних фахівців.",
                },
                smallTime: {
                    text_1: "До початку консиліуму залишилося менше",
                    text_2: "хвилин, будь ласка, виберіть інший час",
                    cancel: "Скасувати",
                    changeTime: "Вибрати інший час",
                },
                specList: {
                    header: "Редагування",
                    remove: "Виключити спеціаліста",
                    add: "Додати",
                    spec: "спеціаліста",
                    search: "Продовжити пошук фахівців",
                    next: "Готово",
                },
            },
            promocodesTabHeader: {
                header: "Промокоди",
            },
            promocodeSpecSettings: {
                promocodeFilters: {
                    valueBegin: "Кількість бонусів від",
                    valueEnd: "Кількість бонусів до",
                    createdBegin: "Створено від (UTC)",
                    createdEnd: "Створено до (UTC)",
                    isActive: {
                        all: "Усе",
                        yes: "Активні",
                        no: "Неактивні",
                    },
                    reset: "Скинути",
                    filter: "Відфільтрувати",
                    ready: "Готово",
                },
                promocodeAddModal: {
                    header: "Створити промокод",
                    changePromocodeHeader: "Змінити промокод",
                    categoryHeader: "Доступний категоріям",
                    changeName: "Змінити",
                    submit: "Відправити",
                    validation: {
                        required: "Обов'язкове поле",
                        integer: "Тільки ціле значення",
                        min1: "Мінімальне значення - 0,01",
                        min2: "Мінімальне значення - 0",
                        existingName: "Таке ім'я вже існує",
                        namePattern:
                            "Тільки латиниця верхнього регістру та цифри 0-9 (10 символів)",
                        endDate:
                            "Час завершення не може передувати часу початку",
                        exact8Characters: "Максимум 8-значне число",
                        number: "Це повинно бути число",
                    },
                },
                promocodeAddButton: {
                    buttonName: "Створити промокод",
                    buttonChangeName: "Змінити",
                },
                promocodeFields: {
                    name: "Назва",
                    type: "Тип",
                    startDate: "Початок дії",
                    endDate: "Кінець дії",
                    startDateUTCFrom: "Початок дії (UTC) від",
                    endDateUTCFrom: "Кінець дії (UTC) від",
                    startDateUTCBefore: "Початок дії (UTC) до",
                    endDateUTCBefore: "Кінець дії (UTC) до",
                    value: "Кількість бонусів",
                    isActive: {
                        isActiveHeader: "Активний",
                    },
                    userTypes: {
                        userTypesHeader: "Типи користувачів",
                        MedicalSpecialist: "Медичний спеціаліст",
                        Translator: "Перекладач",
                        Administrator: "Адміністратор",
                    },
                    usagesLimit: "Ліміт використання",
                    created: "Створений (UTC)",
                    updated: "Оновлений (UTC)",
                    usages: "Використаний",
                    actions: "Дії",
                },
            },
            paginationComponent: {
                label: "Кількість записів на сторінці",
                label2: "Кількість сторінок",
            },
            authTypeSelection: {
                text: "Для подання заявки на участь у проекті як Спеціаліст Вам необхідно зареєструватися або увійти до свого облікового запису.",
                login: "Вхід",
                registration: "Реєстрація",
            },
            specialtySelection: {
                selectCategory: "Виберіть категорію спеціаліста для реєстрації",
                oneCategory:
                    "Ви можете зареєструватися спеціалістом лише однієї категорії",
                medicalSpec: "Медичний спеціаліст",
                medicalSpecText:
                    "Фахівець з вищою медичною освітою та практичним досвідом роботи.",
                translateSpec: "Перекладач",
                translateSpecText:
                    "Наявність освіти перекладача чи лінгвіста, спеціалізація медицини.",
                adminSpec: "Адміністратор консиліуму",
                adminSpecText:
                    "Наявність медичної чи фармацевтичної освіти на рівні не нижче середньої-спеціальної.",
                user: "Продовжити як користувач",
            },
            usePromocode: {
                header: "Використати промокод",
                nameError: "Такого промокоду не існує",
                placeholder: "Промокод",
                activate: "Активувати",
                enterValue: "Введіть промокод",
            },
            errors: {
                commonErrors: {
                    max: "Максимальна довжина - ",
                    min: "Мінімальна довжина - ",
                    required: "Обов'язкове поле",
                    invalidPhoneNumber: "Не вірний формат номера телефону",
                    invalidEmail: "Не вірний формат пошти",
                    integer: "Тільки ціле число",
                },
                standardError: "Виникла помилка, спробуйте пізніше!",
                SERVICE_UNAVAILABLE: "Послуга недоступна",
                NOT_FOUND: "Не знайдено",
                UNAUTHORIZED: "Не авторизовано",

                PROMOCODE_NOT_FOUND: "Промокод не знайдено",
                PROMOCODE_IS_NOT_ACTIVE: "Промокод не активний",
                PROMOCODE_IS_NOT_APPLICABLE: "Промокод не застосовується",
                PROMOCODE_LIMIT_ERROR: "Перевищено ліміт використання",
                PROMOCODE_WITHDRAWAL_RESTRICTIONS:
                    "У зв'язку з використанням промокоду, виведення коштів призупинено",
                PROMOCODE_START_ERROR: "Дія промокоду не розпочалася",
                PROMOCODE_END_ERROR: "Дія промокоду завершилася",
                PROMOCODE_ALREADY_APPLIED_ERROR: "Промокод уже застосований",
                PROMOCODE_BONUS_ALREADY_APPLIED_ERROR:
                    "Бонусний промокод уже застосований",

                PROMOCODE_START_DATE_EDIT_PROHIBITED: "Дату міняти не можна",
                PROMOCODE_END_DATE_EDIT_PROHIBITED: "Дату міняти не можна",
                PROMOCODE_VALUE_EDIT_PROHIBITED:
                    "Кількість бонусів міняти не можна",
                PROMOCODE_TYPE_EDIT_PROHIBITED: "Тип міняти не можна",
                PROMOCODE_USAGES_LIMIT_EDIT_PROHIBITED:
                    "Ліміт використання міняти не можна",
                PROMOCODE_NAME_IS_NOT_UNIQUE: "Таке ім'я вже існує",

                USER_SPECIALIST_NOT_FOUND: "Користувач не знайдений",
                USER_SPECIALIST_DELETION_PROHIBITED:
                    "Видалити запит на спеціаліста не дозволено",
                USER_SPECIALIST_HAS_CONCILIUMS:
                    "Видалити запит на спеціаліста не дозволено, користувач є учасником консультації",

                USER_SPECIALIST_DOES_NOT_EXISTS: "Спеціаліст не існує",
                CAN_NOT_ADD_SPECIALIST: "Не можна додати спеціаліста",
                ADD_MED_SPECIALIST_FIRST: `Для використання послуг Перекладача необхідно\nзамовити Консультацію/Консиліум медичного фахівця.`,
                TRANSLATOR_ALREADY_ADDED:
                    "Брати участь у консультації/консиліумі може лише\nодин Перекладач. Ви вже обрали необхідного Перекладача!",
                NEED_MORE_MED_SPECIALISTS:
                    "Для використання послуг адміністратора консиліуму\nнеобхідно спочатку скликати консиліум. Щоб скликати консиліум, виберіть\nдвох або більше медичних фахівців.",
                ADMINISTRATOR_ALREADY_ADDED:
                    "Участь у консиліумі може лише один Адміністратор\nконсиліуму. Ви вже обрали необхідного спеціаліста!",
                SPECIALIST_ALREADY_ADDED:
                    "Спеціаліста уже додано до консиліума/консультації",
                ERROR_ADDING_SPECIALIST: "Помилка при додаванні спеціаліста",
                MAX_PARTICIPANTS_NUMBER_REACHED:
                    "Досягнута максимальна кількість учасників",
                DOES_NOT_HAVE_FREE_TIME:
                    "У спеціаліста відсутній доступний час або доступний час не пересікається з часом обраних спеціалістів.",

                CART_IS_EMPTY: "Спеціалісти не вибрані",
                CART_TRANSLATOR_REQUIRED: "Не вибраний перекладач",
                CART_ADMINISTRATOR_REQUIRED: "Не вибраний адміністратор",

                CART_STATUS_IS_NOT_DEFINED: "Статус консиліуму не визначений",
                CART_CAN_NOT_SET_STATUS:
                    "Не вдається встановити статус консиліуму",
                CART_PROCESS_IS_NOT_FINISHED:
                    "Процес створення консиліуму незавершений",
                CART_SPECIALISTS_TIME_DOES_NOT_MATCH:
                    "Час консультації не співпадає з часом спеціалістів",
                CART_SMALL_TIME_RESERVE:
                    "До початку консиліуму залишилося мало часу",
                CART_MEETING_TIME_IS_NOT_SET: "Час консультації не встановлено",

                CONCILIUM_CREATION_ERROR: "Помилка при створенні консиліуму",

                INSUFFICIENT_BALANCE: "Недостатньо коштів для виведення",
                INSUFFICIENT_TRANSIT_BALANCE:
                    "Недостатньо коштів для виведення",
                CONDITIONS_NOT_ACCEPTED:
                    "Користувач не прийняв умови контракту",

                WRONG_USER_DATA: "Пароль некоректний",
                undefined: "Сталася помилка",
                USR_INCOMPLETE_INFORMATION:
                    "Обов'язкова інформація не заповнена",
                FILE_SIZE_IS_TOO_BIG:
                    "Розмір файлу занадто великий. Максимальний розмір файлу {{maxFileSize}} MB.",
                FILE_EXTENSION_IS_NOT_ALLOWED:
                    "Це розширення файлу не підтримується, спробуйте наступні: {{allowedExtensions}}",
                TOO_MANY_FILES:
                    "Забагато файлів, можливо додати лише {{maxFilesCount}}",
                FILE_IS_EMPTY: "Цей файл порожній.",
                UR_USER_ALREADY_EXIST: "Користувач вже зареєстрований.",
                UR_EMAIL_ALREADY_EXIST:
                    "Ця пошта вже використовується іншим користувачем.",
                UR_LANGUAGE_DOES_NOT_EXIST: "Мову не знайдено.",
                UR_CURRENCY_DOES_NOT_EXIST: "Валюту не знайдено.",
                UR_COUNTRY_DOES_NOT_EXIST: "Країну не знайдено.",
                UL_INVALID_DATA: "Невірний логін або пароль.",
                UL_RECAPTCHA_FAILED: "Підтвердіть, що ви не робот.",
                UL_EMAIL_NOT_ACTIVATED: "Активуйте пошту.",
                UL_USER_WAS_DEACTIVATED: "Профіль деактивовано.",
            },
            general: {
                saveBtn: "Зберегти",
                restartBtn: "Перезапустити",
                email: "Email",
                skip: "Пропустити",
                delete: "Видалити",
                confirm: "Підтвердити",
                cancel: "Скасувати",
                information: "Інформація",
                continue: "Продовжити",
                closeBtn: "Закрити",
                status: "Статус",
                details: "Деталі",
                yes: "Так",
                no: "Ні",
                exitBtn: "Вихід",
                actions: "Дії",
                consultationConcilium: "Консультація/Консиліум",
                profit: "Прибуток",
                penalty: "Штраф",
                createdUTC: "Дата та час (UTC)",
                currencyType: "Валюта",
                accept: "Прийняти",
                deny: "Скасувати",
                ok: "OK",
            },
            conciliumStatus: {
                [ConciliumStatus.NONE]: "NONE",
                [ConciliumStatus.INITIATED]: "Розглядається",
                [ConciliumStatus.SPECIALIST_APPROVED]: "Схвалена",
                [ConciliumStatus.SPECIALIST_REFUSED]: "Відхилена",
                [ConciliumStatus.FINALIZED]: "Завершена",
                [ConciliumStatus.CANCELED]: "Скасована",
                [ConciliumStatus.INJURED]: "Пошкоджена",
            },
            specSettings: {
                cashOutButton: "Зняти кошти",
                cashOutModal: {
                    header: "Запит на зняття коштів",
                    amount: "Сума",
                    note: "Як Вы зможете прийняти кошти?",
                    send: "Направити",
                    payoneerId: "Payoneer Id",
                    skrillId: "Skrill Id",
                    validations: {
                        required: "Обов'язкове!",
                        addNotes: "Додайте опис!",
                        greaterThenZero: "Має бути більше за 0!",
                        exact8Characters: "Має бути 8 символів!",
                        exact9Characters: "Має бути 9 символів!",
                        cashOutError: "Виникла помилка, спробуйте пізніше!",
                    },
                    promocode: {
                        header: "Використаний промокод",
                        name: "Промокод",
                        value: "Сума",
                        confirmedError:
                            "У зв'язку з використанням промокоду, виведення коштів призупинено",
                    },
                    email: "Email чи інший ідентифікатор отримувача",
                    conditionsAccepted:
                        "Ви підтверджуєте, що маєте повну право- та дієздатність, прочитали, зрозуміли та погоджуєтесь з <1>Умовами надання послуг</1> та <3>Політикою конфіденційності</3> Medical Concilium, а також надаєте згоду на обробку особистих даних відповідно до них. Ви також погоджуєтесь на стягненням комісії, що може бути утримана сторонніми організаціями за виконання платежу.",
                },
                accountBallance: {
                    current: "Поточний баланс",
                    wallet: "Гаманець",
                },
                cashInButton: "поповнити баланс",
                cashInModal: {
                    header: "Поповнення балансу",
                    add: "Поповнити",
                },
            },
            userSettings: {
                deactivateUserAccount: {
                    deactivate: "Деактивація",
                    deactivateAccount: "Деактивувати акаунт",
                    deactivateAccount2: "Деактивувати",
                    deactivateMessage:
                        "Після деактивації Ви не зможете увійти до кабінету та здійснювати діяльність у цьому профілі на порталі Medical Consilium!",
                    deactivateMessage2:
                        "Ви впевнені? Після деактивації Ваш обліковий запис буде видалено. Видалені облікові записи не підлягають відновленню.",
                    passwordPlaceholder: "пароль",
                    deactivateUserMessage1: "Ви деактивуєте користувача ",
                    deactivateUserMessage2:
                        "Ви впевнені? Після деактивації цей обліковий запис буде видалено. Видалені облікові записи не підлягають відновленню.",
                },
            },
            specRequest: {
                deactivationSectionDescription:
                    "Ви можете скасувати заявку і видалити всі пов'язані з нею дані. Будьте обережні, після завершення операції відновити дані буде неможливо.",
                deactivationConfirmation:
                    "Після підтвердження всі дані будуть видалені. Продовжити?",
                deleteRequest: "Видалити запит",
                requestCanNotBeDeleted: "Запит не може бути видалений.",
                requestTitle: "Заявки",
                confirmationModal: {
                    header: "Надіслати запит?",
                    body: "<0>У Вашій заявці на участь в проекті Medical Concilium в якості Спеціаліста є незаповненими розділи, що не є обов'язковими.</0><1>Ви можете направити заявку з таким об’ємом інформації, або доповнити її.</1><2>Для швидкого та  успішного погодження Вашої заявки, радимо заповнити якомога більше інформації та додати файли, що її підтверджують. Це суттєво підвищуватиме Ваш рейтинг та популярність як Спеціаліста проекту, збільшуватиме кількість звернень до Вас з боку Користувачів.</2>",
                    send: "Направити",
                    append: "Доповнити",
                },
                specRequestSentModal: {
                    header: "Запит надіслано",
                    body: "Ваша заявка на участь в проекті у якості {{specialtyTypeLabel}} успішно направлена. За результатами розгляду заявки Вас буде проінформовано.",
                },
                errorModal: {
                    requirements: `
                    Для подачі заявки на участь в проекті в якості Спеціаліста мінімальним є заповнення обов'язкових розділів:
                    <1>
                    <0>Фото</0>
                    <2>Спеціалізація</2>
                    <3>Знання мов</3>
                    <4>Про себе</4>
                    <5>Освіта</5>
                    <6>Місце роботи</6>
                    <7>Вартість послуг</7>
                    </1>
                    Для швидкого та  успішного погодження Вашої заявки, радимо заповнити якомога більше інформації та додати файли, що її підтверджують.
                    Це може суттєво підвищувати Ваш рейтинг та популярність як Спеціаліста проекту, та збільшувати кількість звернень до Вас з боку Користувачів.

                    `,
                },
            },
            payments: {
                transactionsTimeout: "Оплата буде доступна через",
                paymentFailedToCreate: "Платіж не створено.",
                paymentFailed: "Виникла помилка при створенні платежу.",
                paymentCreated:
                    "Платіж створено але не завершено. Зверніться до Адміністратора для перевірки стану платежу.",
                paymentWasNotCreated: "Платіж не створено. Спробуйте ще раз.",
                fields: {
                    accountId: "Ідентифікатор платника",
                    transactionType: "Тип транзакції",
                    userId: "Користувач",
                    toUserId: "Суб'єкт",
                    total: "Сума",
                    balanceFromType: "Тип рахунку відправника",
                    balanceFromBefore: "Рахунок відправника до",
                    balanceFromAfter: "Рахунок відправника після",
                    balanceToType: "Тип рахунку одержувача",
                    balanceToBefore: "Рахунок одержувача до",
                    balanceToAfter: "Рахунок одержувача після",
                    paymentMethod: "Платіжний метод",
                    userSpecialistsPromocodeId: "Промокод",
                    paymentTransactionId: "ID транзакції платіжної системи",
                    isReverted: "Повернута",
                    desiredTotal: "Очікувана сума",
                    adminId: "Адміністратор",
                    decisionDate: "Дата та час прийняття рішення (UTC)",
                    adminComment: "Коментар адміністратора",
                    conditionsAccepted: "Умови користування прийняті",
                    requestLocale: "Мова запиту на виведення коштів",
                    relatedTransactionId: "ID пов'язаної транзакції",
                },
                admin: {
                    detailsHeader: "Деталі Транзакції",
                    generalSection: "Загальне",
                    balancesSection: "Баланси",
                    paymentMethodSection: "Платіжний метод",
                    adminApprovalSection: "Рішення Адміністратора",
                    editPopup: {
                        title: "Завершити Транзакцію",
                    },
                },
            },
            specialistSearch: {
                translatorAlreadyAdded: "Ви вже обрали Перекладача!",
                selectAnotherTranslator: "Обрати іншого Перекладача",
                administratorAlreadyAdded:
                    "Ви вже обрали aдміністратора консиліуму!",
                selectAnotherAdministrator: "Вибрати іншого Адміністратора",
            },
            admin: {
                cashOutPage: {
                    minMessageLength:
                        "Повідомлення має містити більше {{length}} символів",
                    maxPaymentTransactionIdLength:
                        "ID транзакції має містити менше {{length}} символів",
                },
                concilium: {
                    details: {
                        header: "Хронологія Консультації/Консиліуму",
                    },
                },
                eventsTab: {
                    eventsType: "Подія",
                    hasMetaData: "Метадані",
                    details: {
                        header: "Подія",
                        closedConciliums:
                            "Закриті завислі консиліуми (фахівці не відповіли)",
                        finalizedConciliums:
                            "Завершені консиліуми (завершені в кінці часу консультації)",
                        refusedConciliums:
                            "Автоматично відмовлено (не долучилися медичні спеціалісти)",
                        refuseCandidatesConciliums:
                            "Консиліуми, перевірені на автоматичну відмову",
                    },
                },
                platformSettings: {
                    fields: {
                        penaltyUserMin: "Мінімум для користувача",
                        penaltyUserPercent: "Відсоток для користувача",
                        penaltySiteMin: "Мінімум для сайтоутримувача",
                        penaltySitePercent: "Відсоток для сайтоутримувача",
                        platformFee: "Відсоток маржі платформи",
                    },
                    common: {
                        header: "Налаштування платформи",
                        penaltyTitle: "Коефіцієнти штрафів",
                        platformFeeTitle: "Винагорода платформи",
                        submitButtonLabel: "Оновити",
                        success: "Налаштування оновлені",
                    },
                },
            },
            formErrors: {
                required: "Обов'язкове!",
                requiredPassword: "Пароль - це обов'язкове поле",
            },
            fields: {
                firstName: "Ім'я",
                phoneNumber: "Телефон",
                description: "Текст повідомлення",
                comment: "Коментар",
                language: "Мова",
                created: "Дата створення",
                updated: "Дата модифікації",
            },
            enums: {
                supportTicketCustomerRole: {
                    [SupportTicketCustomerRole.GUEST]: "Користувач",
                    [SupportTicketCustomerRole.SPECIALIST]: "Спеціаліст",
                },
                supportTicketSubject: {
                    [SupportTicketSubject.REGISTRATION]: "Реєстрація",
                    [SupportTicketSubject.CONCILIUMS]:
                        "Консультації/Консиліуми",
                    [SupportTicketSubject.PAYMENTS]: "Платежі",
                    [SupportTicketSubject.FEES]: "Штрафи/Пені",
                    [SupportTicketSubject.OFFER]: "Пропозиції",
                    [SupportTicketSubject.TROUBLESHOOT]:
                        "Усунення несправностей",
                    [SupportTicketSubject.COLLABORATION]: "Співпраця",
                    [SupportTicketSubject.ADVERTIZING]: "Реклама",
                    [SupportTicketSubject.ARBITRATION]: "Арбітраж",
                    [SupportTicketSubject.OTHER]: "Інше",
                },
                recordStatus: {
                    [RecordStatus.CREATED]: "Створений",
                    [RecordStatus.APPROVED]: "Підтверджений",
                    [RecordStatus.DECLINED]: "Скасований",
                    [RecordStatus.IN_REVIEW]: "Розглядається",
                },
                transactionType: {
                    [TransactionType.BALANCE_REPLENISHMENT]:
                        "Поповнення балансу",
                    [TransactionType.RESERVATION]: "Резервування коштів",
                    [TransactionType.SERVICE_PAYMENT]: "Оплата послуги",
                    [TransactionType.SERVICE_RECEIPT]: "Надходження за послугу",
                    [TransactionType.WITHDRAW]: "Виведення коштів",
                    [TransactionType.CANCEL_RESERVATION]:
                        "Скасування резервації",
                    [TransactionType.PAYMENT_TO_SITE_OWNER]:
                        "Переклад сайтоутримувачу",
                    [TransactionType.PENALTY]: "Штраф",
                    [TransactionType.ARBITRATION]: "Арбітраж",
                    [TransactionType.COMPENSATION]: "Компенсація",
                    [TransactionType.PROMOCODE_APPLIED]: "Промокод застосовано",
                    [TransactionType.PROMOCODE_REMOVED]: "Промокод видалено",
                    [TransactionType.CANCEL_WITHDRAW_RESERVATION]:
                        "Скасування резервування виведення коштів",
                    [TransactionType.WITHDRAW_RESERVATION]:
                        "Резервування коштів для виведення",
                },
                balanceType: {
                    [BalanceType.MAIN_BALANCE]: "Основний рахунок",
                    [BalanceType.TRANSIT]: "Транзитний рахунок",
                    [BalanceType.PROFIT]: "Прибуток",
                    [BalanceType.PENALTY]: "Штрафний рахунок",
                    [BalanceType.PROMOCODE]: "Промокод",
                },
                paymentMethod: {
                    [PaymentMethod.NONE]: "Валюта платформи",
                    [PaymentMethod.PAYPAL]: "PayPal",
                    [PaymentMethod.PAYONEER]: "Payoneer",
                    [PaymentMethod.SKRILL]: "Skrill",
                },
                transactionStatus: {
                    [TransactionStatus.IN_PROGRESS]: "В обробці",
                    [TransactionStatus.WAITING]: "Очікує",
                    [TransactionStatus.COMPLETED]: "Завершена",
                    [TransactionStatus.CANCELED]: "Скасована",
                    [TransactionStatus.WAITING_FOR_TRANSFER]: "Обробляється",
                },
                currencyType: {
                    [CurrencyType.USD]: "USD",
                    [CurrencyType.EUR]: "EUR",
                    [CurrencyType.PLN]: "PLN",
                },
                asSpecialistType: {
                    [SpecialistType.MEDICAL_SPECIALIST]:
                        "Медичного спеціаліста",
                    [SpecialistType.TRANSLATOR]: "Перекладача",
                    [SpecialistType.ADMINISTRATOR]: "Адміністратора консиліуму",
                },
                eventType: {
                    [EventType.CRON_CONCILIUM]:
                        "Автоматичне завершення консультацій",
                    [EventType.CRON_GENERATE_VIDEO_TOKENS]:
                        "Автоматичнагенерація сесій відеоконференції",
                },
            },
            customerSupport: {
                fields: {
                    lastName: "Прізвище (звернення)",
                    middleName: "По-батькові (не обов'язково)",
                    description: "Текст повідомлення",
                    email: "E-mail (якщо Ви зареєстровані - вкажіть E-mail вказаний при реєстрації)",
                    country: "Країна\\Регіон",
                    customerRole: "Ви звертаєтеся як (виберіть необхідне)",
                    subjectType: "Оберіть тему питання, яке Вас цікавить",
                    ticketName: "Назва",
                },
                createTicketForm: {
                    header: "Зв'яжіться з нами",
                    conditionsAccepted:
                        "Поставивши цей прапорець, ви погоджуєтеся з <1>Умовами надання послуг</1> та <3>Політикою конфіденційності</3> сайту, а також даєте згоду зв'язуватися з Вами телефоном та електронною поштою щодо Вашого звернення. Ми будемо обробляти Ваші дані відповідно до нашої Політики конфіденційності.",
                    sendButton: "Надіслати",
                    successMessage:
                        "Ваше звернення {{ticketName}} спрямоване в службу підтримки. Ми зв'яжемося з вами за допомогою електронної пошти яка була вказана при реєстрації звернення.",
                    successTitle: "Звернення створене",
                    successButton: "OK",
                },
                adminTab: {
                    header: "Питання користувачів",
                    table: {
                        ticketName: "Назва",
                        subjectType: "Тема",
                        fullName: "Ім'я користувача",
                        customerRole: "Роль користувача",
                        emailSent: "Email надіслано",
                        isCustomerRegistered: "Користувач авторизований",
                        created: "Дата створення",
                        isEmailRegistered: "Email зареєстрований",
                        updated: "Дата модифікації",
                    },
                    sendEmailBtn: "Надіслати Email",
                    changeStatusBtn: "Змінити статус",
                    editPopup: {
                        title: "Редагувати Звернення",
                    },
                    detailsHeader: "Деталі Звернення",
                },
            },
            maxSpecialistsModal: {
                edit: "Редагувати",
            },
            registration: {
                common: {
                    loginLabel: "Вхід",
                    registrationLabel: "Реєстрація",
                    generalLabel: "Загальне",
                    birthdayLabel: "Вкажіть дату народження",
                    timezoneLabel: "Часовий пояс",
                    languageLabel: "Мова спілкування",
                    currencyLabel: "Валюта",
                    timezoneInfo:
                        "Інформація про час відображається згідно зазначеного Вами місця розташування.",
                    languageInfo:
                        "Оберіть мови, якими Ви володієте на достатньому рівні для повноцінного проведення консультації без залучення перекладача.",
                    currencyInfo:
                        "Вказана ​​вартість і оплата продуктів на сайті проводяться у валюті проекту - Medical Concilium Credits. Усі перерахунку в обрану Вами валюту будуть приблизними і носять виключно інформаційний характер.",
                    passwordLabel: "Пароль",
                    submitButtonLabel: "Зареєструватися",
                },
                fields: {
                    name: "Ім'я",
                    surname: "Прізвище",
                    patronimyc: "По батькові",
                    email: "Пошта",
                    phone: "Телефон",
                    city: "Місто",
                    country: "Країна",
                    currency: "Валюта",
                    language: "Мова",
                    timezone: "Часовий пояс",
                    birthday: "Дата народження",
                    password: "Пароль",
                    confirmPassword: "Повторити пароль",
                },
                errors: {
                    age: "Вам повинно бути більше {{age}} років",
                    passwordMismatch: "Паролі не співпадають",
                },
                successModal: {
                    title: "Вітаємо!",
                    info: "Ви успішно зареєструвалися на проєкті Medical Concilium.",
                    mainLabel: "Головна",
                    loginLabel: "Вхід",
                },
                selectPlaceholders: {
                    country: "Оберіть країну",
                    currency: "Оберіть валюту",
                    language: "Оберіть мову",
                    timezone: "Оберіть часовий пояс",
                },
            },
            login: {
                common: {
                    loginLabel: "Вхід",
                    registrationLabel: "Реєстрація",
                    emailLabel: "Вкажіть пошту",
                    passwordLabel: "Вкажіть пароль",
                    forgotPasswordLabel: "Забули пароль?",
                    submitButtonLabel: "Увійти",
                },
                fields: {
                    email: "Пошта",
                    password: "Пароль",
                },
            },
            mainHeader: {
                featuresBtn: "Особливості",
                chooseSpecBtn: "Вибрати спеціаліста",
                requestBtn: "Заявки",
                becomeSpecBtn: "Стати фахівцем",
                languages: {
                    ru: "Російська",
                    uk: "Українська",
                    en: "Англійська",
                    pl: "Польська",
                },
                userOptions: {
                    consults: "Консультації",
                    favorites: "Обране",
                    settings: "Налаштування",
                    support: "Допомога",
                    logout: "Вийти",
                },
                login: "Вхід",
                registration: "Реєстрація",
                search: "Пошук",
                notifications: {
                    personal: "Особисті",
                    public: "Загальні",
                    showAll: "Показати всі повідомлення",
                },
            },
            project: {
                name: "Medical Concilium",
            },
            mainPage: {
                title: "Можливості проекту",
                searchSpec: "Замовити консультацію",
                searchConc: "Скликати консиліум",
                howItWorks: "Як це працює?",
                start: "Почати роботу",
            },
            footer: {
                aboutUs: "Про нас",
                features: "Особливості",
                articles: "Спільнота",
                support: "Підтримка",
                slogan: "Поєднуємо знання найкращих, щоб дарувати здоров'я!",
                spec: "Замовити консультацію",
                conc: "Скликати консиліум",
                login: "Вхід",
                register: "Реєстрація",
                termsOfUse: "Умови використання",
                privacyPolicy: "Політика конфіденційності",
                fullVersion: "Повна версія сайту",
                right: {
                    medSpec: "Медичний спеціаліст",
                    lang: "Перекладач",
                    admin: "Адміністратор консиліуму",
                },
            },
            notFound: {
                title: "404",
                message: "Сторінка, яку ви шукаєте, не знайдена.",
                link: "Повернутися на головну",
            },
        },
    },
    en: {
        translation: {
            newConferenceProcess: {
                createConsultation: "Order a consultation",
                createConcilium: "Call a council",
                dateTime: {
                    concilium: "Date and time of the conference",
                    consultation: "Date and time of the consultation",
                    at: "at",
                    edit: "Edit",
                    choiseDateTime: "Select date and time",
                    conciliumLabel: "council",
                    consultationLabel: "consultations",
                    dateTimeText:
                        "The table shows the date and time that is suitable for all participants",
                    duration: "Duration",
                    hour_1: "1 hour",
                    hour_2: "2 hours",
                    hour_3: "3 hours",
                    warning: "You didn't choose the time",
                    selectedRangeIsNotValid:
                        "The selected consultation time is incorrect",
                },
                details: {
                    step_1: "Step 1.",
                    basic: "Main",
                    step_3: "Step 3.",
                    payment: "Payment",
                    proposal: "Application for ",
                    concilium: "council",
                    consultation: "consultation",
                    placeholder: "Describe the reasons for the appeal",
                    addFile: "Attach file",
                    addAudio: "Sound recording",
                    addVideo: "Video recording",
                },
                extra: {
                    text: 'To create a consultation / consultation, you have chosen the time that is billed by specialists as "Urgent"!',
                    next: "Proceed",
                    prev: "Choose another time",
                },
                latest: {
                    concilium: "Council",
                    consultation: "Consultation",
                    text_1: "Your request for a consultation ",
                    text_2: "successfully submitted and will be reviewed shortly.",
                },
                payment: {
                    step_2: "Step 2.",
                    detailsText: "Details",
                    consultationText: "Consultations (Quantity: ",
                    translatorText: "Translation Services (Quantity: ",
                    adminText: "Administrator Services (Quantity: ",
                    allText: "Total",
                    warningText:
                        "You do not have enough funds to order the selected consultation / consultation. To complete (continue) placing an order, please replenish your account in the currency of the site - Medical Concilium Credits.",
                    warningButton: "replenish the balance",
                    confirm:
                        "You confirm your contract, according to which you must make the purchase of the services listed above. You also acknowledge that you have full legal capacity to enter into and perform the contract, and you have read, understand and agree to the <1>Terms of Use</1> and the <3>Privacy Policy</3> of the Medical Concilium website.",
                    next: "Checkout",
                },
                requiredAdmin: {
                    admin: "Council administrator",
                    adminHeader: "Council Administrator Needed!",
                    adminText:
                        "To improve the quality and help in consulting, we suggest using the services of the Council Administrator. In accordance with the Terms of Service and the Privacy Policy of the Medical Concilium, the refusal of the services of the Administrator of the consultation places all obligations for the quality and completeness of its conduct on the initiator of the consultation.",
                    adminButton: "Go to Administrator selection",
                },
                requiredLang: {
                    lang: "Translator",
                    langHeader: "Need a translator!",
                    langText:
                        "You have chosen a specialist for consultation whose communication language does not match yours.",
                    langButton: "Go to Translator selection",
                    specButton: "Choose another Medical Specialist",
                },
                requiredSpec: {
                    specText:
                        "To apply for a Consilium / Consultation, you must select medical specialists.",
                },
                smallTime: {
                    text_1: "Before the start of the consultation, less than",
                    text_2: "minutes, please select another time",
                    cancel: "Cancel",
                    changeTime: "Choose another time",
                },
                specList: {
                    header: "Editing",
                    remove: "Exclude Specialist",
                    add: "Add",
                    spec: "specialist",
                    search: "Continue searching for specialists",
                    next: "Ready",
                },
            },
            promocodesTabHeader: {
                header: "Promocodes",
            },
            promocodeSpecSettings: {
                promocodeFilters: {
                    valueBegin: "Number of bonuses from",
                    valueEnd: "Number of bonuses up to",
                    createdBegin: "Created from (UTC)",
                    createdEnd: "Created before (UTC)",
                    isActive: {
                        all: "Everything",
                        yes: "Active",
                        no: "Inactive",
                    },
                    reset: "Reset",
                    filter: "Filter",
                    ready: "Ready",
                },
                promocodeAddModal: {
                    header: "Create promocode",
                    changePromocodeHeader: "Change promocode",
                    categoryHeader: "Available to categories",
                    changeName: "Change",
                    submit: "Send",
                    validation: {
                        required: "Required field",
                        integer: "Only integer value",
                        min1: "The minimum value - 0.01",
                        min2: "The minimum value - 0",
                        existingName: "Such a name already exists",
                        namePattern:
                            "Only uppercase Latin letters and numbers 0-9 (10 characters)",
                        endDate: "End time cannot be before start time",
                        exact8Characters: "Maximum 8-digit number",
                        number: "This must be a number",
                    },
                },
                promocodeAddButton: {
                    buttonName: "Create a promocode",
                    buttonChangeName: "Change",
                },
                promocodeFields: {
                    name: "Name",
                    type: "Type",
                    startDate: "Begin of action",
                    endDate: "End of action",
                    startDateUTCFrom: "Begin of action (UTC) from",
                    endDateUTCFrom: "End of action (UTC) from",
                    startDateUTCBefore: "Begin of action (UTC) before",
                    endDateUTCBefore: "End of action (UTC) before",
                    value: "Quantity of bonuses",
                    isActive: {
                        isActiveHeader: "Active",
                    },
                    userTypes: {
                        userTypesHeader: "User types",
                        MedicalSpecialist: "MedicalSpecialist",
                        Translator: "Translator",
                        Administrator: "Administrator",
                    },
                    usagesLimit: "Usages Limit",
                    created: "Created (UTC)",
                    updated: "Updated (UTC)",
                    usages: "Usages",
                    actions: "Actions",
                },
            },
            paginationComponent: {
                label: "Quantity of records per page",
                label2: "Number of pages",
            },
            authTypeSelection: {
                text: "To apply for the project as a Specialist, you need to register or log in to your account.",
                login: "Entry",
                registration: "Registration",
            },
            specialtySelection: {
                selectCategory: "Select a specialist category to register",
                oneCategory:
                    "You can only register as a specialist in one category",
                medicalSpec: "Medical specialist",
                medicalSpecText:
                    "Specialist with higher medical education and practical experience.",
                translateSpec: "Translator",
                translateSpecText:
                    "Education of a translator or linguist, specialization in medicine.",
                adminSpec: "Council administrator",
                adminSpecText:
                    "Presence of medical or pharmaceutical education at a level not lower than secondary special education.",
                user: "Continue as user",
            },
            usePromocode: {
                header: "Use a promocode",
                nameError: "There is no such promocode",
                placeholder: "Promocode",
                activate: "Activate",
                enterValue: "Enter the promo code",
            },
            errors: {
                commonErrors: {
                    max: "Maximum length - ",
                    min: "Minimum length - ",
                    required: "Required field",
                    invalidPhoneNumber: "Phone number format is incorrect",
                    invalidEmail: "Email format is incorrect",
                    integer: "Only integer value",
                },
                standardError: "An error occurred, please try again later!",
                SERVICE_UNAVAILABLE: "Service unavailable",
                NOT_FOUND: "Not found",
                UNAUTHORIZED: "Not authorized",

                PROMOCODE_NOT_FOUND: "Promocode not found",
                PROMOCODE_IS_NOT_ACTIVE: "The promocode is not active",
                PROMOCODE_IS_NOT_APPLICABLE: "Promocode does not apply",
                PROMOCODE_LIMIT_ERROR: "Usage limit exceeded",
                PROMOCODE_WITHDRAWAL_RESTRICTIONS:
                    "Due to the use of the promotional code, the withdrawal of funds is suspended",
                PROMOCODE_START_ERROR: "The promocode has not started",
                PROMOCODE_END_ERROR: "The promocode has expired",
                PROMOCODE_ALREADY_APPLIED_ERROR:
                    "The promocode has already been applied",
                PROMOCODE_BONUS_ALREADY_APPLIED_ERROR:
                    "The bonus promocode has already been applied",

                PROMOCODE_START_DATE_EDIT_PROHIBITED: "Date cannot be changed",
                PROMOCODE_END_DATE_EDIT_PROHIBITED: "Date cannot be changed",
                PROMOCODE_VALUE_EDIT_PROHIBITED:
                    "The value of bonuses cannot be changed",
                PROMOCODE_TYPE_EDIT_PROHIBITED: "Type cannot be changed",
                PROMOCODE_USAGES_LIMIT_EDIT_PROHIBITED:
                    "The usage limit cannot be changed",
                PROMOCODE_NAME_IS_NOT_UNIQUE: "Such a name already exists",

                USER_SPECIALIST_NOT_FOUND: "User not found",
                USER_SPECIALIST_DELETION_PROHIBITED:
                    "It is not allowed to delete a request for a specialist",
                USER_SPECIALIST_HAS_CONCILIUMS:
                    "It is not allowed to delete a request for a specialist, the user is a participant in the consultation",

                USER_SPECIALIST_DOES_NOT_EXISTS: "Specialist does not exists",
                CAN_NOT_ADD_SPECIALIST: "You cannot add a specialist",
                ADD_MED_SPECIALIST_FIRST:
                    "To use the services of an Interpreter, you must first\norder a Consultation/Consilium of a medical specialist.",
                TRANSLATOR_ALREADY_ADDED:
                    "Only one Translator can participate in the consultation/concilium.\nYou have already chosen the required Translator!",
                NEED_MORE_MED_SPECIALISTS:
                    "To use the services of the Council Administrator, you must first\nconvene a council. To convene a council, select two\nor more Medical Specialists.",
                ADMINISTRATOR_ALREADY_ADDED:
                    "Only one Council Administrator can participate in the council.\nYou have already chosen the necessary specialist!",
                SPECIALIST_ALREADY_ADDED:
                    "The specialist has already been added to the consultation/concilium.",
                ERROR_ADDING_SPECIALIST: "Error adding specialist",
                MAX_PARTICIPANTS_NUMBER_REACHED:
                    "The maximum number of participants has been reached",
                DOES_NOT_HAVE_FREE_TIME:
                    "The specialist has no available time or the available time does not overlap with the time of the selected specialists.",

                CART_IS_EMPTY: "Specialists are not selected",
                CART_TRANSLATOR_REQUIRED: "No translator selected",
                CART_ADMINISTRATOR_REQUIRED: "No administrator selected",

                CART_STATUS_IS_NOT_DEFINED:
                    "The status of the council is not defined",
                CART_CAN_NOT_SET_STATUS: "Unable to establish consilium status",
                CART_PROCESS_IS_NOT_FINISHED:
                    "The process of creating a consilium is incomplete",
                CART_SPECIALISTS_TIME_DOES_NOT_MATCH:
                    "Consultation time does not coincide with specialist time",
                CART_SMALL_TIME_RESERVE:
                    "There is little time left before the start of the conference",
                CART_MEETING_TIME_IS_NOT_SET:
                    "The consultation time has not been set",

                CONCILIUM_CREATION_ERROR:
                    "An error occurred when creating a consilium",

                INSUFFICIENT_BALANCE: "Insufficient funds to withdraw",
                INSUFFICIENT_TRANSIT_BALANCE: "Insufficient funds to withdraw",
                CONDITIONS_NOT_ACCEPTED:
                    "The user has not accepted the terms of the contract",
                WRONG_USER_DATA: "The password is incorrect",
                undefined: "An error occurred",
                USR_INCOMPLETE_INFORMATION:
                    "Required information is not filled in",
                FILE_SIZE_IS_TOO_BIG:
                    "The file size is too large. The maximum file size is {{maxFileSize}} MB.",
                FILE_EXTENSION_IS_NOT_ALLOWED:
                    "This file extension is not supported, try the following: {{allowedExtensions}}",
                TOO_MANY_FILES:
                    "Too many files, only {{maxFilesCount}} can be added",
                FILE_IS_EMPTY: "This file is empty.",
                UR_USER_ALREADY_EXIST: "The user is already registered.",
                UR_EMAIL_ALREADY_EXIST:
                    "This email is already in use by another user.",
                UR_LANGUAGE_DOES_NOT_EXIST: "Language not found.",
                UR_CURRENCY_DOES_NOT_EXIST: "Currency not found.",
                UR_COUNTRY_DOES_NOT_EXIST: "Country not found.",
                UL_INVALID_DATA: "Invalid login or password.",
                UL_RECAPTCHA_FAILED: "Confirm that you are not a robot.",
                UL_EMAIL_NOT_ACTIVATED: "Activate your mail.",
                UL_USER_WAS_DEACTIVATED: "Profile deactivated.",
            },
            general: {
                saveBtn: "Save",
                restartBtn: "Restart",
                email: "Email",
                skip: "Skip",
                delete: "Delete",
                confirm: "Confirm",
                cancel: "Cancel",
                information: "Information",
                continue: "Continue",
                closeBtn: "Close",
                status: "Status",
                details: "Details",
                yes: "Yes",
                no: "No",
                exitBtn: "Exit",
                actions: "Actions",
                consultationConcilium: "Consultation/Consilium",
                profit: "Profit",
                penalty: "Penalty",
                createdUTC: "Date and time (UTC)",
                currencyType: "Currency",
                accept: "Accept",
                deny: "Deny",
                ok: "OK",
            },
            conciliumStatus: {
                [ConciliumStatus.NONE]: "NONE",
                [ConciliumStatus.INITIATED]: "Initiated",
                [ConciliumStatus.SPECIALIST_APPROVED]: "Approved",
                [ConciliumStatus.SPECIALIST_REFUSED]: "Refused",
                [ConciliumStatus.FINALIZED]: "Finalized",
                [ConciliumStatus.CANCELED]: "Canceled",
                [ConciliumStatus.INJURED]: "Injured",
            },
            specSettings: {
                cashOutButton: "Withdraw funds",
                cashOutModal: {
                    header: "Withdrawal application",
                    amount: "Amount",
                    note: "How can you receive funds?",
                    send: "Send",
                    payoneerId: "Payoneer Id",
                    skrillId: "Skrill Id",
                    validations: {
                        required: "Required!",
                        addNotes: "Please add notes!",
                        greaterThenZero: "Should be more then 0!",
                        exact8Characters: "Must be exactly 8 characters",
                        exact9Characters: "Must be exactly 9 characters",
                        cashOutError:
                            "An error occurred, please try again later!",
                    },
                    promocode: {
                        header: "Promocode used",
                        name: "Promocode",
                        value: "Sum",
                        confirmedError:
                            "Due to the use of the promotional code, the withdrawal of funds is suspended",
                    },
                    email: "Email or other identifier of the recipient",
                    conditionsAccepted:
                        "You confirm that you have full legal capacity, have read, understood and agree to the Medical Concilium <1>Terms and Conditions</1> and <3>Privacy Policy</3>, and consent to the processing of personal data in accordance with them. You also agree to be charged a fee that may be charged by third-party organizations for payment processing.",
                },
                accountBallance: {
                    current: "Current balance",
                    wallet: "Wallet",
                },
                cashInButton: "Top up the balance",
                cashInModal: {
                    header: "Top up your balance",
                    add: "Add funds",
                },
            },
            userSettings: {
                deactivateUserAccount: {
                    deactivate: "Deactivation",
                    deactivateAccount: "Deactivate account",
                    deactivateAccount2: "Deactivate",
                    deactivateMessage:
                        "After deactivation, you will not be able to enter the office and carry out activities in this profile on the Medical Consilium portal!",
                    deactivateMessage2:
                        "Are you sure? After deactivation, your account will be deleted. Deleted accounts cannot be restored.",
                    passwordPlaceholder: "password",
                    deactivateUserMessage1: "You are deactivating a user ",
                    deactivateUserMessage2:
                        "Are you sure? After deactivation, this account will be deleted. Deleted accounts cannot be restored.",
                },
            },
            specRequest: {
                deactivationSectionDescription:
                    "You can cancel the application and delete all the data associated with it. Be careful, after the operation is completed, it will be impossible to recover the data.",
                deactivationConfirmation:
                    "After confirmation, all data will be deleted. Do you want to continue?",
                deleteRequest: "Delete request",
                requestCanNotBeDeleted: "The request cannot be deleted.",
                requestTitle: "Request",
                confirmationModal: {
                    header: "Send a request?",
                    body: "<0>Your application for participation in the Medical Concilium project as a Specialist contains some sections that are not required.</0><1>You can send an application with this amount of information or supplement it.</1><2>For quick and successful approval of your application, we recommend filling in as much information as possible and attaching files that confirm it. This will significantly increase your rating and popularity as a Project Specialist, increase the number of requests from Users.</2>",
                    send: "Send",
                    append: "Edit",
                },
                specRequestSentModal: {
                    header: "The request has been sent",
                    body: "Your application for participation in the project as a {{specialtyTypeLabel}} has been successfully submitted. You will be informed about the results of the application review.",
                },
                errorModal: {
                    requirements: `
                    To apply for participation in the project as a Specialist, the minimum required sections must be completed:
                    <1>
                    <0>Photo</0>
                    <2>Specialization</2>
                    <3>Knowledge of languages</3>
                    <4>About me</4>
                    <5>Education</5>
                    <6>Place of work</6>
                    <7>Cost of services</7>
                    </1>
                    To ensure quick and successful approval of your application, we recommend filling in as much information as possible and attaching supporting files.
                    This can significantly increase your rating and popularity as a Project Specialist, and increase the number of requests from Users.

                    `,
                },
            },
            payments: {
                transactionsTimeout: "Payment will be available in",
                paymentFailedToCreate: "Payment was not created.",
                paymentFailed: "There was an error creating the payment.",
                paymentCreated:
                    "Payment created but not completed. Contact the Administrator to check the status of the payment.",
                paymentWasNotCreated:
                    "Payment was not created. Please try later.",
                fields: {
                    accountId: "Payer ID",
                    transactionType: "Transaction type",
                    userId: "User",
                    toUserId: "Subject",
                    total: "Total",
                    balanceFromType: "Sender account type",
                    balanceFromBefore: "Sender's balance before",
                    balanceFromAfter: "Sender's balance after",
                    balanceToType: "Recipient's account type",
                    balanceToBefore: "Recipient's balance before",
                    balanceToAfter: "Recipient's balance after",
                    paymentMethod: "Payment method",
                    userSpecialistsPromocodeId: "Promo code",
                    paymentTransactionId: "Payment system transaction ID",
                    isReverted: "Reverted",
                    desiredTotal: "Desired amount",
                    adminId: "Administrator",
                    decisionDate: "Decision date and time (UTC)",
                    adminComment: "Administrator's comment",
                    conditionsAccepted: "Terms of use accepted",
                    requestLocale: "The language of the withdrawal request",
                    relatedTransactionId:
                        "The ID of the associated transaction",
                },
                admin: {
                    detailsHeader: "Transaction Details",
                    generalSection: "General",
                    balancesSection: "Balances",
                    paymentMethodSection: "Payment Method",
                    adminApprovalSection: "Administrator's Approval",
                    editPopup: {
                        title: "Complete the Transaction",
                    },
                },
            },
            specialistSearch: {
                translatorAlreadyAdded:
                    "You have already selected a Translator!",
                selectAnotherTranslator: "Choose another Translator",
                administratorAlreadyAdded:
                    "You have already selected a Сoncilium Administrator!",
                selectAnotherAdministrator: "Choose another Administrator",
            },
            admin: {
                cashOutPage: {
                    minMessageLength:
                        "The message must contain more than {{length}} characters",
                    maxPaymentTransactionIdLength:
                        "Transaction ID must be less than {{length}} characters",
                },
                concilium: {
                    details: {
                        header: "Chronology of the Consultation/Consilium",
                    },
                },
                eventsTab: {
                    eventsType: "Event",
                    hasMetaData: "Metadata",
                    details: {
                        header: "Event",
                        closedConciliums:
                            "Closed pending consultations (specialists did not respond)",
                        finalizedConciliums:
                            "Finalized consultations (completed at the end of the consultation time)",
                        refusedConciliums:
                            "Automatically refused (no medical specialists joined)",
                        refuseCandidatesConciliums:
                            "Consiliums screened for automatic refusal",
                    },
                },
                platformSettings: {
                    fields: {
                        penaltyUserMin: "Minimum for the user",
                        penaltyUserPercent: "Percentage for the user",
                        penaltySiteMin: "The minimum for a site holder",
                        penaltySitePercent: "Percentage for the site holder",
                        platformFee: "Platform margin percentage",
                    },
                    common: {
                        header: "Platform settings",
                        penaltyTitle: "Penalty rates",
                        platformFeeTitle: "Platform fee",
                        submitButtonLabel: "Update",
                        success: "Settings was updated",
                    },
                },
            },
            formErrors: {
                required: "Required!",
                requiredPassword: "Password is a required field",
            },
            fields: {
                firstName: "Name",
                phoneNumber: "Phone",
                description: "Message text",
                comment: "Comment",
                language: "Language",
                created: "Date of creation",
                updated: "Date of modification",
            },
            enums: {
                supportTicketCustomerRole: {
                    [SupportTicketCustomerRole.GUEST]: "User",
                    [SupportTicketCustomerRole.SPECIALIST]: "Specialist",
                },
                supportTicketSubject: {
                    [SupportTicketSubject.REGISTRATION]: "Register",
                    [SupportTicketSubject.CONCILIUMS]:
                        "Consultations/Consiliums",
                    [SupportTicketSubject.PAYMENTS]: "Payments",
                    [SupportTicketSubject.FEES]: "Penalties/Fees",
                    [SupportTicketSubject.OFFER]: "Offers",
                    [SupportTicketSubject.TROUBLESHOOT]: "Troubleshooting",
                    [SupportTicketSubject.COLLABORATION]: "Collaboration",
                    [SupportTicketSubject.ADVERTIZING]: "Advertising",
                    [SupportTicketSubject.ARBITRATION]: "Arbitrage",
                    [SupportTicketSubject.OTHER]: "Other",
                },
                recordStatus: {
                    [RecordStatus.CREATED]: "Created",
                    [RecordStatus.APPROVED]: "Approved",
                    [RecordStatus.DECLINED]: "Declined",
                    [RecordStatus.IN_REVIEW]: "In Review",
                },
                transactionType: {
                    [TransactionType.BALANCE_REPLENISHMENT]:
                        "Balance replenishment",
                    [TransactionType.RESERVATION]: "Funds reservation",
                    [TransactionType.SERVICE_PAYMENT]: "Service payment",
                    [TransactionType.SERVICE_RECEIPT]:
                        "Receipt of payment for service",
                    [TransactionType.WITHDRAW]: "Withdrawal of funds",
                    [TransactionType.CANCEL_RESERVATION]:
                        "Reservation cancellation",
                    [TransactionType.PAYMENT_TO_SITE_OWNER]:
                        "Payment to site owner",
                    [TransactionType.PENALTY]: "Penalty",
                    [TransactionType.ARBITRATION]: "Arbitration",
                    [TransactionType.COMPENSATION]: "Compensation",
                    [TransactionType.PROMOCODE_APPLIED]: "Promo code applied",
                    [TransactionType.PROMOCODE_REMOVED]: "Promo code removed",
                    [TransactionType.CANCEL_WITHDRAW_RESERVATION]:
                        "Cancellation of withdrawal reservation",
                    [TransactionType.WITHDRAW_RESERVATION]:
                        "Reservation of funds for withdrawal",
                },
                balanceType: {
                    [BalanceType.MAIN_BALANCE]: "Main balance",
                    [BalanceType.TRANSIT]: "Transit balance",
                    [BalanceType.PROFIT]: "Profit balance",
                    [BalanceType.PENALTY]: "Penalty balance",
                    [BalanceType.PROMOCODE]: "Promo code balance",
                },
                paymentMethod: {
                    [PaymentMethod.NONE]: "Platform currency",
                    [PaymentMethod.PAYPAL]: "PayPal",
                    [PaymentMethod.PAYONEER]: "Payoneer",
                    [PaymentMethod.SKRILL]: "Skrill",
                },
                transactionStatus: {
                    [TransactionStatus.IN_PROGRESS]: "In progress",
                    [TransactionStatus.WAITING]: "Waiting",
                    [TransactionStatus.COMPLETED]: "Completed",
                    [TransactionStatus.CANCELED]: "Canceled",
                    [TransactionStatus.WAITING_FOR_TRANSFER]: "Handled by",
                },
                currencyType: {
                    [CurrencyType.USD]: "USD",
                    [CurrencyType.EUR]: "EUR",
                    [CurrencyType.PLN]: "PLN",
                },
                asSpecialistType: {
                    [SpecialistType.MEDICAL_SPECIALIST]: "Medical specialist",
                    [SpecialistType.TRANSLATOR]: "Translator",
                    [SpecialistType.ADMINISTRATOR]:
                        "Administrator of consilium",
                },
                eventType: {
                    [EventType.CRON_CONCILIUM]:
                        "Automatic completion of consultations",
                    [EventType.CRON_GENERATE_VIDEO_TOKENS]:
                        "Automatic generation of video conference sessions",
                },
            },
            customerSupport: {
                fields: {
                    lastName: "First name and surname",
                    middleName: "Patronymic name (optional)",
                    description: "Message text",
                    email: "E-mail (if you are a registered user, please provide your e-mail address shown at registration)",
                    country: "Country/Region",
                    customerRole:
                        "You are referring as (select as appropriate)",
                    subjectType: "Select the issue you are interested in",
                    ticketName: "Name",
                },
                createTicketForm: {
                    header: "Contact us",
                    conditionsAccepted:
                        "By checking this box you agree to our <1>Terms of Use</1> and <3>Privacy Policy</3>, and agree to be contacted by phone and e-mail regarding your inquiry. We will process your data in accordance with our Privacy Policy.",
                    sendButton: "Submit",
                    successMessage:
                        "Your request {{ticketName}} has been sent to support. We will contact you using the e-mail address that was specified when registering the request.",
                    successTitle: "Appeal created",
                    successButton: "OK",
                },
                adminTab: {
                    header: "Customer Appeals",
                    table: {
                        ticketName: "Name",
                        subjectType: "Subject",
                        fullName: "Customer name",
                        customerRole: "Customer role",
                        emailSent: "Email sent",
                        isCustomerRegistered: "User authorized",
                        created: "Created at",
                        isEmailRegistered: "Email registered",
                        updated: "Modified at",
                    },
                    sendEmailBtn: "Send Email",
                    changeStatusBtn: "Chanche status",
                    editPopup: {
                        title: "Edit Appeal",
                    },
                    detailsHeader: "Details of Appeal",
                },
            },
            maxSpecialistsModal: {
                edit: "Edit",
            },
            registration: {
                common: {
                    loginLabel: "Login",
                    registrationLabel: "Registration",
                    generalLabel: "General",
                    birthdayLabel: "Enter date of birth",
                    timezoneLabel: "Time Zone",
                    languageLabel: "Communication language",
                    currencyLabel: "Currency",
                    timezoneInfo:
                        "Time information is displayed according to the location specified by you.",
                    languageInfo:
                        "Choose the languages that you know at a sufficient level for a full-fledged consultation without the involvement of an interpreter.",
                    currencyInfo:
                        "The indicated cost and payment for products on the site are made in the currency of the project - Medical Concilium Credits. All conversions into the currency you choose will be approximate and are of an informational nature only.",
                    passwordLabel: "Password",
                    submitButtonLabel: "Sign Up",
                },
                fields: {
                    name: "Name",
                    surname: "Surname",
                    patronimyc: "Patronymic",
                    email: "Mail",
                    phone: "Phone",
                    city: "City",
                    country: "Country",
                    currency: "Currency",
                    language: "Language",
                    timezone: "Time Zone",
                    birthday: "Date of birth",
                    password: "Password",
                    confirmPassword: "Repeat password",
                },
                errors: {
                    age: "You must be over {{age}} years old",
                    passwordMismatch: "Passwords do not match",
                },
                successModal: {
                    title: "Congratulations!",
                    info: "You have successfully registered for the Medical Concilium project.",
                    mainLabel: "Main",
                    loginLabel: "Login",
                },
                selectPlaceholders: {
                    country: "Select a country",
                    currency: "Select a currency",
                    language: "Select a language",
                    timezone: "Select a timezone",
                },
            },
            login: {
                common: {
                    loginLabel: "Login",
                    registrationLabel: "Registration",
                    emailLabel: "Enter your email",
                    passwordLabel: "Enter your password",
                    forgotPasswordLabel: "Forgot your password?",
                    submitButtonLabel: "Submit",
                },
                fields: {
                    email: "Email",
                    password: "Password",
                },
            },
            mainHeader: {
                featuresBtn: "Features",
                chooseSpecBtn: "Choose a specialist",
                requestBtn: "Request",
                becomeSpecBtn: "Become a specialist",
                languages: {
                    ru: "Russian",
                    uk: "Ukrainian",
                    en: "English",
                    pl: "Polish",
                },
                userOptions: {
                    consults: "Consultations",
                    favorites: "Favorites",
                    settings: "Settings",
                    support: "Help",
                    logout: "Exit",
                },
                login: "Enter",
                registration: "Registration",
                search: "Search",
                notifications: {
                    personal: "Private",
                    public: "General",
                    showAll: "Show all notifications",
                },
            },
            project: {
                name: "Medical Concilium",
            },
            mainPage: {
                title: "Project features",
                searchSpec: "Order a consultation",
                searchConc: "Convene a concilium",
                howItWorks: "How it works?",
                start: "Get started",
            },
            footer: {
                aboutUs: "About us",
                features: "Features",
                articles: "Community",
                support: "Support",
                slogan: "We combine the knowledge of the best to give health!",
                spec: "Order a consultation",
                conc: "Convene a concilium",
                login: "Log in",
                register: "Registration",
                termsOfUse: "Terms of Services",
                privacyPolicy: "Privacy policy",
                fullVersion: "Full version of the site",
                right: {
                    medSpec: "Medical specialist",
                    lang: "Translator",
                    admin: "Concilium administrator",
                },
            },
            notFound: {
                title: "404",
                message: "The page you are looking for was not found.",
                link: "Back to Home",
            },
        },
    },
    ru: {
        translation: {
            newConferenceProcess: {
                createConsultation: "Заказать консультацию",
                createConcilium: "Созвать консилиум",
                dateTime: {
                    concilium: "Дата и время проведения консилиума",
                    consultation: "Дата и время проведения консультации",
                    at: "в",
                    edit: "Редактировать",
                    choiseDateTime: "Выберите дату и время",
                    conciliumLabel: "консилиума",
                    consultationLabel: "консультации",
                    dateTimeText:
                        "В таблице отображается дата и время, которое подходит для всех участников",
                    duration: "Длительность",
                    hour_1: "1 час",
                    hour_2: "2 часа",
                    hour_3: "3 часа",
                    warning: "Вы не выбрали время",
                    selectedRangeIsNotValid:
                        "Выбранное время консультации некорректно",
                },
                details: {
                    step_1: "Шаг 1.",
                    basic: "Основное",
                    step_3: "Шаг 3.",
                    payment: "Оплата",
                    proposal: "Заявка на ",
                    concilium: "консилиум",
                    consultation: "консультацию",
                    placeholder: "Опишите причины обращения",
                    addFile: "Прикрепить файл",
                    addAudio: "Запись звука",
                    addVideo: "Запись видео",
                },
                extra: {
                    text: 'Для создания консультации/консилиума Вы выбрали время, которое тарифицируется специалистами как "Срочная"!',
                    next: "Продолжить",
                    prev: "Выбрать другое время",
                },
                latest: {
                    concilium: "Консилиум",
                    consultation: "Консультация",
                    text_1: "Ваша заявка на консультацию от ",
                    text_2: "года успешно направлена, и будет рассмотрена в ближайшее время.",
                },
                payment: {
                    step_2: "Шаг 2.",
                    detailsText: "Детали",
                    consultationText: "Консультации (Количество: ",
                    translatorText: "Услуги переводчика (Количество: ",
                    adminText: "Услуги администратора (Количество: ",
                    allText: "Всего",
                    warningText:
                        "У Вас недостаточно денежных средств, чтобы  заказать выбранную консультацию/консилиум. Для завершения (продолжения) оформления заказа просим пополнить свой cчет в валюте сайта - Medical Concilium Credits.",
                    warningButton: "пополнить баланс",
                    confirm:
                        "Вы подтверждаете свой контракт, согласно которому Вы должны осуществить покупку услуг, перечисленных выше. Вы также подтверждаете, что Вы обладаете полной право- и дееспособностью для заключения и исполнения контракта, и Вы прочитали, понимаете и согласны с <1>Условиями предоставления услуг</1> и с <3>Политикой конфиденциальности</3> сайта Medical Concilium.",
                    next: "Оформить заказ",
                },
                requiredAdmin: {
                    admin: "Администратор консилиума",
                    adminHeader: "Необходим Администратор консилиума!",
                    adminText:
                        "Для улучшения качества и помощи в консультировании, предлагаем воспользоваться услугами Администратора консилиума. В соответствии с Условиями предоставления услуг и Политикой конфиденциальности Medical Concilium, отказ от услуг Администратора консилиума возлагает все обязательства по качеству и полноте его проведения на инициатора консилиума.",
                    adminButton: "Перейти к выбору Администратора",
                },
                requiredLang: {
                    lang: "Переводчик",
                    langHeader: "Необходим Переводчик!",
                    langText:
                        "Вы выбрали для консультации специалиста, язык общения которого не совпадает с Вашим.",
                    langButton: "Перейти к выбору Переводчика",
                    specButton: "Выбрать другого Медицинского специалиста",
                },
                requiredSpec: {
                    specText:
                        "Для оформления заявки на Консилиум/Консультацию - необходимо выбрать медицинских специалистов.",
                },
                smallTime: {
                    text_1: "До начала консилиума осталось менее",
                    text_2: "минут, пожалуйста, выберите другое время",
                    cancel: "Отменить",
                    changeTime: "Выбрать другое время",
                },
                specList: {
                    header: "Редактирование",
                    remove: "Исключить специалиста",
                    add: "Добавить",
                    spec: "специалиста",
                    search: "Продолжить поиск специалистов",
                    next: "Готово",
                },
            },
            promocodesTabHeader: {
                header: "Промокоды",
            },
            promocodeSpecSettings: {
                promocodeFilters: {
                    valueBegin: "Количество бонусов от",
                    valueEnd: "Количество бонусов до",
                    createdBegin: "Создан от (UTC)",
                    createdEnd: "Создан до (UTC)",
                    isActive: {
                        all: "Все",
                        yes: "Активные",
                        no: "Неактивные",
                    },
                    reset: "Сбросить",
                    filter: "Отфильтровать",
                    ready: "Готово",
                },
                promocodeAddModal: {
                    header: "Создать промокод",
                    changePromocodeHeader: "Изменить промокод",
                    categoryHeader: "Доступный категориям",
                    changeName: "Изменить",
                    submit: "Отправить",
                    validation: {
                        required: "Обязательное поле",
                        integer: "Только целое значение",
                        min1: "Минимальное значение - 0,01",
                        min2: "Минимальное значение - 0",
                        existingName: "Такое имя уже существует",
                        namePattern:
                            "Только латиница верхнего регистра и цифры 0-9 (10 символов)",
                        endDate:
                            "Время окончания не может быть раньше времени начала",
                        exact8Characters: "Максимум 8-значное число",
                        number: "Это должно быть число",
                    },
                },
                promocodeAddButton: {
                    buttonName: "Создать промокод",
                    buttonChangeName: "Изменить",
                },
                promocodeFields: {
                    name: "Название",
                    type: "Тип",
                    startDate: "Начало действия",
                    endDate: "Конец действия",
                    startDateUTCFrom: "Начало действия (UTC) от",
                    endDateUTCFrom: "Конец действия (UTC) от",
                    startDateUTCBefore: "Начало действия (UTC) до",
                    endDateUTCBefore: "Конец действия (UTC) до",
                    value: "Количество бонусов",
                    isActive: {
                        isActiveHeader: "Активный",
                    },
                    userTypes: {
                        userTypesHeader: "Типы пользователей",
                        MedicalSpecialist: "Медицинский специалист",
                        Translator: "Переводчик",
                        Administrator: "Администратор",
                    },
                    usagesLimit: "Лимит использования",
                    created: "Создан (UTC)",
                    updated: "Обновлен (UTC)",
                    usages: "Использован",
                    actions: "Действия",
                },
            },
            paginationComponent: {
                label: "Количество записей на странице",
                label2: "Количество страниц",
            },
            authTypeSelection: {
                text: "Чтобы подать заявку на участие в проекте в качестве специалиста, вам необходимо зарегистрироваться или войти в свою учетную запись.",
                login: "Вход",
                registration: "Регистрация",
            },
            specialtySelection: {
                selectCategory:
                    "Выберите категорию специалистов для регистрации",
                oneCategory:
                    "Вы можете зарегистрироваться как специалист только в одной категории",
                medicalSpec: "Медицинский специалист",
                medicalSpecText:
                    "Специалист с высшим медицинским образованием и практическим опытом.",
                translateSpec: "Переводчик",
                translateSpecText:
                    "Образование переводчика или лингвиста, специальность - медицина.",
                adminSpec: "Администратор консилиума",
                adminSpecText:
                    "Наличие медицинского или фармацевтического образования на уровне не ниже среднего специального образования.",
                user: "Продолжить как пользователь",
            },
            usePromocode: {
                header: "Используйте промокод",
                nameError: "Такого промокода не существует",
                placeholder: "Промокод",
                activate: "Активировать",
                enterValue: "Введите промокод",
            },
            errors: {
                commonErrors: {
                    max: "Максимальная длина - ",
                    min: "Минимальная длина - ",
                    required: "Обязательное поле",
                    invalidPhoneNumber: "Не верный формат номера телефона",
                    invalidEmail: "Неверный формат почты",
                    integer: "Только целое число",
                },
                standardError: "Возникла ошибка, попробуйте позже!",
                SERVICE_UNAVAILABLE: "Услуга недоступна",
                NOT_FOUND: "Не найдено",
                UNAUTHORIZED: "Не авторизовано",

                PROMOCODE_NOT_FOUND: "Промокод не найден",
                PROMOCODE_IS_NOT_ACTIVE: "Промокод не активен",
                PROMOCODE_IS_NOT_APPLICABLE: "Промокод не действителен",
                PROMOCODE_LIMIT_ERROR: "Превышен лимит использования",
                PROMOCODE_WITHDRAWAL_RESTRICTIONS:
                    "В связи с использованием промокода, вывод средств приостановлен",
                PROMOCODE_START_ERROR: "Действие промокода не началось",
                PROMOCODE_END_ERROR: "Действие промокода завершилось",
                PROMOCODE_ALREADY_APPLIED_ERROR: "Промокод уже применен",
                PROMOCODE_BONUS_ALREADY_APPLIED_ERROR:
                    "Бонусный промокод уже применен",

                PROMOCODE_START_DATE_EDIT_PROHIBITED: "Дату менять нельзя",
                PROMOCODE_END_DATE_EDIT_PROHIBITED: "Дату менять нельзя",
                PROMOCODE_VALUE_EDIT_PROHIBITED:
                    "Количество бонусов менять нельзя",
                PROMOCODE_TYPE_EDIT_PROHIBITED: "Тип менять нельзя",
                PROMOCODE_USAGES_LIMIT_EDIT_PROHIBITED:
                    "Лимит использования менять нельзя",
                PROMOCODE_NAME_IS_NOT_UNIQUE: "Такое имя уже существует",

                USER_SPECIALIST_NOT_FOUND: "Пользователь не найден",
                USER_SPECIALIST_DELETION_PROHIBITED:
                    "Удалить запрос на специалиста не разрешено",
                USER_SPECIALIST_HAS_CONCILIUMS:
                    "Удалить запрос на специалиста не разрешено, пользователь является участником консультации",

                USER_SPECIALIST_DOES_NOT_EXISTS: "Специалист не существует",
                CAN_NOT_ADD_SPECIALIST: "Нельзя добавить специалиста",
                ADD_MED_SPECIALIST_FIRST:
                    "Для использования услуг Переводчика необходимо сначала заказать\nКонсультацию/Консилиум медицинского специалиста.",
                TRANSLATOR_ALREADY_ADDED:
                    "Участвовать в консультации/консилиуме может только один\nПереводчик. Вы уже выбрали необходимого Переводчика!",
                NEED_MORE_MED_SPECIALISTS:
                    "Для использования услуг Администратора консилиума\nнеобходимо сначала созвать консилиум. Чтобы созвать консилиум\nвыберите двух или более Медицинских специалистов.",
                ADMINISTRATOR_ALREADY_ADDED:
                    "Участвовать в консилиуме может только один Администратор\nконсилиума. Вы уже выбрали необходимого специалиста!",
                SPECIALIST_ALREADY_ADDED:
                    "Специалист уже добавлен в консилиум/консультацию.",
                ERROR_ADDING_SPECIALIST: "Ошибка при добавлении специалиста",
                MAX_PARTICIPANTS_NUMBER_REACHED:
                    "Достигнуто максимальное количество участников",
                DOES_NOT_HAVE_FREE_TIME:
                    "У специалиста отсутствует доступное время или доступное время не пересекается со временем выбранных специалистов.",

                CART_IS_EMPTY: "Специалисты не выбраны",
                CART_TRANSLATOR_REQUIRED: "Не выбран переводчик",
                CART_ADMINISTRATOR_REQUIRED: "Не выбранный администратор",

                CART_STATUS_IS_NOT_DEFINED: "Статус консилиума не определен",
                CART_CAN_NOT_SET_STATUS:
                    "Не удается установить статус консилиума",
                CART_PROCESS_IS_NOT_FINISHED:
                    "Процесс создания консилиума не завершен",
                CART_SPECIALISTS_TIME_DOES_NOT_MATCH:
                    "Время консультации не совпадает со временем специалистов",
                CART_SMALL_TIME_RESERVE:
                    "До начала консилиума осталось мало времени",
                CART_MEETING_TIME_IS_NOT_SET:
                    "Время консультации не установлено",

                CONCILIUM_CREATION_ERROR: "Ошибка при создании консилиума",

                INSUFFICIENT_BALANCE: "Недостаточно средств для вывода",
                INSUFFICIENT_TRANSIT_BALANCE: "Недостаточно средств для вывода",
                CONDITIONS_NOT_ACCEPTED:
                    "Пользователь не принял условия контракта",
                WRONG_USER_DATA: "Пароль некорректен",
                undefined: "Произошла ошибка",
                USR_INCOMPLETE_INFORMATION:
                    "Обязательная информация не заполнена",
                FILE_SIZE_IS_TOO_BIG:
                    "Размер файла слишком большой. Максимальный размер файла {{maxFileSize}} MB.",
                FILE_EXTENSION_IS_NOT_ALLOWED:
                    "Это расширение файла не поддерживается, попробуйте следующие: {{allowedExtensions}}",
                TOO_MANY_FILES:
                    "Слишком много файлов, возможно добавить только {{maxFilesCount}}",
                FILE_IS_EMPTY: "Этот файл пустой.",
                UR_USER_ALREADY_EXIST: "Пользователь уже зарегистрирован.",
                UR_EMAIL_ALREADY_EXIST:
                    "Эта почта уже используется другим пользователем.",
                UR_LANGUAGE_DOES_NOT_EXIST: "Язык не найден.",
                UR_CURRENCY_DOES_NOT_EXIST: "Валюта не найдена.",
                UR_COUNTRY_DOES_NOT_EXIST: "Страна не найдена.",
                UL_INVALID_DATA: "Неверный логин или пароль.",
                UL_RECAPTCHA_FAILED: "Подтвердите, что вы не робот.",
                UL_EMAIL_NOT_ACTIVATED: "Активируйте почту.",
                UL_USER_WAS_DEACTIVATED: "Профиль деактивирован.",
            },
            general: {
                saveBtn: "Сохранить",
                restartBtn: "Перезапустить",
                email: "Email",
                skip: "Пропустить",
                delete: "Удалить",
                confirm: "Подтвердить",
                cancel: "Отклонить",
                information: "Информация",
                continue: "Продолжить",
                closeBtn: "Закрыть",
                status: "Статус",
                details: "Детали",
                yes: "Да",
                no: "Нет",
                exitBtn: "Выход",
                actions: "Действия",
                consultationConcilium: "Консультация/Консилиум",
                profit: "Прибыль",
                penalty: "Штраф",
                createdUTC: "Дата и время создания (UTC)",
                currencyType: "Валюта",
                accept: "Принять",
                deny: "Отклонить",
                ok: "OK",
            },
            conciliumStatus: {
                [ConciliumStatus.NONE]: "NONE",
                [ConciliumStatus.INITIATED]: "Рассматривается",
                [ConciliumStatus.SPECIALIST_APPROVED]: "Одобрена",
                [ConciliumStatus.SPECIALIST_REFUSED]: "Отклонена",
                [ConciliumStatus.FINALIZED]: "Завершена",
                [ConciliumStatus.CANCELED]: "Отменена",
                [ConciliumStatus.INJURED]: "Повреждена",
            },
            specSettings: {
                cashOutButton: "Снять средства",
                cashOutModal: {
                    header: "Заявка на снятие средств",
                    amount: "Сумма",
                    note: "Как вы сможете принять средства?",
                    send: "Отправить",
                    payoneerId: "Payoneer Id",
                    skrillId: "Skrill Id",
                    validations: {
                        required: "Обязательное!",
                        addNotes: "Добавьте описание!",
                        greaterThenZero: "Должно быть больше 0!",
                        exact8Characters: "Должно быть 8 символов!",
                        exact9Characters: "Должно быть 9 символов!",
                        cashOutError: "Возникла ошибка, попробуйте позже!",
                    },
                    promocode: {
                        header: "Использованный промокод",
                        name: "Промокод",
                        value: "Сумма",
                        confirmedError:
                            "В связи с использованием промокода, вывод средств приостановлен",
                    },
                    email: "Email или другой идентификатор получателя",
                    conditionsAccepted:
                        "Вы подтверждаете, что обладаете полной право- и дееспособностью, прочитали, поняли и согласны с <1>Условиями предоставления услуг</1> и <3>Политикой конфиденциальности</3> Medical Concilium, а также даете согласие на обработку личных данных в соответствии с ними. Вы также соглашаетесь на взыскание комиссии, которая может быть удержана сторонними организациями за выполнение платежа.",
                },
                accountBallance: {
                    current: "Текущий баланс",
                    wallet: "Кошелёк",
                },
                cashInButton: "пополнить баланс",
                cashInModal: {
                    header: "Пополнение баланса",
                    add: "Пополнить",
                },
            },
            userSettings: {
                deactivateUserAccount: {
                    deactivate: "Деактивация",
                    deactivateAccount: "Деактивировать аккаунт",
                    deactivateAccount2: "Деактивировать",
                    deactivateMessage:
                        "После деактивации Вы не сможете войти в кабинет и осуществлять деятельность в этом профиле на портале Medical Consilium!",
                    deactivateMessage2:
                        "Вы уверены? После деактивации Ваш аккаунт будет удален. Удаленные аккаунты не подлежат восстановлению.",
                    passwordPlaceholder: "пароль",
                    deactivateUserMessage1: "Вы деактивируете пользователя ",
                    deactivateUserMessage2:
                        "Вы уверены? После деактивации эта учетная запись будет удалена. Удаленные учетные записи не подлежат восстановлению.",
                },
            },
            specRequest: {
                deactivationSectionDescription:
                    "Вы можете отменить заявку и удалить все связанные с ней данные. Будьте осторожны, после завершения операции восстановить данные будет невозможно.",
                deactivationConfirmation:
                    "После подтверждения все данные будут удалены. Продолжить?",
                deleteRequest: "Удалить запрос",
                requestCanNotBeDeleted: "Запрос не может быть удалён.",
                requestTitle: "Заявка",
                confirmationModal: {
                    header: "Отправить запрос?",
                    body: "<0>В Вашей заявке на участие в проекте Medical Concilium в качестве Специалиста есть незаполненными разделы, которые не являются обязательными.</0><1>Вы можете направить заявку с таким объемом информации, или дополнить ее.</1><2>Для быстрого и успешного согласования Вашей заявки, советуем заполнить как можно больше информации и добавить файлы, которые ее подтверждают. Это существенно повысит Ваш рейтинг и популярность как Специалиста проекта, увеличит количество обращений к Вам со стороны Пользователей.</2>",
                    send: "Отправить",
                    append: "Дополнить",
                },
                specRequestSentModal: {
                    header: "Запрос отправлен",
                    body: "Ваша заявка на участие в проекте в качестве {{specialtyTypeLabel}} успешно направлена. По результатам рассмотрения заявки Вы будете проинформированы.",
                },
                errorModal: {
                    requirements: `
                    Для подачи заявки на участие в проекте в качестве Специалиста минимальным является заполнение обязательных разделов:
                    <1>
                    <0>Фото</0>
                    <2>Специализация</2>
                    <3>Знание языков</3>
                    <4>О себе</4>
                    <5>Образование</5>
                    <6>Место работы</6>
                    <7>Стоимость услуг</7>
                    </1>
                    Для быстрого и успешного согласования Вашей заявки, советуем заполнить как можно больше информации и добавить файлы, которые ее подтверждают.
                    Это может существенно повышать Ваш рейтинг и популярность как Специалиста проекта, и увеличивать количество обращений к Вам со стороны Пользователей.

                    `,
                },
            },
            payments: {
                transactionsTimeout: "Оплата будет доступна через",
                paymentFailedToCreate: "Платеж не создан.",
                paymentFailed: "Возникла ошибка при создании платежа.",
                paymentCreated:
                    "Платеж создан, но не завершен. Обратитесь к Администратору для проверки состояния платежа.",
                paymentWasNotCreated: "Платеж не создан. Попытайтесь еще раз.",
                fields: {
                    accountId: "Идентификатор плательщика",
                    transactionType: "Тип транзакции",
                    userId: "Пользователь",
                    toUserId: "Субъект",
                    total: "Сумма",
                    balanceFromType: "Тип счета отправителя",
                    balanceFromBefore: "Счет отправителя до",
                    balanceFromAfter: "Счет отправителя после",
                    balanceToType: "Тип счета получателя",
                    balanceToBefore: "Счет получателя до",
                    balanceToAfter: "Счет получателя после",
                    paymentMethod: "Способ платежа",
                    userSpecialistsPromocodeId: "Промокод пользователя",
                    paymentTransactionId: "ID платежной системы",
                    isReverted: "Возвращено",
                    desiredTotal: "Ожидаемая сумма",
                    adminId: "Администратор",
                    decisionDate: "Дата и время принятия решения (UTC)",
                    adminComment: "Комментарий администратора",
                    conditionsAccepted: "Условия использования приняты",
                    requestLocale: "Язык запроса на вывод средств",
                    relatedTransactionId: "ID связанной транзакции",
                },
                admin: {
                    detailsHeader: "Детали транзакции",
                    generalSection: "Общее",
                    balancesSection: "Балансы",
                    paymentMethodSection: "Способ платежа",
                    adminApprovalSection: "Решение администратора",
                    editPopup: {
                        title: "Завершить транзакцию",
                    },
                },
            },
            specialistSearch: {
                translatorAlreadyAdded: "Вы уже выбрали Переводчика!",
                selectAnotherTranslator: "Выбрать другого Переводчика",
                administratorAlreadyAdded:
                    "Вы уже выбрали администратора консилиума!",
                selectAnotherAdministrator: "Выбрать другого Администратора",
            },
            admin: {
                cashOutPage: {
                    minMessageLength:
                        "Сообщение должно содержать более {{length}} символов",
                    maxPaymentTransactionIdLength:
                        "ID транзакции должно содержать меньше {{length}} символов",
                },
                concilium: {
                    details: {
                        header: "Хронология Консультации/Консилиума",
                    },
                },
                eventsTab: {
                    eventsType: "Событие",
                    hasMetaData: "Метаданные",
                    details: {
                        header: "Событие",
                        closedConciliums:
                            "Закрытые зависшие консилиумы (специалисты не ответили)",
                        finalizedConciliums:
                            "Завершенные консилиумы (завершены в конце времени консультации)",
                        refusedConciliums:
                            "Автоматически отказано (не присоединились медицинские специалисты)",
                        refuseCandidatesConciliums:
                            "Консилиумы, проверенные на автоматический отказ",
                    },
                },
                platformSettings: {
                    fields: {
                        penaltyUserMin: "Минимум для пользователя",
                        penaltyUserPercent: "Процент для пользователя",
                        penaltySiteMin: "Минимум для сайтодержателя",
                        penaltySitePercent: "Процент для сайтодержателя",
                        platformFee: "Процент маржи платфомы",
                    },
                    common: {
                        header: "Настройки платформы",
                        penaltyTitle: "Коэффициенты штрафов",
                        platformFeeTitle: "Вознаграждение платформы",
                        submitButtonLabel: "Обновить",
                        success: "Настройки обновлены",
                    },
                },
            },
            formErrors: {
                required: "Обязательное!",
                requiredPassword: "Пароль – это обязательное поле",
            },
            fields: {
                firstName: "Имя",
                phoneNumber: "Телефон",
                description: "Текст сообщения",
                comment: "Коментарий",
                language: "Язык",
                created: "Дата создания",
                updated: "Дата модификации",
            },
            enums: {
                supportTicketCustomerRole: {
                    [SupportTicketCustomerRole.GUEST]: "Пользователь",
                    [SupportTicketCustomerRole.SPECIALIST]: "Специалист",
                },
                supportTicketSubject: {
                    [SupportTicketSubject.REGISTRATION]: "Регистрация",
                    [SupportTicketSubject.CONCILIUMS]:
                        "Консультации/Консилиумы",
                    [SupportTicketSubject.PAYMENTS]: "Платежи",
                    [SupportTicketSubject.FEES]: "Штрафы/Пени",
                    [SupportTicketSubject.OFFER]: "Предложения",
                    [SupportTicketSubject.TROUBLESHOOT]:
                        "Устранение неисправностей",
                    [SupportTicketSubject.COLLABORATION]: "Сотрудничество",
                    [SupportTicketSubject.ADVERTIZING]: "Реклама",
                    [SupportTicketSubject.ARBITRATION]: "Арбитраж",
                    [SupportTicketSubject.OTHER]: "Другое",
                },
                recordStatus: {
                    [RecordStatus.CREATED]: "Создан",
                    [RecordStatus.APPROVED]: "Подтвержденный",
                    [RecordStatus.DECLINED]: "Отклоненный",
                    [RecordStatus.IN_REVIEW]: "Рассматривается",
                },
                transactionType: {
                    [TransactionType.BALANCE_REPLENISHMENT]:
                        "Пополнение баланса",
                    [TransactionType.RESERVATION]: "Резервирование средств",
                    [TransactionType.SERVICE_PAYMENT]: "Оплата услуги",
                    [TransactionType.SERVICE_RECEIPT]:
                        "Получение оплаты за услугу",
                    [TransactionType.WITHDRAW]: "Вывод средств",
                    [TransactionType.CANCEL_RESERVATION]:
                        "Отмена резервирования",
                    [TransactionType.PAYMENT_TO_SITE_OWNER]:
                        "Перевод владельцу сайта",
                    [TransactionType.PENALTY]: "Штраф",
                    [TransactionType.ARBITRATION]: "Арбитраж",
                    [TransactionType.COMPENSATION]: "Компенсация",
                    [TransactionType.PROMOCODE_APPLIED]: "Применен промокод",
                    [TransactionType.PROMOCODE_REMOVED]: "Удален промокод",
                    [TransactionType.CANCEL_WITHDRAW_RESERVATION]:
                        "Отмена резервирования вывода средств",
                    [TransactionType.WITHDRAW_RESERVATION]:
                        "Резервирование средств для вывода",
                },
                balanceType: {
                    [BalanceType.MAIN_BALANCE]: "Основной счет",
                    [BalanceType.TRANSIT]: "Транзитный счет",
                    [BalanceType.PROFIT]: "Прибыль",
                    [BalanceType.PENALTY]: "Штрафной счет",
                    [BalanceType.PROMOCODE]: "Промокод",
                },
                paymentMethod: {
                    [PaymentMethod.NONE]: "Валюта платформы",
                    [PaymentMethod.PAYPAL]: "PayPal",
                    [PaymentMethod.PAYONEER]: "Payoneer",
                    [PaymentMethod.SKRILL]: "Skrill",
                },
                transactionStatus: {
                    [TransactionStatus.IN_PROGRESS]: "В обработке",
                    [TransactionStatus.WAITING]: "Ожидает",
                    [TransactionStatus.COMPLETED]: "Завершена",
                    [TransactionStatus.CANCELED]: "Отменена",
                    [TransactionStatus.WAITING_FOR_TRANSFER]: "Обрабатывается",
                },
                currencyType: {
                    [CurrencyType.USD]: "USD",
                    [CurrencyType.EUR]: "EUR",
                    [CurrencyType.PLN]: "PLN",
                },
                asSpecialistType: {
                    [SpecialistType.MEDICAL_SPECIALIST]:
                        "Медицинского специалиста",
                    [SpecialistType.TRANSLATOR]: "Переводчика",
                    [SpecialistType.ADMINISTRATOR]: "Администратора консилиума",
                },
                eventType: {
                    [EventType.CRON_CONCILIUM]:
                        "Автоматическое завершение консультаций",
                    [EventType.CRON_GENERATE_VIDEO_TOKENS]:
                        "Автоматическая генерация сессий видеоконференции",
                },
            },
            customerSupport: {
                fields: {
                    lastName: "Фамилия",
                    middleName: "Отчество (не обязательно)",
                    description: "Текст сообщения",
                    email: "E-mail (если Вы зарегистрированы - укажите E-mail указанный при регистрации)",
                    country: "Страна/Регион",
                    customerRole: "Вы обращаетесь как (выберите необходимое)",
                    subjectType:
                        "Выберите тему вопроса, который Вас интересует",
                    ticketName: "Название",
                },
                createTicketForm: {
                    header: "Свяжитесь с нами",
                    conditionsAccepted:
                        "Поставив этот флажок, вы соглашаетесь с <1>Условиями предоставления услуг</1> и <3>Политикой конфиденциальности</3> сайта, а также даете согласие связываться с Вами по телефону и электронной почте относительно Вашего обращения. Мы будем обрабатывать Ваши данные в соответствии с нашей Политикой конфиденциальности.",
                    sendButton: "Отправить",
                    successMessage:
                        "Ваше обращение {{ticketName}} направлено в службу поддержки. Мы свяжемся с вами с помощью электронной почты, указанной при регистрации обращения.",
                    successTitle: "Обращение создано",
                    successButton: "OK",
                },
                adminTab: {
                    header: "Вопросы пользователей",
                    table: {
                        ticketName: "Название",
                        subjectType: "Тема",
                        fullName: "Имя пользователя",
                        customerRole: "Роль пользователя",
                        emailSent: "Email отправлено",
                        isCustomerRegistered: "Пользователь авторизован",
                        created: "Дата создания",
                        isEmailRegistered: "Email зарегистрирован",
                        updated: "Дата модификации",
                    },
                    sendEmailBtn: "Отправить Email",
                    changeStatusBtn: "Изменить статус",
                    editPopup: {
                        title: "Редактировать Обращение",
                    },
                    detailsHeader: "Подробности Обращения",
                },
            },
            maxSpecialistsModal: {
                edit: "Редактировать",
            },
            registration: {
                common: {
                    loginLabel: "Вход",
                    registrationLabel: "Регистрация",
                    generalLabel: "Общее",
                    birthdayLabel: "Укажите дату рождения",
                    timezoneLabel: "Временной пояс",
                    languageLabel: "Язык общения",
                    currencyLabel: "Валюта",
                    timezoneInfo:
                        "Информация о времени отображается в соответствии с указанным местоположением.",
                    languageInfo:
                        "Выберите языки, которыми Вы владеете на достаточном уровне для полноценного проведения консультации без привлечения переводчика.",
                    currencyInfo:
                        "Указанная стоимость и оплата продуктов на сайте производятся в валюте проекта - Medical Concilium Credits. Все перерасчеты в выбранную Вами валюту будут приблизительными и носят исключительно информационный характер.",
                    passwordLabel: "Пароль",
                    submitButtonLabel: "Зарегистрироваться",
                },
                fields: {
                    name: "Имя",
                    surname: "Фамилия",
                    patronimyc: "Отчество",
                    email: "Почта",
                    phone: "Телефон",
                    city: "Город",
                    country: "Страна",
                    currency: "Валюта",
                    language: "Язык",
                    timezone: "Временной пояс",
                    birthday: "Дата рождения",
                    password: "Пароль",
                    confirmPassword: "Повторить пароль",
                },
                errors: {
                    age: "Вам должно быть больше {{age}} лет",
                    passwordMismatch: "Пароли не совпадают",
                },
                successModal: {
                    title: "Поздравляем!",
                    info: "Вы успешно зарегистрировались на проекте Medical Concilium.",
                    mainLabel: "Главная",
                    loginLabel: "Вход",
                },
                selectPlaceholders: {
                    country: "Выберите страну",
                    currency: "Выберите валюту",
                    language: "Выберите язык",
                    timezone: "Выберите часовой пояс",
                },
            },
            login: {
                common: {
                    loginLabel: "Вход",
                    registrationLabel: "Регистрация",
                    emailLabel: "Укажите почту",
                    passwordLabel: "Укажите пароль",
                    forgotPasswordLabel: "Забыли пароль?",
                    submitButtonLabel: "Войти",
                },
                fields: {
                    email: "Почта",
                    password: "Пароль",
                },
            },
            mainHeader: {
                featuresBtn: "Особенности",
                chooseSpecBtn: "Выбрать специалиста",
                requestBtn: "Заявки",
                becomeSpecBtn: "Стать специалистом",
                languages: {
                    ru: "Русский",
                    uk: "Украинский",
                    en: "Английский",
                    pl: "Польский",
                },
                userOptions: {
                    consults: "Консультации",
                    favorites: "Избранное",
                    settings: "Настройки",
                    support: "Помощь",
                    logout: "Выйти",
                },
                login: "Вход",
                registration: "Регистрация",
                search: "Поиск",
                notifications: {
                    personal: "Личные",
                    public: "Общие",
                    showAll: "Показать все уведомления",
                },
            },
            project: {
                name: "Medical Concilium",
            },
            mainPage: {
                title: "Возможности проекта",
                searchSpec: "Заказать консультацию",
                searchConc: "Созвать консилиум",
                howItWorks: "Как это работает?",
                start: "Начать работу",
            },
            footer: {
                aboutUs: "Про нас",
                features: "Особенности",
                articles: "Сообщество",
                support: "Поддержка",
                slogan: "Объединяем знания лучших, чтобы дарить здоровье!",
                spec: "Заказать консультацию",
                conc: "Созвать консилиум",
                login: "Вход",
                register: "Регистрация",
                termsOfUse: "Условия использования",
                privacyPolicy: "Политика конфиденциальности",
                fullVersion: "Полная версия сайта",
                right: {
                    medSpec: "Медицинский специалист",
                    lang: "Переводчик",
                    admin: "Администратор консилиума",
                },
            },
            notFound: {
                title: "404",
                message: "Страница, которую вы ищете, не найдена.",
                link: "Вернуться на главную",
            },
        },
    },
    pl: {
        translation: {
            newConferenceProcess: {
                createConsultation: "Zamów konsultację",
                createConcilium: "Zadzwoń do rady",
                dateTime: {
                    concilium: "Data i godzina konferencji",
                    consultation: "Data i godzina konsultacji",
                    at: "o",
                    edit: "Edytować",
                    choiseDateTime: "Wybierz datę i godzinę",
                    conciliumLabel: "rada",
                    consultationLabel: "konsultacje",
                    dateTimeText:
                        "Tabela wyświetla datę i godzinę odpowiednią dla wszystkich uczestników",
                    duration: "Czas trwania",
                    hour_1: "1 godzina",
                    hour_2: "2 godziny",
                    hour_3: "3 godziny",
                    warning: "Nie wybrałeś czasu",
                    selectedRangeIsNotValid:
                        "Wybrany czas konsultacji jest nieprawidłowy",
                },
                details: {
                    step_1: "Krok 1.",
                    basic: "Główny",
                    step_3: "Krok 3.",
                    payment: "Zapłata",
                    proposal: "Aplikacja do ",
                    concilium: "rada",
                    consultation: "konsultacja",
                    placeholder: "Opisz powody odwołania",
                    addFile: "Załącz plik",
                    addAudio: "Nagrywanie dźwięku",
                    addVideo: "Nagrywanie wideo",
                },
                extra: {
                    text: 'Aby utworzyć konsultację/konsultację wybrałeś czas, który jest rozliczany przez specjalistów jako "Pilny"!',
                    next: "Kontynuować",
                    prev: "Wybierz inny czas",
                },
                latest: {
                    concilium: "Rada",
                    consultation: "Konsultacja",
                    text_1: "Twoja prośba o konsultację ",
                    text_2: "pomyślnie przesłane i wkrótce zostaną sprawdzone.",
                },
                payment: {
                    step_2: "Krok 2.",
                    detailsText: "Detale",
                    consultationText: "Konsultacje (Ilość: ",
                    translatorText: "Usługi tłumaczeniowe (ilość: ",
                    adminText: "Usługi administratora (ilość: ",
                    allText: "Całkowity",
                    warningText:
                        "Nie masz wystarczających środków, aby zamówić wybraną konsultację/konsultację. Aby dokończyć (kontynuować) składanie zamówienia, prosimy o doładowanie konta w walucie serwisu - Medical Concilium Credits.",
                    warningButton: "uzupełnij saldo",
                    confirm:
                        "Potwierdzasz umowę, zgodnie z którą musisz dokonać zakupu usług wymienionych powyżej. Przyjmujesz również do wiadomości, że masz pełną zdolność do czynności prawnych do zawarcia i wykonania umowy oraz przeczytałeś, zrozumiałeś i zgadzasz się z <1>Warunkami świadczenia</1> usług i <3>Polityką prywatności</3> witryny internetowej Medical Concilium.",
                    next: "Sprawdzić",
                },
                requiredAdmin: {
                    admin: "Administrator Rady",
                    adminHeader: "Potrzebny administrator Rady!",
                    adminText:
                        "W celu podniesienia jakości i pomocy w doradztwie proponujemy skorzystanie z usług Administratora Rady. Zgodnie z Regulaminem oraz Polityką Prywatności Konsylium Medycznego odmowa świadczenia usług przez Administratora konsultacji nakłada na inicjatora konsultacji wszelkie zobowiązania dotyczące jakości i kompletności jej przeprowadzenia.",
                    adminButton: "Przejdź do wyboru administratora",
                },
                requiredLang: {
                    lang: "Tłumacz",
                    langHeader: "Potrzebujesz tłumacza!",
                    langText:
                        "Wybrałeś do konsultacji specjalistę, którego język komunikacji nie pasuje do Twojego.",
                    langButton: "Przejdź do wyboru tłumacza",
                    specButton: "Wybierz innego specjalistę medycznego",
                },
                requiredSpec: {
                    specText:
                        "Aby ubiegać się o Consilium / Konsultację, musisz wybrać lekarzy specjalistów.",
                },
                smallTime: {
                    text_1: "Przed rozpoczęciem konsultacji mniej niż",
                    text_2: "minut, proszę wybrać inny czas",
                    cancel: "Anulować",
                    changeTime: "Wybierz inny czas",
                },
                specList: {
                    header: "Redagowanie",
                    remove: "Wyklucz specjalistę",
                    add: "Dodać",
                    spec: "specjalista",
                    search: "Kontynuuj poszukiwanie specjalistów",
                    next: "Gotowy",
                },
            },
            promocodesTabHeader: {
                header: "Kody promocyjne",
            },
            promocodeSpecSettings: {
                promocodeFilters: {
                    valueBegin: "Liczba bonusów od",
                    valueEnd: "Liczba bonusów do",
                    createdBegin: "Utworzono z (UTC)",
                    createdEnd: "Utworzono wcześniej (UTC)",
                    isActive: {
                        all: "Wszystko",
                        yes: "Aktywny",
                        no: "Nieaktywny",
                    },
                    reset: "Resetowanie",
                    filter: "Filtr",
                    ready: "Gotowe",
                },
                promocodeAddModal: {
                    header: "Utwórz kod promocyjny",
                    changePromocodeHeader: "Zmień kod promocyjny",
                    categoryHeader: "Dostępne dla kategorii",
                    changeName: "Reszta",
                    submit: "Wysłać",
                    validation: {
                        required: "Pole wymagane",
                        integer: "Tylko liczba całkowita",
                        min1: "Minimalna wartość - 0,01",
                        min2: "Minimalna wartość - 0",
                        existingName: "Taka nazwa już istnieje",
                        namePattern:
                            "Tylko łacina wielkimi literami i cyfry 0-9 (10 znaków)",
                        endDate:
                            "Czas zakończenia nie może być wcześniejszy niż czas rozpoczęcia",
                        exact8Characters: "Maksymalna liczba 8-cyfrowa",
                        number: "To musi być liczba",
                    },
                },
                promocodeAddButton: {
                    buttonName: "Utwórz kod promocyjny",
                    buttonChangeName: "Zmiana",
                },
                promocodeFields: {
                    name: "Nazwa",
                    type: "Rodzaj",
                    startDate: "Początek akcji",
                    endDate: "Koniec akcji",
                    startDateUTCFrom: "Początek akcji (UTC) z",
                    endDateUTCFrom: "Koniec akcji (UTC) z",
                    startDateUTCBefore: "Początek akcji (UTC) zanim",
                    endDateUTCBefore: "Koniec akcji (UTC) zanim",
                    value: "Liczba bonusów",
                    isActive: {
                        isActiveHeader: "Aktywny",
                    },
                    userTypes: {
                        userTypesHeader: "Typy użytkowników",
                        MedicalSpecialist: "Specjalista medyczny",
                        Translator: "Tłumacz",
                        Administrator: "Administrator",
                    },
                    usagesLimit: "Limit użytkowania",
                    created: "Wygenerowane (UTC)",
                    updated: "Odnowiony (UTC)",
                    usages: "Używany",
                    actions: "Działania",
                },
            },
            paginationComponent: {
                label: "Liczba rekordów na stronie",
                label2: "Numer stron",
            },
            authTypeSelection: {
                text: "Aby zgłosić się do udziału w projekcie jako specjalista, musisz się zarejestrować lub zalogować na swoje konto.",
                login: "Wejście",
                registration: "Rejestracja",
            },
            specialtySelection: {
                selectCategory: "Wybierz kategorię specjalistów do rejestracji",
                oneCategory:
                    "Możesz zarejestrować się jako specjalista tylko w jednej kategorii",
                medicalSpec: "Specjalista medyczny",
                medicalSpecText:
                    "Specjalista z wyższym wykształceniem medycznym i praktycznym doświadczeniem.",
                translateSpec: "Tłumacz",
                translateSpecText:
                    "Wykształcenie tłumacza lub językoznawcy, specjalność - medycyna.",
                adminSpec: "Administrator Rady",
                adminSpecText:
                    "Dostępność wykształcenia medycznego lub farmaceutycznego na poziomie co najmniej średniego wykształcenia kierunkowego.",
                user: "Kontynuuj jako użytkownik",
            },
            usePromocode: {
                header: "Użyj kodu promocyjnego",
                nameError: "Nie ma takiego kodu promocyjnego.",
                placeholder: "Kod promocyjny",
                activate: "Aktywuj",
                enterValue: "Wpisz kod promocyjny",
            },
            errors: {
                commonErrors: {
                    max: "Maksymalna długość - ",
                    min: "Minimalna długość - ",
                    required: "Pole obowiązkowe",
                    invalidPhoneNumber:
                        "Format numeru telefonu jest nieprawidłowy",
                    invalidEmail: "Format email jest nieprawidłowy",
                    integer: "Tylko liczba całkowita",
                },
                standardError: "Wystąpił błąd. Proszę spróbować później!",
                SERVICE_UNAVAILABLE: "Serwis niedostępny",
                NOT_FOUND: "Nie znaleziono",
                UNAUTHORIZED: "Nieautoryzowany",

                PROMOCODE_NOT_FOUND: "Nie znaleziono kodu promocyjnego",
                PROMOCODE_IS_NOT_ACTIVE: "Kod promocyjny nie jest aktywny",
                PROMOCODE_IS_NOT_APPLICABLE:
                    "Kod promocyjny jest nieprawidłowy",
                PROMOCODE_LIMIT_ERROR: "Przekroczono limit użytkowania",
                PROMOCODE_WITHDRAWAL_RESTRICTIONS:
                    "Ze względu na użycie kodu promocyjnego wypłata środków jest zawieszona",
                PROMOCODE_START_ERROR: "Kod promocyjny nie został uruchomiony",
                PROMOCODE_END_ERROR: "Kod promocyjny wygasł",
                PROMOCODE_ALREADY_APPLIED_ERROR:
                    "Kod promocyjny został już zastosowany",
                PROMOCODE_BONUS_ALREADY_APPLIED_ERROR:
                    "Bonusowy kod promocyjny został już zastosowany",

                PROMOCODE_START_DATE_EDIT_PROHIBITED: "Nie można zmienić daty",
                PROMOCODE_END_DATE_EDIT_PROHIBITED: "Nie można zmienić daty",
                PROMOCODE_VALUE_EDIT_PROHIBITED:
                    "Liczby bonusów nie można zmienić",
                PROMOCODE_TYPE_EDIT_PROHIBITED: "Nie można zmienić typu",
                PROMOCODE_USAGES_LIMIT_EDIT_PROHIBITED:
                    "Limitu użytkowania nie można zmienić",
                PROMOCODE_NAME_IS_NOT_UNIQUE: "Taka nazwa już istnieje",

                USER_SPECIALIST_NOT_FOUND: "Nie znaleziono użytkownika",
                USER_SPECIALIST_DELETION_PROHIBITED:
                    "Nie można usunąć prośby o specjalistę",
                USER_SPECIALIST_HAS_CONCILIUMS:
                    "Niedozwolone jest usunięcie wniosku o specjalistę, użytkownik jest uczestnikiem konsultacji",

                USER_SPECIALIST_DOES_NOT_EXISTS: "Nie ma specjalisty",
                CAN_NOT_ADD_SPECIALIST: "Nie możesz dodać specjalisty",
                ADD_MED_SPECIALIST_FIRST:
                    "Aby skorzystać z usług tłumacza, należy najpierw\nzamówić Konsultację/Consilium lekarza specjalisty.",
                TRANSLATOR_ALREADY_ADDED:
                    "W konsultacjach/konsylium może brać udział tylko\njeden Tłumacz. Wybrałeś już wymaganego Tłumacza!",
                NEED_MORE_MED_SPECIALISTS:
                    "Aby skorzystać z usług Administratora Rady należy\nnajpierw zwołać Radę. Aby zwołać radę, wybierz dwóch\nlub więcej specjalistów medycznych.",
                ADMINISTRATOR_ALREADY_ADDED:
                    "W radzie może uczestniczyć tylko jeden Administrator\nRady. Wybrałeś już potrzebnego specjalistę!",
                SPECIALIST_ALREADY_ADDED:
                    "Specjalista został już dodany do konsultacji/konsultacji.",
                ERROR_ADDING_SPECIALIST: "Błąd podczas dodawania specjalisty",
                MAX_PARTICIPANTS_NUMBER_REACHED:
                    "Osiągnięto maksymalną liczbę uczestników",
                DOES_NOT_HAVE_FREE_TIME:
                    "Specjalista nie ma dostępnego czasu lub dostępny czas nie pokrywa się z czasem wybranych specjalistów.",

                CART_IS_EMPTY: "Specjaliści nie są wybierani",
                CART_TRANSLATOR_REQUIRED: "Nie wybrano tłumacza",
                CART_ADMINISTRATOR_REQUIRED: "Nie wybrano administratora",

                CART_STATUS_IS_NOT_DEFINED: "Status rady nie jest określony",
                CART_CAN_NOT_SET_STATUS: "Nie można ustalić statusu consilium",
                CART_PROCESS_IS_NOT_FINISHED:
                    "Proces tworzenia consilium jest niekompletny",
                CART_SPECIALISTS_TIME_DOES_NOT_MATCH:
                    "Czas konsultacji nie pokrywa się z czasem specjalisty",
                CART_SMALL_TIME_RESERVE:
                    "Do rozpoczęcia konferencji pozostało niewiele czasu",
                CART_MEETING_TIME_IS_NOT_SET: "Nie ustawiono czasu konsultacji",

                CONCILIUM_CREATION_ERROR:
                    "Wystąpił błąd podczas tworzenia consilium",

                INSUFFICIENT_BALANCE: "Niewystarczające środki do wypłaty",
                INSUFFICIENT_TRANSIT_BALANCE:
                    "Niewystarczające środki do wypłaty",
                CONDITIONS_NOT_ACCEPTED:
                    "Użytkownik nie zaakceptował warunków umowy",
                WRONG_USER_DATA: "Hasło jest błędne",
                undefined: "Wystąpił błąd",
                USR_INCOMPLETE_INFORMATION:
                    "Wymagane informacje nie zostały wypełnione",
                FILE_SIZE_IS_TOO_BIG:
                    "Rozmiar pliku jest zbyt duży. Maksymalny rozmiar pliku to {{maxFileSize}} MB.",
                FILE_EXTENSION_IS_NOT_ALLOWED:
                    "To rozszerzenie pliku nie jest obsługiwane, spróbuj użyć następującego: {{allowedExtensions}}",
                TOO_MANY_FILES:
                    "Zbyt wiele plików, można dodać tylko {{maxFilesCount}}",
                FILE_IS_EMPTY: "Ten plik jest pusty.",
                UR_USER_ALREADY_EXIST: "Użytkownik jest już zarejestrowany.",
                UR_EMAIL_ALREADY_EXIST:
                    "Ta poczta jest już używana przez innego użytkownika.",
                UR_LANGUAGE_DOES_NOT_EXIST: "Nie znaleziono języka.",
                UR_CURRENCY_DOES_NOT_EXIST: "Nie znaleziono waluty.",
                UR_COUNTRY_DOES_NOT_EXIST: "Nie znaleziono kraju.",
                UL_INVALID_DATA: "Nieprawidłowy login lub hasło.",
                UL_RECAPTCHA_FAILED: "Potwierdź, że nie jesteś robotem.",
                UL_EMAIL_NOT_ACTIVATED: "Aktywuj pocztę.",
                UL_USER_WAS_DEACTIVATED: "Profil wyłączony.",
            },
            general: {
                saveBtn: "Zapisać",
                restartBtn: "Uruchom ponownie",
                email: "Email",
                skip: "Chybić",
                delete: "Usunąć",
                confirm: "Potwierdzać",
                cancel: "Anuluj",
                information: "Informacja",
                continue: "Kontynuuj",
                closeBtn: "Zamknąć",
                status: "Status",
                details: "ДетDetaleалі",
                yes: "Tak",
                no: "Nie",
                exitBtn: "Wejście",
                actions: "Działania",
                consultationConcilium: "Konsultacja/Koncylium",
                profit: "Zysk",
                penalty: "Kara",
                createdUTC: "Data i czas (UTC)",
                currencyType: "Waluta",
                accept: "Akceptuj",
                deny: "Anuluj",
                ok: "OK",
            },
            conciliumStatus: {
                [ConciliumStatus.NONE]: "NONE",
                [ConciliumStatus.INITIATED]: "Uważany za",
                [ConciliumStatus.SPECIALIST_APPROVED]: "Zatwierdzony",
                [ConciliumStatus.SPECIALIST_REFUSED]: "Odrzucony",
                [ConciliumStatus.FINALIZED]: "Zakończony",
                [ConciliumStatus.CANCELED]: "Odwołany",
                [ConciliumStatus.INJURED]: "Ranny",
            },
            specSettings: {
                cashOutButton: "Wypłata środków",
                cashOutModal: {
                    header: "Prośba o wycofanie",
                    amount: "Suma",
                    note: "Jak możesz przyjmować środki?",
                    send: "Wysłać",
                    payoneerId: "Payoneer Id",
                    skrillId: "Skrill Id",
                    validations: {
                        required: "Wymagany!",
                        addNotes: "Dodaj opis!",
                        greaterThenZero: "Musi być powyżej 0!",
                        exact8Characters: "Musi mieć 8 znaków!",
                        exact9Characters: "Musi mieć 9 znaków!",
                        cashOutError:
                            "Wystąpił błąd. Proszę spróbować później!",
                    },
                    promocode: {
                        header: "Użyty kod promocyjny",
                        name: "Kod promocyjny",
                        value: "Suma",
                        confirmedError:
                            "Ze względu na użycie kodu promocyjnego wypłata środków jest zawieszona",
                    },
                    email: "Adres e-mail lub inny identyfikator odbiorcy",
                    conditionsAccepted:
                        "Użytkownik potwierdza, że posiada pełną zdolność do czynności prawnych, przeczytał, zrozumiał i akceptuje <1>Warunkami świadczenia</1> Medical Concilium oraz <3>Politykę prywatności</3>, a także wyraża zgodę na przetwarzanie danych osobowych zgodnie z nimi. Użytkownik wyraża również zgodę na pobieranie wszelkich opłat, które mogą być naliczane przez organizacje zewnętrzne za przetwarzanie płatności.",
                },
                accountBallance: {
                    current: "Aktualne saldo",
                    wallet: "Portmonetka",
                },
                cashInButton: "doładuj saldo",
                cashInModal: {
                    header: "Uzupełnianie salda",
                    add: "Uzupełniać",
                },
            },
            userSettings: {
                deactivateUserAccount: {
                    deactivate: "Dezaktywacja",
                    deactivateAccount: "Dezaktywuj konto",
                    deactivateAccount2: "Dezaktywować",
                    deactivateMessage:
                        "Po dezaktywacji nie będziesz mógł wejść do urzędu i prowadzić czynności w tym profilu na portalu Medical Consilium!",
                    deactivateMessage2:
                        "Jesteś pewny? Po dezaktywacji Twoje konto zostanie usunięte. Usuniętych kont nie można przywrócić.",
                    passwordPlaceholder: "hasło",
                    deactivateUserMessage1: "Dezaktywujesz użytkownika ",
                    deactivateUserMessage2:
                        "Czy na pewno? Po dezaktywacji to konto zostanie usunięte. Usuniętych kont nie można przywrócić.",
                },
            },
            specRequest: {
                deactivationSectionDescription:
                    "Możesz anulować aplikację i usunąć wszystkie powiązane z nią dane. Po zakończeniu operacji odzyskanie danych będzie niemożliwe.",
                deactivationConfirmation:
                    "Po potwierdzeniu wszystkie dane zostaną usunięte. Czy chcesz kontynuować?",
                deleteRequest: "Usuń prośbę",
                requestCanNotBeDeleted: "Żądanie nie może zostać usunięte.",
                requestTitle: "Wniosek",
                confirmationModal: {
                    header: "Wysłać prośbę?",
                    body: "<0>Twój wniosek o udział w projekcie Medical Concilium jako Specjalista zawiera pewne sekcje, które nie są wymagane.</0><1>Możesz wysłać wniosek z taką ilością informacji lub je uzupełnić.</1><2>W celu szybkiego i pomyślnego zatwierdzenia wniosku zalecamy wypełnienie jak największej ilości informacji i dołączenie plików, które je potwierdzają. Znacznie zwiększy to Twoją ocenę i popularność jako specjalisty ds. projektów, zwiększy liczbę zapytań od użytkowników.</2>",
                    send: "Wysłać",
                    append: "Edytuj",
                },
                specRequestSentModal: {
                    header: "Żądanie zostało wysłane",
                    body: "Twój wniosek o udział w projekcie jako {{specialtyTypeLabel}} został pomyślnie złożony. Zostaniesz poinformowany o wynikach weryfikacji wniosku.",
                },
                errorModal: {
                    requirements: `
                    Aby ubiegać się o udział w projekcie jako Specjalista, należy wypełnić minimum wymaganych sekcji:
                    <1>
                    <0>Zdjęcie</0>
                    <2>Specjalizacja</2>
                    <3>Znajomość języków</3>
                    <4>O mnie</4>
                    <5>Wykształcenie</5>
                    <6>Miejsce pracy</6>
                    <7>Koszt usług</7>
                    </1>
                    W celu szybkiego i pomyślnego zatwierdzenia aplikacji zalecamy wypełnienie jak największej ilości informacji i dołączenie plików pomocniczych.
                    Może to znacznie podnieść twoją ocenę i popularność jako specjalisty ds. projektów oraz zwiększyć liczbę zapytań od użytkowników.

                    `,
                },
            },
            payments: {
                transactionsTimeout: "Płatność będzie dostępna za",
                paymentFailedToCreate: "Płatność nie została utworzona.",
                paymentFailed: "Wystąpił błąd podczas tworzenia płatności.",
                paymentCreated:
                    "Płatność utworzona, ale nieukończona. Skontaktuj się z Administratorem, aby sprawdzić status płatności.",
                paymentWasNotCreated:
                    "Płatność nie została utworzona. Proszę spróbuj ponownie.",
                fields: {
                    accountId: "Identyfikator płatnika",
                    transactionType: "Typ transakcji",
                    userId: "Użytkownik",
                    toUserId: "Podmiot",
                    total: "Suma",
                    balanceFromType: "Typ konta nadawcy",
                    balanceFromBefore: "Stan konta nadawcy przed",
                    balanceFromAfter: "Stan konta nadawcy po",
                    balanceToType: "Typ konta odbiorcy",
                    balanceToBefore: "Stan konta odbiorcy przed",
                    balanceToAfter: "Stan konta odbiorcy po",
                    paymentMethod: "Metoda płatności",
                    userSpecialistsPromocodeId: "Kod promocyjny",
                    paymentTransactionId: "ID transakcji w systemie płatności",
                    isReverted: "Zwrócona",
                    desiredTotal: "Oczekiwana suma",
                    adminId: "Administrator",
                    decisionDate: "Data i godzina podjęcia decyzji (UTC)",
                    adminComment: "Komentarz administratora",
                    conditionsAccepted: "Warunki użytkowania zaakceptowane",
                    requestLocale: "Język żądania wypłaty",
                    relatedTransactionId: "Identyfikator powiązanej transakcji",
                },
                admin: {
                    detailsHeader: "Szczegóły transakcji",
                    generalSection: "Ogólne",
                    balancesSection: "Salda",
                    paymentMethodSection: "Metoda płatności",
                    adminApprovalSection: "Decyzja administratora",
                    editPopup: {
                        title: "Zakończ transakcję",
                    },
                },
            },
            specialistSearch: {
                translatorAlreadyAdded: "Już wybrałeś Tłumacza!",
                selectAnotherTranslator: "Wybrać innego Tłumacza",
                administratorAlreadyAdded:
                    "Już wybrałeś administratora konsyliuma!",
                selectAnotherAdministrator: "Wybierz innego administratora",
            },
            admin: {
                cashOutPage: {
                    minMessageLength:
                        "Wiadomość musi zawierać więcej niż {{length}} znaków",
                    maxPaymentTransactionIdLength:
                        "Transakcja D musi zawierać mniej niż {{length}} znaków",
                },
                concilium: {
                    details: {
                        header: "Chronologia konsultacji/Consilium",
                    },
                },
                eventsTab: {
                    eventsType: "Wydarzenie",
                    hasMetaData: "Metadane",
                    details: {
                        header: "Wydarzenie",
                        closedConciliums:
                            "Zamknięte w oczekiwaniu na konsultacje (specjaliści nie odpowiedzieli)",
                        finalizedConciliums:
                            "Sfinalizowane konsultacje (zakończone pod koniec czasu konsultacji)",
                        refusedConciliums:
                            "Automatycznie odrzucone (żaden specjalista medyczny nie dołączył)",
                        refuseCandidatesConciliums:
                            "Consiliums screened for automatic refusal",
                    },
                },
                platformSettings: {
                    fields: {
                        penaltyUserMin: "Minimum dla użytkownika",
                        penaltyUserPercent:
                            "Wartość procentowa dla użytkownika",
                        penaltySiteMin: "Minimum dla posiadacza witryny",
                        penaltySitePercent:
                            "Wartość procentowa dla posiadacza witryny",
                        platformFee: "Procentowa marża platformy",
                    },
                    common: {
                        header: "Ustawienia platformy",
                        penaltyTitle: "Stawki kar",
                        platformFeeTitle: "Nagrody platformy",
                        submitButtonLabel: "Aktualizacja",
                        success: "Ustawienia zostały zaktualizowane",
                    },
                },
            },
            formErrors: {
                required: "Wymagany!",
                requiredPassword: "Hasło jest polem wymaganym",
            },
            fields: {
                firstName: "Nazwa",
                phoneNumber: "Numer telefonu",
                description: "Tekst wiadomości",
                comment: "Komentarz",
                language: "Język",
                created: "Data utworzenia",
                updated: "Data modyfikacji",
            },
            enums: {
                supportTicketCustomerRole: {
                    [SupportTicketCustomerRole.GUEST]: "Użytkownik",
                    [SupportTicketCustomerRole.SPECIALIST]: "Specjalista",
                },
                supportTicketSubject: {
                    [SupportTicketSubject.REGISTRATION]: "Zarejestruj się",
                    [SupportTicketSubject.CONCILIUMS]: "Konsultację/Konsylium",
                    [SupportTicketSubject.PAYMENTS]: "Płatności",
                    [SupportTicketSubject.FEES]: "Kary/Opłaty",
                    [SupportTicketSubject.OFFER]: "Propozycje",
                    [SupportTicketSubject.TROUBLESHOOT]: "Usuwanie usterek",
                    [SupportTicketSubject.COLLABORATION]: "Współpraca",
                    [SupportTicketSubject.ADVERTIZING]: "Reklama",
                    [SupportTicketSubject.ARBITRATION]: "Arbitraż",
                    [SupportTicketSubject.OTHER]: "Inne",
                },
                recordStatus: {
                    [RecordStatus.CREATED]: "Wygenerowane",
                    [RecordStatus.APPROVED]: "Potwierdzony",
                    [RecordStatus.DECLINED]: "Odwołany",
                    [RecordStatus.IN_REVIEW]: "Rozważany",
                },
                transactionType: {
                    [TransactionType.BALANCE_REPLENISHMENT]:
                        "Doładowanie salda",
                    [TransactionType.RESERVATION]: "Rezerwacja środków",
                    [TransactionType.SERVICE_PAYMENT]: "Płatność za usługę",
                    [TransactionType.SERVICE_RECEIPT]:
                        "Otrzymanie płatności za usługę",
                    [TransactionType.WITHDRAW]: "Wypłata środków",
                    [TransactionType.CANCEL_RESERVATION]:
                        "Anulowanie rezerwacji",
                    [TransactionType.PAYMENT_TO_SITE_OWNER]:
                        "Płatność dla właściciela strony",
                    [TransactionType.PENALTY]: "Kara",
                    [TransactionType.ARBITRATION]: "Arbitraż",
                    [TransactionType.COMPENSATION]: "Odszkodowanie",
                    [TransactionType.PROMOCODE_APPLIED]:
                        "Zastosowanie kodu promocyjnego",
                    [TransactionType.PROMOCODE_REMOVED]:
                        "Usunięcie kodu promocyjnego",
                    [TransactionType.CANCEL_WITHDRAW_RESERVATION]:
                        "Anulowanie rezerwacji wypłaty",
                    [TransactionType.WITHDRAW_RESERVATION]:
                        "Rezerwacja środków do wypłaty",
                },
                balanceType: {
                    [BalanceType.MAIN_BALANCE]: "Saldo główne",
                    [BalanceType.TRANSIT]: "Saldo tranzytowe",
                    [BalanceType.PROFIT]: "Zysk",
                    [BalanceType.PENALTY]: "Saldo karne",
                    [BalanceType.PROMOCODE]: "Kod promocyjny",
                },
                paymentMethod: {
                    [PaymentMethod.NONE]: "Waluta platformy",
                    [PaymentMethod.PAYPAL]: "PayPal",
                    [PaymentMethod.PAYONEER]: "Payoneer",
                    [PaymentMethod.SKRILL]: "Skrill",
                },
                transactionStatus: {
                    [TransactionStatus.IN_PROGRESS]: "W trakcie realizacji",
                    [TransactionStatus.WAITING]: "Oczekująca",
                    [TransactionStatus.COMPLETED]: "Zakończona",
                    [TransactionStatus.CANCELED]: "Anulowana",
                    [TransactionStatus.WAITING_FOR_TRANSFER]:
                        "Obsługiwany przez",
                },
                currencyType: {
                    [CurrencyType.USD]: "USD",
                    [CurrencyType.EUR]: "EUR",
                    [CurrencyType.PLN]: "PLN",
                },
                asSpecialistType: {
                    [SpecialistType.MEDICAL_SPECIALIST]:
                        "Specjalisty medycznego",
                    [SpecialistType.TRANSLATOR]: "Tłumacza",
                    [SpecialistType.ADMINISTRATOR]: "Administratora konsylium",
                },
                eventType: {
                    [EventType.CRON_CONCILIUM]:
                        "Automatyczne zakończenie konsultacji",
                    [EventType.CRON_GENERATE_VIDEO_TOKENS]:
                        "Automatyczne generowanie sesji wideokonferencji",
                },
            },
            customerSupport: {
                fields: {
                    lastName: "Imię i nazwisko",
                    middleName: "Imię patronimiczne (opcjonalnie)",
                    description: "Wiadomość tekstowa",
                    email: "E-mail (jeśli jesteś zarejestrowanym użytkownikiem, podaj swój adres e-mail wkazany przy rejestracji) ",
                    country: "Kraj/Region",
                    customerRole: "Odwołujesz się jako (wybierz odpowiednio)",
                    subjectType: "Wybierz interesujące Cię zagadnienie",
                    ticketName: "Nazwa",
                },
                createTicketForm: {
                    header: "Skontaktuj się z nami",
                    conditionsAccepted:
                        "Zaznaczając to pole akceptujesz <1>Warunkami świadczenia</1> i <3>Politykę Prywatności</3> serwisu oraz wyrażasz zgodę na kontakt telefoniczny i mailowy w sprawie Twojego zapytania. Będziemy przetwarzać Twoje dane zgodnie z naszą Polityką Prywatności.",
                    sendButton: "Wysłać",
                    successMessage:
                        "ВTwoja prośba {{ticketName}} została wysłana do pomocy technicznej. Skontaktujemy się z Tobą za pomocą adresu e-mail, który został podany podczas rejestracji zapytania.",
                    successTitle: "Utworzono odwołanie",
                    successButton: "OK",
                },
                adminTab: {
                    header: "Pytania użytkowników",
                    table: {
                        ticketName: "Nazwa",
                        subjectType: "Temat",
                        fullName: "Nazwa użytkownika",
                        customerRole: "Rola użytkownika",
                        emailSent: "E-mail wysłany",
                        isCustomerRegistered: "Użytkownik jest autoryzowany",
                        created: "Data utworzenia",
                        isEmailRegistered: "E-mail jest zarejestrowany",
                        updated: "Data modyfikacji",
                    },
                    sendEmailBtn: "Wysłać email",
                    changeStatusBtn: "Zmień status",
                    editPopup: {
                        title: "Edytuj odwołanie",
                    },
                    detailsHeader: "Szczegóły Apelu",
                },
            },
            maxSpecialistsModal: {
                edit: "Edytować",
            },
            registration: {
                common: {
                    loginLabel: "Wejść",
                    registrationLabel: "Rejestracja",
                    generalLabel: "Ogólne",
                    birthdayLabel: "Wprowadź swoją datę urodzenia",
                    timezoneLabel: "Strefa czasowa",
                    languageLabel: "Język komunikacji",
                    currencyLabel: "Waluta",
                    timezoneInfo:
                        "Informacje o czasie są wyświetlane zgodnie z określoną lokalizacją.",
                    languageInfo:
                        "Wybierz języki, którymi mówisz na wystarczającym poziomie, aby w pełni przeprowadzić konsultację bez udziału tłumacza.",
                    currencyInfo:
                        "Wskazane koszty i płatności za produkty na stronie dokonywane są w walucie projektu - Kredyty Medical Concilium. Wszystkie przeliczenia na wybraną walutę będą przybliżone i służą wyłącznie celom informacyjnym.",
                    passwordLabel: "Hasło",
                    submitButtonLabel: "Rejestr",
                },
                fields: {
                    name: "Imię",
                    surname: "Nazwisko",
                    patronimyc: "Imię ojca",
                    email: "Poczta",
                    phone: "Numer telefonu",
                    city: "Miasto",
                    country: "Państwo",
                    currency: "Waluta",
                    language: "Język",
                    timezone: "Strefa czasowa",
                    birthday: "Data urodzenia",
                    password: "Hasło",
                    confirmPassword: "Powtórz hasło",
                },
                errors: {
                    age: "Musisz mieć ukończone {{age}} lat",
                    passwordMismatch: "Hasła nie pasują",
                },
                successModal: {
                    title: "Gratulacje!",
                    info: "Zarejestrowałeś się pomyślnie w projekcie Medical Concilium.",
                    mainLabel: "Główny",
                    loginLabel: "Zaloguj się",
                },
                selectPlaceholders: {
                    country: "Wybierz kraj",
                    currency: "Wybierz walutę",
                    language: "Wybierz język",
                    timezone: "Wybierz strefę czasową",
                },
            },
            login: {
                common: {
                    loginLabel: "Wejść",
                    registrationLabel: "Rejestracja",
                    emailLabel: "Wprowadź swój adres email",
                    passwordLabel: "Wprowadź hasło",
                    forgotPasswordLabel: "Zapomniałeś hasła?",
                    submitButtonLabel: "Zaloguj się",
                },
                fields: {
                    email: "Poczta",
                    password: "Hasło",
                },
            },
            mainHeader: {
                featuresBtn: "Osobliwości",
                chooseSpecBtn: "Wybierz specjalistę",
                requestBtn: "Wniosek",
                becomeSpecBtn: "Zostań specjalistą",
                languages: {
                    ru: "Rosyjski",
                    uk: "Ukraiński",
                    en: "Angelski",
                    pl: "Polskie",
                },
                userOptions: {
                    consults: "Konsultacje",
                    favorites: "Ulubione",
                    settings: "Ustawienia",
                    support: "Pomoc",
                    logout: "Wyjść",
                },
                login: "Wejść",
                registration: "Rejestracja",
                search: "Szukaj",
                notifications: {
                    personal: "Osobiste",
                    public: "Ogólne",
                    showAll: "Pokaż wszystkie powiadomienia",
                },
            },
            project: {
                name: "Medical Concilium",
            },
            mainPage: {
                title: "Możliwości projektu",
                searchSpec: "Zamów konsultację",
                searchConc: "Zwołaj konsylium",
                howItWorks: "Jak to działa?",
                start: "Rozpocznij",
            },
            footer: {
                aboutUs: "O nas",
                features: "Osobliwości",
                articles: "Społeczność",
                support: "Wsparcie",
                slogan: "Łączymy wiedzę najlepszych, by dawać zdrowie!",
                spec: "Zamów konsultację",
                conc: "Zwołaj konsylium",
                login: "Wejść",
                register: "Rejestracja",
                termsOfUse: "Warunki korzystania",
                privacyPolicy: "Polityka prywatności",
                fullVersion: "Pełna wersja strony",
                right: {
                    medSpec: "Specjalista medyczny",
                    lang: "Tłumacz",
                    admin: "Administrator konsyliuma",
                },
            },
            notFound: {
                title: "404",
                message: "Strona, której szukasz, nie została znaleziona.",
                link: "Wróć do strony głównej",
            },
        },
    },
};
