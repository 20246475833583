import { conciliumLink, documentsAllowedExtensions, maxFileSizeBytes } from "../Config";
import { CustomFile } from "../domain/models/CustomFile";
import { FileDTO } from "../domain/models/dto/FileDTO";
import { ParentType } from "../enums/parent-type";

export class FileModel implements CustomFile {
    id?: string;
    parentType?: ParentType;
    parentId?: string;
    filePath?: string;
    dataUrl?: string;
    isRemoved?: boolean = false;

    fileName?: string;
    extension?: string;
    fileUrl?: string;
    originalFileName?: string;
    creatorId?: string;
    created?: string;
    updated?: string;

    size: number;

    constructor(data: FileDTO) {
        Object.assign(this, data || {});
    }

    get isNewValid(): boolean {
        return !this.id && !!this.dataUrl && !this.isRemoved;
    }

    get needToBeRemoved(): boolean {
        return !!(this.id && this.isRemoved);
    }

    get isAbandoned(): boolean {
        return !this.id && !!this.isRemoved;
    }

    markAsRemoved() {
        this.isRemoved = true;
    }

    setParentId(parentId: string) {
        this.parentId = parentId;
    }

    setSize(size: number) {
        this.size = size;
    }

    isSizeValid(maxSizeBytes?: number): boolean {
        return (maxSizeBytes || maxFileSizeBytes) > this.size;
    }

    isExtensionValid(allowedExtensions?: string[]): boolean {
        return (allowedExtensions || documentsAllowedExtensions).includes(this.extension);
    }

    get fullFilePath () {
        return conciliumLink + this.fileUrl;
    }
}

