import moment from "moment";
import React, { FC } from "react";

const TimeView: FC<{ time: moment.Moment }> = (props) => {
    const time = props.time;
    const hours = time.hours();
    const minutes = time.minutes();
    return (
        <>
            {hours < 10 ? "0" + hours : hours}:{minutes === 0 ? "0" + minutes : minutes}
        </>
    );
};

export default TimeView;
