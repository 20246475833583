import React from "react";
import { MIN_TIME_TO_CONCILIUM } from "../../domain/constants/Constants";
import { NewConferenceSpelialistsSelectDateTime } from "./NewConferenceSpelialistsSelectDateTime";
import { NewConferenceState } from "./NewConferenceState";

export class NewConferenceSmallTime extends NewConferenceState {
    goToPrevStep(): void {
        this.props.context.transitionTo(NewConferenceSpelialistsSelectDateTime);
    }

    cancel(): void {
        this.props.context.handlerCancel();
    }

    render() {
        return (
            <div className="content content_margin-top">
                <div className="modal active">
                    <div className="modal__body_ns advice_ns">
                        <div className="advice__top_ns">
                            <img src="/static/images/minfo.png" alt="" />
                            <div className="advice__top--headline_ns">{this.props.t("general.information")}</div>
                        </div>
                        <div className="advice__body_ns">
                            <div className="advice__body--text_ns">
                                {this.props.t("newConferenceProcess.smallTime.text_1")} {MIN_TIME_TO_CONCILIUM}{" "}
                                {this.props.t("newConferenceProcess.smallTime.text_2")}
                            </div>
                            <div className="advice__buttons_ns">
                                <a
                                    onClick={() => {
                                        this.cancel();
                                    }}
                                >
                                    <div className="advice__button_ns">
                                        {this.props.t("newConferenceProcess.smallTime.cancel")}
                                    </div>
                                </a>
                                <a
                                    onClick={() => {
                                        this.goToPrevStep();
                                    }}
                                >
                                    <div className="advice__time_ns arrowNewApplic">
                                        <img src="/static/images/time.png" alt="" />
                                        {this.props.t("newConferenceProcess.smallTime.changeTime")}
                                    </div>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
