import React, { FC } from "react";
import SimpleClosingButton from "../../shared/SimpleClosingButton";
import { CustomFile } from "../../../domain/models/CustomFile";
import SimpleClip from "../../shared/SimpleClip";

interface ConferenceFileViewProps {
    file: CustomFile;
    deleteFileCallback: (file: CustomFile) => void;
}

const ConferenceFileView: FC<ConferenceFileViewProps> = (props) => {
    return (
        <div id={props.file.id} style={{ display: "flex"}}>
            <SimpleClip small width={12} height={12} />
            <label className="msecond__file--label msecond__file--label--truncate">
                <a href={props.file.fullFilePath} title={props.file.originalFileName} className="msecond__file--text">{props.file.originalFileName}</a>
            </label>
            <div className="msecond__file--label--close">
                <SimpleClosingButton width={10} height={10} callback={() => props.deleteFileCallback(props.file)} />
            </div>
        </div>
    );
};

export default ConferenceFileView;
