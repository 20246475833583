import { getEnumTranslatedDropDownOptions } from "../../tools/EnumTools";
import { SelectProps } from "../models/SelectProps";
import { Translate } from "../models/Translate";

export enum SupportTicketSubject {
    REGISTRATION = 1,
    CONCILIUMS = 2,
    PAYMENTS = 3,
    FEES = 4,
    OFFER = 5,
    TROUBLESHOOT = 6,
    COLLABORATION = 7,
    ADVERTIZING = 8,
    ARBITRATION = 9,
    OTHER = 10,
}

const translatePrefix = "enums.supportTicketSubject.";

export function getSupportTicketSubjectTranslation(
    t: Translate,
    requestCustomerRole: SupportTicketSubject
): SelectProps {
    return {
        value: requestCustomerRole?.toString(),
        label: t(
            requestCustomerRole && `${translatePrefix}${requestCustomerRole}`
        ),
    };
}

export function getSupportTicketSubjectDropdownOptions(
    t: Translate
): SelectProps[] {
    return getEnumTranslatedDropDownOptions(
        SupportTicketSubject,
        translatePrefix,
        t
    );
}
