import React, { FC, useEffect } from "react";
import { NotificationModel } from "../../../../domain/models/NotificationModel";
import notificationServiceFactory from "../../../../services/NotificationServiceImpl";

interface NotificationsListProps {
    notifications: NotificationModel[];
    readNotificationsCallback: () => void;
}

const NotificationsList: FC<NotificationsListProps> = (props) => {
    useEffect(() => {
        const notificationService = notificationServiceFactory();
        (async () => {
            const data = await notificationService.readNotifications(props.notifications.map((item) => item.id));
            if (data) {
                props.readNotificationsCallback();
            }
        })();
    }, [props.notifications]);

    return (
        <div className="notif__tab__content" style={{ display: "flex" }}>
            {props.notifications.map((element) => {
                return (
                    <div key={element.id} className="notif__tab__content--item">
                        <div className="notif__tab__heading">{element.title}</div>
                        <div className="notif__tab__text">{element.message}</div>
                        <div className="notif__tab__date">{element.localTimeCreated}</div>
                    </div>
                );
            })}
        </div>
    );
};

export default NotificationsList;
