import { get, patch } from "../tools/Tools";
import { NotificationFields } from "../domain/enums/NotificationFields";
import { SortingOrder } from "../domain/enums/SortingOrder";
import { NotificationModel } from "../domain/models/NotificationModel";
import { NotificationService } from "../domain/services/NotificationService";
import { conciliumLink } from "../Config";
import notificationMapperFactory from "../mappers/NotificationMapper";
import { BaseServiceImpl } from "./BaseServiceImpl";

class NotificationServiceImpl
    extends BaseServiceImpl
    implements NotificationService
{
    async getNotifications(
        page: number,
        limit: number,
        isPersonal: boolean,
        sortingField: NotificationFields,
        sortingOrder: SortingOrder
    ): Promise<NotificationModel[]> {
        try {
            const result = await get(
                `${conciliumLink}/api/notifications?page=${page}&limit=${limit}&isPersonal=${isPersonal}&sortingField=${sortingField}&sortingOrder=${sortingOrder}`
            );
            const mapper = notificationMapperFactory();
            return result.data?.map((element) => mapper.fromDTO(element)) || [];
        } catch (err) {
            console.log("NotificationServiceImpl.getNotifications => ERROR:");
            console.log(err);
        }
    }

    async readNotifications(notificationsIds: string[]): Promise<boolean> {
        try {
            const result = await patch(
                `${conciliumLink}/api/notifications/read`,
                { ids: notificationsIds }
            );
            return this.getData<{ status: boolean }>(result, { status: false })
                .status;
        } catch (err) {
            console.log("NotificationServiceImpl.getNotifications => ERROR:");
            console.log(err);
        }
    }
}

export default function notificationServiceFactory() {
    return new NotificationServiceImpl();
}
