import * as _ from "lodash";
import { Mapper } from "../domain/mappers/mapper";

export abstract class SimpleMapper<I, O> implements Mapper<I, O> {
    protected toDTOFields?: string[];
    protected fromDTOFields?: string[];
    protected entityConstructor?: any;

    constructor() {}

    toDTO(model: I): O {
        if (!model) {
            return;
        }
        return _.pick(
            model,
            this.toDTOFields || this.fromDTOFields
        ) as any as O;
    }

    fromDTO(dto: O, params?: any): I {
        if (!dto) {
            return;
        }
        const model = _.pick(dto, this.fromDTOFields || this.toDTOFields);
        return this.entityConstructor
            ? new this.entityConstructor(model, params)
            : model;
    }
}
