import React, { FC, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import widgetServiceFactory from "../../services/WidgetServiceImpl";
import { WidgetPages } from "../../enums/widget-pages-enum";
import { Langs } from "../../enums/langs-enum";
import { WidgetBlocks } from "../../domain/models/Widget";
import MainPageContent from "./MainPageContent";
import MainHeader from "../headers/main-header/MainHeader";
import MainFooter from "../footer/MainFooter";

interface MainPageProps {}

const widgetService = widgetServiceFactory();

const MainPage: FC<MainPageProps> = () => {
    const { t, i18n } = useTranslation();
    const [widgetBlocks, setWidgetBlocks] = useState<WidgetBlocks>(null);

    useEffect(() => {
        (async () => {
            setWidgetBlocks(await widgetService.getAllWidgets(WidgetPages.INDEX, i18n.language as Langs));
        })();
    }, []);

    return (
        <>
            <title>
                {t("project.name")} - {t("mainPage.title")}
            </title>
            <MainHeader />
            {widgetBlocks && <MainPageContent widgetBlocks={widgetBlocks} />}
            <MainFooter />
        </>
    );
};

export default MainPage;
