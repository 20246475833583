import React, { useState, useEffect, createContext, useContext, useCallback } from "react";
// import { useNavigate } from "react-router-dom";
import { User } from "../domain/models/User";
import { userServiceFactory } from "../services/UserServiceImpl";
import { LoginForm } from "../domain/forms/LoginForm";

function AuthProvider(props: any) {
    // const navigate = useNavigate();

    const [user, setUser] = useState<User>();
    const [loading, setLoading] = useState(true);

    const userService = userServiceFactory();

    useEffect(() => {
        (async function () {
            const currentUser = await userService.getCachedUser();
            if (currentUser) {
                setUser(currentUser);
            }

            setLoading(false);
        })();
    }, [userService]);

    const signIn = useCallback(
        async (data: LoginForm) => {
            const result = await userService.login(data);
            if (result) {
                setUser(result);
            }
            return result;
        },
        [userService]
    );

    const signOut = useCallback(async () => {
        setUser(undefined);
        // await userService.logout();
        // navigate("/login");
    }, [
        userService,
        // navigate
    ]);

    return <AuthContext.Provider value={{ user, signIn, signOut, loading }} {...props} />;
}

interface AuthorizationContext {
    user?: User;
    signIn: (data: LoginForm) => Promise<User | undefined>;
    signOut?: any;
    loading?: any;
}

async function defaultSignIn(_data: LoginForm) {
    return undefined;
}

const AuthContext = createContext<AuthorizationContext>({
    signIn: defaultSignIn,
});
const useAuth = () => useContext(AuthContext);

export { AuthProvider, useAuth };
