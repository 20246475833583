import React from "react";
import { NewConferenceSpelialistsSelectDateTime } from "./NewConferenceSpelialistsSelectDateTime";
import { NewConferenceState } from "./NewConferenceState";

export class NewConferenceRequiredAdmin extends NewConferenceState {
    async goToNextStep(): Promise<void> {
        this.cartService.cart.setSkipAdmin();
        await this.cartService.saveAndUpdate();
        this.props.context.transitionTo(NewConferenceSpelialistsSelectDateTime);
    }

    navigateToAdminSearchPage(): void {
        this.props.context.handlerCancel();
        window.location.replace("/search/admin");
    }

    render() {
        return (
            <div className="content content_margin-top">
                <div className="modal active" id="searchCM">
                    <div className="modal__body_ns advice_ns">
                        <div className="advice__top_ns">
                            <img src="/static/images/a3.png" alt="" />
                            <div className="advice__top--headline_ns">
                                {this.props.t("newConferenceProcess.requiredAdmin.adminHeader")}
                            </div>
                        </div>
                        <div className="advice__body_ns">
                            <div className="advice__body--text_ns">
                                {this.props.t("newConferenceProcess.requiredAdmin.adminText")}
                            </div>
                            <div className="advice__buttons_ns">
                                <a onClick={() => this.goToNextStep()}>
                                    <div className="advice__button_ns">
                                        {this.props.t("newConferenceProcess.extra.next")}
                                    </div>
                                </a>
                                <a onClick={() => this.navigateToAdminSearchPage()}>
                                    <div className="advice__button_ns advice__button--row">
                                        {this.props.t("newConferenceProcess.requiredAdmin.adminButton")}
                                    </div>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
