export const getExtensionByMimeType = (mimeType: string) => {
    const mimeMap = {
        "text/plain": "txt",
        "text/csv": "csv",
        "application/pdf": "pdf",
        "application/msword": "doc",
        "application/vnd.openxmlformats-officedocument.wordprocessingml.document": "docx",
        "image/jpeg": "jpeg",
        "image/png": "png",
        "image/bmp": "bmp",
        "image/svg+xml": "svg",
        "image/webp": "webp"
    }

    return mimeMap[mimeType];
}

