import React, { FC } from "react";
import { withTranslation } from "react-i18next";
import { User } from "../../../../domain/models/User";
import MainHeaderMenuRight from "./MainHeaderMenuRight";
import MainHeaderMenuLeft from "./MainHeaderMenuLeft";
import MainHeaderMenuButtons from "./MainHeaderMenuButtons";

interface MainHeaderMenuProps {
    t(key?: string, values?: object): string;
    user?: User;
    isOpenedMenu: boolean;
}

const MainHeaderMenu: FC<MainHeaderMenuProps> = (props) => {
    return (
        <div id="mobile-menu" className={`mobile-menu ${props.isOpenedMenu ? "show_mobile_menu active" : ""}`}>
            <div className="mobile-menu__inner">
                <MainHeaderMenuLeft user={props.user} />
                <MainHeaderMenuRight />
            </div>
            <MainHeaderMenuButtons user={props.user} />
        </div>
    );
};

export default withTranslation()(MainHeaderMenu);
