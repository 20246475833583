import React from "react";
import NumberFormat from "react-number-format";
import { CartStatus } from "../../domain/enums/CartStatus";
import { ErrorService } from "../../domain/services/ErrorService";
import { ErrorServiceFactory } from "../../services/ErrorService";
import FixedSimpleError from "../shared/FixedSimpleError";
import DateTimeView from "./DateTimeView";
import { NewConferenceDetailsView } from "./details-view/NewConferenceDetailsView";
import { NewConferenceLatest } from "./NewConferenceLatest";
import { NewConferenceState } from "./NewConferenceState";
import SpecialistsList from "./SpecialistsList";
import { Trans } from "react-i18next";
import LoadingPanel from "../shared/LoadingPanel";

export class NewConferencePayment extends NewConferenceState {
    errorService: ErrorService = ErrorServiceFactory();
    constructor(props) {
        super(props);
        this.state = {
            checkoutButtonActive: false,
        };
    }

    goToNextStep(): void {
        this.props.context.transitionTo(NewConferenceLatest);
    }

    goToPrevStep(): void {
        this.props.context.transitionTo(NewConferenceDetailsView);
    }

    cancel(): void {
        this.props.context.handlerCancel();
    }

    toLatestModal = async () => {
        if (!this.state.checkoutButtonActive) {
            return;
        }

        this.setState({
            checkoutButtonActive: false,
        });

        try {
            this.setState({ loading: true });
            await this.cartService.updateCurrentCartStatus(CartStatus.PROCESS_COMPLETED);
            const cart = await this.cartService.createNewConcilium();
            this.setState({ loading: false });
            if (cart) {
                this.props.context.props.setCartStatus(CartStatus.PROCESS_COMPLETED);
                this.goToNextStep();
            }
        } catch (err) {
            this.setState({
                loading: false,
                error: this.props.t(this.errorService.getErrorTranslateKey(err, "errors.")),
            });
        }
    };

    componentDidMount = async () => {
        await this.cartService.updateCurrentCartStatus(CartStatus.PAYMENT_REQUIRED);
    };

    render() {
        const t = this.props.t;
        return (
            <>
                <LoadingPanel isVisible={this.state.loading} />
                <div id="create-concilium-container"></div>
                <div className="content content_margin-top">
                    <div className="modal active">
                        <div className="modal__body_ns">
                            <div className="create-concilium-form">
                                <button
                                    type="submit"
                                    name="submit"
                                    value="cancel"
                                    className="modal__step modal__step_prev screen_buttons form-action-button"
                                    data-modal="msecond"
                                >
                                    <div className="modal__step--count_ns">
                                        {this.props.t("newConferenceProcess.payment.step_2")}
                                    </div>
                                    <div className="modal__step--info">
                                        <div className="modal__step--name_ns">
                                            {this.props.t("newConferenceProcess.payment.detailsText")}
                                        </div>
                                        <img
                                            className="modal__step--arrow_ns"
                                            src="/static/images/arrowg.png"
                                            onClick={() => this.goToPrevStep()}
                                        />
                                    </div>
                                </button>
                            </div>
                            <div className="mfirst__top_ns mfirst__top_ns--pad modal__block__color-white">
                                <div className="mfirst__line_ns">
                                    <div className="mfirst__column">
                                        <div className="mfirst__headline_ns mfirst__headline_ns--start">
                                            {this.props.t("newConferenceProcess.details.proposal")}
                                            {this.cartService.cart.isConcilium
                                                ? this.props.t("newConferenceProcess.details.concilium")
                                                : this.props.t("newConferenceProcess.details.consultation")}
                                        </div>
                                    </div>
                                    <div className="mfirst__column mfirst__column--balance">
                                        <div className="mfirst__balance">
                                            <img className="mfirst__balance--icon" src="/static/images/money.png" />
                                            <div className="mfirst__balance--textBlack">
                                                <NumberFormat
                                                    displayType={"text"}
                                                    value={this.cartService.cart.user.balance}
                                                    decimalScale={2}
                                                    fixedDecimalScale={true}
                                                />
                                                {} $
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="msecond__list">
                                    <SpecialistsList t={this.props.t} />
                                </div>
                            </div>
                            <div
                                className="mfirst__bottom_ns mfirst__bottom_ns--pad modal__block__color-grey"
                                style={{ borderRadius: "0px", backgroundColor: "rgba(245, 243, 241) !important" }}
                            >
                                <DateTimeView t={this.props.t} />
                            </div>
                            <div className="mfirst__segment modal__block__color-white">
                                {this.cartService.cart.medicsExist && (
                                    <div className="mthird__line">
                                        <div className="mthird__line--name">
                                            {this.props.t("newConferenceProcess.payment.consultationText")}
                                            {this.cartService.cart.price.quantityMedics})
                                        </div>
                                        <div className="mthird__line--value">
                                            <NumberFormat
                                                displayType={"text"}
                                                value={this.cartService.cart.price.costMedics}
                                                decimalScale={2}
                                                fixedDecimalScale={true}
                                            />
                                            {} $
                                        </div>
                                    </div>
                                )}
                                {this.cartService.cart.translatorsExist && (
                                    <div className="mthird__line">
                                        <div className="mthird__line--name">
                                            {this.props.t("newConferenceProcess.payment.translatorText")}
                                            {this.cartService.cart.price.quantityTranslators})
                                        </div>
                                        <div className="mthird__line--value">
                                            <NumberFormat
                                                displayType={"text"}
                                                value={this.cartService.cart.price.costTranslators}
                                                decimalScale={2}
                                                fixedDecimalScale={true}
                                            />
                                            {} $
                                        </div>
                                    </div>
                                )}
                                {this.cartService.cart.adminsExist && (
                                    <div className="mthird__line">
                                        <div className="mthird__line--name">
                                            {this.props.t("newConferenceProcess.payment.adminText")}
                                            {this.cartService.cart.price.quantityAdmins})
                                        </div>
                                        <div className="mthird__line--value">
                                            <NumberFormat
                                                displayType={"text"}
                                                value={this.cartService.cart.price.costAdmins}
                                                decimalScale={2}
                                                fixedDecimalScale={true}
                                            />
                                            {} $
                                        </div>
                                    </div>
                                )}
                                <div className="mthird__line mthird__line_main">
                                    <div className="mthird__line--name">
                                        {this.props.t("newConferenceProcess.payment.allText")}
                                    </div>
                                    <div className="mthird__line--value">
                                        <NumberFormat
                                            displayType={"text"}
                                            value={this.cartService.cart.finalPrice}
                                            decimalScale={2}
                                            fixedDecimalScale={true}
                                        />
                                        {} $
                                    </div>
                                </div>
                            </div>
                            {this.cartService.cart.notEnoughFinance && (
                                <div className="mwarning">
                                    <div className="mwarning__text">
                                        {this.props.t("newConferenceProcess.payment.warningText")}
                                    </div>
                                    <a href="/user/settings#5">
                                        <div className="mwarning__button">
                                            {this.props.t("newConferenceProcess.payment.warningButton")}
                                        </div>
                                    </a>
                                </div>
                            )}
                            <div className="mfirst__segment border_bottom_radius modal__block__color-white">
                                <div className="create-concilium-form">
                                    <div className="settings__check">
                                        <input
                                            type="checkbox"
                                            id="22"
                                            required
                                            onChange={(e) => {
                                                this.setState({ checkoutButtonActive: e.target.checked });
                                            }}
                                        />
                                        <label
                                            className="settings__check--label settings__check--label--full settings__check--label--marg"
                                            htmlFor="22"
                                        >
                                            <div className="settings__check--squareStep3">
                                                <img
                                                    className="settings__check--inner"
                                                    src="/static/images/greencheck.png"
                                                />
                                            </div>
                                            <div className="settings__check--text_ns" style={{ textAlign: "justify" }}>
                                                <Trans i18nKey="newConferenceProcess.payment.confirm">
                                                    0
                                                    <a className="terms-and-conditions-link" href="/terms_of_use">
                                                        1
                                                    </a>
                                                    2
                                                    <a className="terms-and-conditions-link" href="/privacy_policy">
                                                        3
                                                    </a>
                                                    4
                                                </Trans>
                                            </div>
                                        </label>
                                        <FixedSimpleError
                                            error={this.state.error}
                                            className={"error-text-center font-size-small"}
                                        />
                                        <div className="mfirst__bottom_NextPrev">
                                            {/* TODO :: replace with BlueFormSubmitButton */}
                                            <button
                                                className={`mthird__button form-action-button
                                                    ${
                                                        this.cartService.cart.notEnoughFinance ||
                                                        !this.state.checkoutButtonActive
                                                            ? "mthird__button_negative"
                                                            : "mthird__button_positive"
                                                    }`}
                                                onClick={async () => {
                                                    this.toLatestModal();
                                                }}
                                            >
                                                {this.props.t("newConferenceProcess.payment.next")}
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="create-concilium-form">
                                <div className="mfirst__bottom_NextPrev mobile_buttons mobile_buttons--pad-pay">
                                    <button
                                        type="submit"
                                        name="submit"
                                        value="cancel"
                                        className="modal__step modal__step_prev-mobile form-action-button"
                                        data-modal="msecond"
                                    >
                                        <div className="modal__step--count_ns">
                                            {this.props.t("newConferenceProcess.payment.step_2")}
                                        </div>
                                        <div className="modal__step--info">
                                            <div className="modal__step--name_ns">
                                                {t("newConferenceProcess.payment.detailsText")}
                                            </div>
                                            <img
                                                className="modal__step--arrow_ns"
                                                src="/static/images/arrowg.png"
                                                onClick={() => this.goToPrevStep()}
                                            />
                                        </div>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    }
}
