import { ErrorServiceFactory } from "../services/ErrorService";

const errorService = ErrorServiceFactory();

export async function get(url: string) {
    const response = await fetch(url, {
        method: "GET",
        credentials: "include",
    });
    return response.json();
}

export async function patch(url: string, data?: any, method: string = "PATCH") {
    const response = await fetch(url, {
        method,
        credentials: "include",
        headers: { "Content-type": "application/json" },
        body: JSON.stringify(data),
    });
    if (!response.ok) {
        await errorService.throwResponseError(response);
    }
    return response.json();
}

export async function post(url: string, data: any) {
    return patch(url, data, "POST");
}

export async function deleteRequest(url: string, data?: any) {
    return patch(url, data, "DELETE");
}
