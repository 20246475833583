export function scrollToComponent(
    scrollToId: string,
    options?: ScrollIntoViewOptions
) {
    if (!scrollToId) {
        return;
    }
    const section = document.getElementById(scrollToId);
    if (!section) {
        return;
    }
    section.scrollIntoView(options);
}

export function showComponentById(componentId: string, show: boolean) {
    if (!componentId) {
        return;
    }
    const component = document.getElementById(componentId);
    if (component) {
        component.style.display = show ? "block" : "none";
    }
}
