export enum Errors {
    WRONG_USER_DATA = "WRONG_USER_DATA",
    SERVICE_UNAVAILABLE = "SERVICE_UNAVAILABLE",
    PROMOCODE_NOT_FOUND = "PROMOCODE_NOT_FOUND",
    PROMOCODE_IS_NOT_ACTIVE = "PROMOCODE_IS_NOT_ACTIVE",
    PROMOCODE_IS_NOT_APPLICABLE = "PROMOCODE_IS_NOT_APPLICABLE",
    PROMOCODE_LIMIT_ERROR = "PROMOCODE_LIMIT_ERROR",
    PROMOCODE_WITHDRAWAL_RESTRICTIONS = "PROMOCODE_WITHDRAWAL_RESTRICTIONS",
    PROMOCODE_START_ERROR = "PROMOCODE_START_ERROR",
    PROMOCODE_END_ERROR = "PROMOCODE_END_ERROR",
    PROMOCODE_ALREADY_APPLIED_ERROR = "PROMOCODE_ALREADY_APPLIED_ERROR",
    PROMOCODE_BONUS_ALREADY_APPLIED_ERROR = "PROMOCODE_BONUS_ALREADY_APPLIED_ERROR",

    PROMOCODE_START_DATE_EDIT_PROHIBITED = "PROMOCODE_START_DATE_EDIT_PROHIBITED",
    PROMOCODE_END_DATE_EDIT_PROHIBITED = "PROMOCODE_END_DATE_EDIT_PROHIBITED",
    PROMOCODE_VALUE_EDIT_PROHIBITED = "PROMOCODE_VALUE_EDIT_PROHIBITED",
    PROMOCODE_TYPE_EDIT_PROHIBITED = "PROMOCODE_TYPE_EDIT_PROHIBITED",
    PROMOCODE_USAGES_LIMIT_EDIT_PROHIBITED = "PROMOCODE_USAGES_LIMIT_EDIT_PROHIBITED",
    PROMOCODE_NAME_IS_NOT_UNIQUE = "PROMOCODE_NAME_IS_NOT_UNIQUE",

    USER_SPECIALIST_NOT_FOUND = "USER_SPECIALIST_NOT_FOUND",
    USER_SPECIALIST_DELETION_PROHIBITED = "USER_SPECIALIST_DELETION_PROHIBITED",
    USER_SPECIALIST_HAS_CONCILIUMS = "USER_SPECIALIST_HAS_CONCILIUMS",

    TRANSACTION_RECHARGE_ERROR = "TRANSACTION_RECHARGE_ERROR",

    USER_SPECIALIST_DOES_NOT_EXISTS = "USER_SPECIALIST_DOES_NOT_EXISTS",
    CAN_NOT_ADD_SPECIALIST = "CAN_NOT_ADD_SPECIALIST",
    ADD_MED_SPECIALIST_FIRST = "ADD_MED_SPECIALIST_FIRST",
    TRANSLATOR_ALREADY_ADDED = "TRANSLATOR_ALREADY_ADDED",
    NEED_MORE_MED_SPECIALISTS = "NEED_MORE_MED_SPECIALISTS",
    ADMINISTRATOR_ALREADY_ADDED = "ADMINISTRATOR_ALREADY_ADDED",
    SPECIALIST_ALREADY_ADDED = "SPECIALIST_ALREADY_ADDED",
    ERROR_ADDING_SPECIALIST = "ERROR_ADDING_SPECIALIST",
    MAX_PARTICIPANTS_NUMBER_REACHED = "MAX_PARTICIPANTS_NUMBER_REACHED",
    DOES_NOT_HAVE_FREE_TIME = "DOES_NOT_HAVE_FREE_TIME",

    CART_IS_EMPTY = "CART_IS_EMPTY",
    CART_TRANSLATOR_REQUIRED = "CART_TRANSLATOR_REQUIRED",
    CART_ADMINISTRATOR_REQUIRED = "CART_ADMINISTRATOR_REQUIRED",

    CART_STATUS_IS_NOT_DEFINED = "CART_STATUS_IS_NOT_DEFINED",
    CART_CAN_NOT_SET_STATUS = "CART_CAN_NOT_SET_STATUS",
    CART_PROCESS_IS_NOT_FINISHED = "CART_PROCESS_IS_NOT_FINISHED",
    CART_SPECIALISTS_TIME_DOES_NOT_MATCH = "CART_SPECIALISTS_TIME_DOES_NOT_MATCH",
    CART_SMALL_TIME_RESERVE = "CART_SMALL_TIME_RESERVE",
    CART_MEETING_TIME_IS_NOT_SET = "CART_MEETING_TIME_IS_NOT_SET",

    CONCILIUM_CREATION_ERROR = "CONCILIUM_CREATION_ERROR",

    INSUFFICIENT_BALANCE = "INSUFFICIENT_BALANCE",
    INSUFFICIENT_TRANSIT_BALANCE = "INSUFFICIENT_TRANSIT_BALANCE",
    CONDITIONS_NOT_ACCEPTED = "CONDITIONS_NOT_ACCEPTED",

    UNAUTHORIZED = "UNAUTHORIZED",

    USR_INCOMPLETE_INFORMATION = "USR_INCOMPLETE_INFORMATION",
    USR_INCORRECT_GRADUATION_YEAR = "USR_INCORRECT_GRADUATION_YEAR",
    USR_EXPERIENCE_WRONG_BEGIN_DATE = "USR_EXPERIENCE_WRONG_BEGIN_DATE",
    USR_EXPERIENCE_WRONG_END_DATE = "USR_EXPERIENCE_WRONG_END_DATE",
    USR_EXPERIENCE_WRONG_DATES_RANGE = "USR_EXPERIENCE_WRONG_DATES_RANGE",

    USR_INCORRECT_IMG_FILE_PARAMETERS_USER_PHOTO_TOO_LARGE = "USR_INCORRECT_IMG_FILE_PARAMETERS_USER_PHOTO_TOO_LARGE",
    USR_INCORRECT_TEXT_FILE_PARAMETERS_AWARD_TOO_LARGE = "USR_INCORRECT_TEXT_FILE_PARAMETERS_AWARD_TOO_LARGE",
    USR_INCORRECT_TEXT_FILE_PARAMETERS_EDUCATION_TOO_LARGE = "USR_INCORRECT_TEXT_FILE_PARAMETERS_EDUCATION_TOO_LARGE",
    USR_INCORRECT_TEXT_FILE_PARAMETERS_EXPERIENCE_TOO_LARGE = "USR_INCORRECT_TEXT_FILE_PARAMETERS_EXPERIENCE_TOO_LARGE",
    USR_INCORRECT_VIDEO_FILE_PARAMETERS_USER_VIDEO_TOO_LARGE = "USR_INCORRECT_VIDEO_FILE_PARAMETERS_USER_VIDEO_TOO_LARGE",

    USR_INCORRECT_IMG_FILE_PARAMETERS_USER_PHOTO_EXTENSION_NOT_ALLOWED = "USR_INCORRECT_IMG_FILE_PARAMETERS_USER_PHOTO_EXTENSION_NOT_ALLOWED",
    USR_INCORRECT_TEXT_FILE_PARAMETERS_AWARD_EXTENSION_NOT_ALLOWED = "USR_INCORRECT_TEXT_FILE_PARAMETERS_AWARD_EXTENSION_NOT_ALLOWED",
    USR_INCORRECT_TEXT_FILE_PARAMETERS_EDUCATION_EXTENSION_NOT_ALLOWED = "USR_INCORRECT_TEXT_FILE_PARAMETERS_EDUCATION_EXTENSION_NOT_ALLOWED",
    USR_INCORRECT_TEXT_FILE_PARAMETERS_EXPERIENCE_EXTENSION_NOT_ALLOWED = "USR_INCORRECT_TEXT_FILE_PARAMETERS_EXPERIENCE_EXTENSION_NOT_ALLOWED",
    USR_INCORRECT_VIDEO_FILE_PARAMETERS_USER_VIDEO_EXTENSION_NOT_ALLOWED = "USR_INCORRECT_VIDEO_FILE_PARAMETERS_USER_VIDEO_EXTENSION_NOT_ALLOWED",

    UR_USER_ALREADY_EXIST = 'UR_USER_ALREADY_EXIST',
    UR_EMAIL_ALREADY_EXIST = 'UR_EMAIL_ALREADY_EXIST',
    UR_LANGUAGE_DOES_NOT_EXIST = 'UR_LANGUAGE_DOES_NOT_EXIST',
    UR_CURRENCY_DOES_NOT_EXIST = 'UR_CURRENCY_DOES_NOT_EXIST',
    UR_COUNTRY_DOES_NOT_EXIST = 'UR_COUNTRY_DOES_NOT_EXIST',
    UL_INVALID_DATA = 'UL_INVALID_DATA',
    UL_RECAPTCHA_FAILED = 'UL_RECAPTCHA_FAILED',
    UL_EMAIL_NOT_ACTIVATED = 'UL_EMAIL_NOT_ACTIVATED',
    UL_USER_WAS_DEACTIVATED = 'UL_USER_WAS_DEACTIVATED',
}
