import { Component } from "react";
import { CartService } from "../../domain/services/new-conference-process/CartService";
import { cartServiceFactory } from "../../services/CartServiceImpl";
import { fileServiceFactory } from "../../services/FileServiceImpl";
import { NewConferenceContext } from "./NewConferenceContext";
import { FileService } from "../../domain/services/FileService";

interface NewConferenceStateProps {
    t(key?: string): string;
    context: NewConferenceContext;
}

interface NewConferenceStateState {
    showError?: boolean;
    dateTimeCurrentPage?: number;
    checkoutButtonActive?: boolean;
    error?: string;
    loading?: boolean;
}

export abstract class NewConferenceState extends Component<
    NewConferenceStateProps,
    NewConferenceStateState
> {
    context: NewConferenceContext;
    cartService: CartService;
    fileService: FileService;

    constructor(props) {
        super(props);
        this.cartService = cartServiceFactory();
        this.fileService = fileServiceFactory();

        this.state = { loading: false };
    }

    goToNextStep(): void { };
    goToPrevStep(): void { };
    cancel(_close: () => void): void { };
}
