import { useEffect } from "react";

export function useSmoothScroll(buttonClass: string, scrollToClass: string) {
    useEffect(() => {
        const t = document.querySelector(`.${buttonClass}`);
        if (!t) {
            return;
        }

        const eventHandler = () => {
            document.querySelector(`.${scrollToClass}`).scrollIntoView({
                behavior: "smooth",
                block: "start",
            });
        };

        t.addEventListener("click", eventHandler);
        return () => t.removeEventListener("click", eventHandler);
    }, []);
}
