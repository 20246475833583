import _ from "lodash";
import React from "react";

export interface ModalWrapperProps {
    children: any;
    class?: string;
}

export default function ModalWrapper(props: ModalWrapperProps) {
    return (
        <div className={`modal active${props.class ? " " + props.class : ""}`}>
            {..._.isArray(props.children) ? props.children : [props.children]}
        </div>
    );
}
