import { Mapper } from "../domain/mappers/mapper";
import { NotificationModel } from "../domain/models/NotificationModel";
import { NotificationDTO } from "../domain/models/dto/NotificationDTO";
import { NotificationModelImpl } from "../models/NotificationModelImpl";
import { SimpleMapper } from "./SimpleMapper";

class NotificationMapper extends SimpleMapper<
    NotificationModel,
    NotificationDTO
> {
    protected entityConstructor = NotificationModelImpl;

    protected fromDTOFields?: string[] = [
        "id",
        "userId",
        "isPersonal",
        "title",
        "message",
        "isRead",
        "created",
        "localTimeCreated",
    ];

    protected toDTOFields?: string[] = [];
}

export default function notificationMapperFactory(): Mapper<
    NotificationModel,
    NotificationDTO
> {
    return new NotificationMapper();
}
