import React from "react";

// TODO :: simplify this interface
export interface FixedSimpleErrorProps {
    error?: string;
    className?: string;
    useTouched?: boolean;
    touched?: boolean;
}

export default function FixedSimpleError(props: FixedSimpleErrorProps) {
    if ((props.useTouched && !props.touched) || !props.error) {
        return <></>;
    }

    return <div className={`form-field-error ${props.className ? props.className : ""}`}>{props.error}</div>;
}
