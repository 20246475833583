import { conciliumLink } from "../Config";
import { DEFAULT_PAGE, GET_ALL_RECORDS } from "../domain/constants/Constants";
import { CountryType } from "../domain/models/types/CountryType";
import { CurrencyType } from "../domain/models/types/CurrencyType";
import { CountryTypeDTO } from "../domain/models/dto/types/CountryTypeDTO";
import { CurrencyTypeDTO } from "../domain/models/dto/types/CurrencyTypeDTO";
import { TypesService } from "../domain/services/TypesService";
import countryTypeMapperFactory from "../mappers/CountryTypeMapper";
import currencyTypeMapperFactory from "../mappers/CurrencyTypeMapper";
import { get } from "../tools/Tools";
import { BaseServiceImpl } from "./BaseServiceImpl";
import { LanguageType } from "../domain/models/types/LanguageType";
import languageTypeMapperFactory from "../mappers/LanguageTypeMapper";
import { LanguageTypeDTO } from "../domain/models/dto/types/LanguageTypeDTO";
import { SpecialtyType } from "../domain/models/types/SpecialtyType";
import specialtyTypeMapperFactory from "../mappers/SpecialtyTypeMapper";
import { SpecialtyTypeDTO } from "../domain/models/dto/types/SpecialtyTypeDTO";
import { BaseTypeDTO } from "../domain/models/dto/types/BaseTypeDTO";

class TypesServiceImpl extends BaseServiceImpl implements TypesService {
    private static instance: TypesService;
    private specialties: SpecialtyType[] = null;
    private languages: LanguageType[] = null;

    private constructor() {
        super();
    }

    static getInstance() {
        if (!TypesServiceImpl.instance) {
            TypesServiceImpl.instance = new TypesServiceImpl();
        }
        return TypesServiceImpl.instance;
    }

    async getCountries(limit = GET_ALL_RECORDS): Promise<CountryType[]> {
        try {
            const mapper = countryTypeMapperFactory();
            return this.getData<CountryTypeDTO[]>(
                await get(
                    `${conciliumLink}/api/types/countries?page=${DEFAULT_PAGE}&limit=${limit}`
                )
            ).map((dto) => mapper.fromDTO(dto));
        } catch (err) {
            console.log("TypesServiceImpl.getCountries => ERROR:");
            console.log(err);
        }
    }

    async getCurrencies(limit = GET_ALL_RECORDS): Promise<CurrencyType[]> {
        try {
            const mapper = currencyTypeMapperFactory();
            return this.getData<CurrencyTypeDTO[]>(
                await get(
                    `${conciliumLink}/api/types/currencies?page=${DEFAULT_PAGE}&limit=${limit}`
                )
            ).map((dto) => mapper.fromDTO(dto));
        } catch (err) {
            console.log("TypesServiceImpl.getCurrencies => ERROR:");
            console.log(err);
        }
    }

    async getLanguages(limit = GET_ALL_RECORDS): Promise<LanguageType[]> {
        try {
            const mapper = languageTypeMapperFactory();
            return this.getData<LanguageTypeDTO[]>(
                await get(
                    `${conciliumLink}/api/types/languages?page=${DEFAULT_PAGE}&limit=${limit}`
                )
            ).map((dto) => mapper.fromDTO(dto));
        } catch (err) {
            console.log("TypesServiceImpl.getLanguages => ERROR:");
            console.log(err);
        }
    }

    async getSpecialties(limit = GET_ALL_RECORDS): Promise<SpecialtyType[]> {
        try {
            const mapper = specialtyTypeMapperFactory();
            return this.getData<SpecialtyTypeDTO[]>(
                await get(
                    `${conciliumLink}/api/types/specialties?page=${DEFAULT_PAGE}&limit=${limit}`
                )
            ).map((dto) => mapper.fromDTO(dto));
        } catch (err) {
            console.log("TypesServiceImpl.getSpecialties => ERROR:");
            console.log(err);
        }
    }

    async getCachedSpecialties(): Promise<SpecialtyType[]> {
        if (!this.specialties) {
            this.specialties = await this.getSpecialties();
        }
        return this.specialties;
    }

    async getCachedLanguages(): Promise<LanguageType[]> {
        if (!this.languages) {
            this.languages = await this.getLanguages();
        }
        return this.languages;
    }

    async getMockAdmin(title): Promise<BaseTypeDTO[]> {
        return [{ id: "1ec742f9-ef6b-4cfb-a95b-6868ff8c55a2", title }];
    }
}

export default function typesServiceFactory(): TypesService {
    return TypesServiceImpl.getInstance();
}
