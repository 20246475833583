import React, { FC } from "react";
import { withTranslation } from "react-i18next";
import LanguageSelect from "../../../shared/LanguageSelect";

interface MainMobileHeaderRightProps {}

const MainMobileHeaderRight: FC<MainMobileHeaderRightProps> = () => {
    return (
        <div className="mobile-menu__right">
            <LanguageSelect wrapperClassName="header__dropdown" />
        </div>
    );
};

export default withTranslation()(MainMobileHeaderRight);
