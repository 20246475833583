export enum TransactionType {
    BALANCE_REPLENISHMENT = 1,
    RESERVATION = 2,
    SERVICE_PAYMENT = 3,
    SERVICE_RECEIPT = 4,
    WITHDRAW = 5,
    CANCEL_RESERVATION = 6,
    PAYMENT_TO_SITE_OWNER = 7,
    PENALTY = 8,
    ARBITRATION = 9,
    COMPENSATION = 10,
    PROMOCODE_APPLIED = 11,
    PROMOCODE_REMOVED = 12,
    CANCEL_WITHDRAW_RESERVATION = 13,
    WITHDRAW_RESERVATION = 14,
}
