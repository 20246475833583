import React, { FC } from "react";
import { BaseTypeDTO } from "../../domain/models/dto/types/BaseTypeDTO";
import RouterLink from "../shared/RouterLink";

interface MainFooterTypeColumnsProps {
    typesList: BaseTypeDTO[][];
    getSearchItemPath: (itemId: string) => string;
}

const MainFooterTypeColumns: FC<MainFooterTypeColumnsProps> = (props) => {
    return (
        <div className="footer__body footer__body--desktop">
            {props.typesList.map((column, i) => (
                <div key={"footer_items_column-" + i} className="footer__column">
                    {column.map((item, j) => (
                        <RouterLink
                            useAnchorElement
                            key={"footer_item-" + j}
                            className="footer__link"
                            path={props.getSearchItemPath(item.id)}
                        >
                            {item.title}
                        </RouterLink>
                    ))}
                </div>
            ))}
        </div>
    );
};

export default MainFooterTypeColumns;
