import React, { FC, useEffect, useState } from "react";
import { LanguageType } from "../../../domain/models/types/LanguageType";
import typesServiceFactory from "../../../services/TypesService";
import { getValidationClass } from "../../../tools/FormTools";
import { FormikProps } from "formik";
import { SchemaFactoryData } from "../../../models/SchemaFactory";
import { Translate } from "../../../domain/models/Translate";
import { withTranslation } from "react-i18next";

interface LanguageTypeDropDownProps {
    form: FormikProps<any>;
    schema: SchemaFactoryData;
    fieldName?: string;
    t: Translate;
}

const LanguageTypeDropDown: FC<LanguageTypeDropDownProps> = (props) => {
    const [languages, setLanguages] = useState<LanguageType[]>([]);
    const fieldName = props.fieldName || "languageId";

    useEffect(() => {
        const service = typesServiceFactory();
        (async () => {
            setLanguages(await service.getLanguages());
        })();
    }, []);

    return (
        <select
            className={`textField ${getValidationClass(props.form, fieldName)}`}
            name={fieldName}
            onChange={props.form.handleChange}
            value={props.form.values[fieldName]}
            placeholder={props.schema.fields[fieldName].placeholder}
        >
            <option value="" disabled selected>
                {props.t("registration.selectPlaceholders.language")}
            </option>
            {languages.map((value) => {
                return (
                    <option key={value.id} value={value.id}>
                        {value.title}
                    </option>
                );
            })}
        </select>
    );
};

export default withTranslation()(LanguageTypeDropDown);
