import { Mapper } from "../domain/mappers/mapper";
import { CountryType } from "../domain/models/types/CountryType";
import { CountryTypeDTO } from "../domain/models/dto/types/CountryTypeDTO";
import { CountryTypeModel } from "../models/CountryTypeModel";
import { SimpleMapper } from "./SimpleMapper";

class CountryTypeMapper extends SimpleMapper<CountryType, CountryTypeDTO> {
    protected entityConstructor = CountryTypeModel;

    protected fromDTOFields: string[] = [
        "id",
        "title",
    ];
}

export default function countryTypeMapperFactory(): Mapper<CountryType, CountryTypeDTO> {
    return new CountryTypeMapper();
}
