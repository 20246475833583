import React, { Component } from "react";
import { CartService } from "../../domain/services/new-conference-process/CartService";
import { cartServiceFactory } from "../../services/CartServiceImpl";

// TODO :: pass appropriate properties
export default class SpecialistsList extends Component<{ t(key?: string): string }> {
    cartService: CartService;

    constructor(props) {
        super(props);
        this.cartService = cartServiceFactory();
    }

    render() {
        return (
            <>
                {this.cartService.cart.specialists.map((specialist) => (
                    <div className="mfirst__doc mfirst__doc_half" key={specialist.id}>
                        <img className="mfirst__doc--pic_ns" src={specialist.user.imageUrl} />
                        <div className="mfirst__doc--info">
                            <a href={"/details/specialist/" + specialist.id}>
                                <div className="mfirst__doc--name_ns">{specialist.user.fullName}</div>
                            </a>
                            {specialist.isMedicalSpecialist && (
                                <div className="mfirst__doc--specialization_ns">{specialist.specialty.title}</div>
                            )}
                            {specialist.isTranslator && (
                                <div className="mfirst__doc--specializationBlue_ns">
                                    {this.props.t("newConferenceProcess.requiredLang.lang")}
                                </div>
                            )}
                            {specialist.isAdministrator && (
                                <div className="mfirst__doc--specializationBlue_ns">
                                    {this.props.t("newConferenceProcess.requiredAdmin.admin")}
                                </div>
                            )}
                        </div>
                    </div>
                ))}
            </>
        );
    }
}
