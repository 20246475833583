import { Mapper } from "../domain/mappers/mapper";
import { SimpleMapper } from "./SimpleMapper";
import { Experience } from "../domain/models/Experience";
import { ExperienceDTO } from "../domain/models/dto/ExperienceDTO";
import { ExperienceModel } from "../models/ExperienceModel";
import moment from "moment";
import { DATE_FORMAT } from "../domain/constants/Constants";

class ExperienceMapper extends SimpleMapper<Experience, ExperienceDTO> {
    protected entityConstructor = ExperienceModel;

    protected fromDTOFields: string[] = [
        "id",
        "userId",
        "currentJob",
        "fromDate",
        "toDate",
        "position",
        "employment",
        "body",
        "fileHide",
        "fileName",
        "filePath",
        "validate",
        "added",
    ];

    fromDTO(dto: ExperienceDTO, params?: any): Experience {
        const result = super.fromDTO(dto, params);

        result.fromDateM = result.fromDate ? moment(result.fromDate, DATE_FORMAT) : null;
        result.toDateM = result.toDate ? moment(result.toDate, DATE_FORMAT) : null;

        return result;
    }
}

export default function experienceMapperFactory(): Mapper<
    Experience,
    ExperienceDTO
> {
    return new ExperienceMapper();
}
