import { BaseModelImpl } from "./BaseModelImpl";
import { SpecialtyTypeDTO } from "../domain/models/dto/types/SpecialtyTypeDTO";
import { SpecialtyType } from "../domain/models/types/SpecialtyType";

export class SpecialtyTypeModel
    extends BaseModelImpl<SpecialtyTypeDTO>
    implements SpecialtyType
{
    id: string;
    title: string;
}
