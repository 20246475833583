import React, { FC, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import typesServiceFactory from "../../services/TypesService";
import { FOOTER_TYPES_LIMIT, FOOTER_TYPES_COLUMN_LIMIT, MIN_DESKTOP_WIDTH } from "../../domain/constants/Constants";
import MainFooterTypeColumns from "./MainFooterTypeColumns";
import MainFooterTypesSelect from "./MainFooterTypesSelect";
import useWindowDimensions from "../../hooks/useWindowDimensions";
import { SpecialtyType } from "../../domain/models/types/SpecialtyType";
import { LanguageType } from "../../domain/models/types/LanguageType";
import { BaseTypeDTO } from "../../domain/models/dto/types/BaseTypeDTO";
import RouterLink from "../shared/RouterLink";
import _ from "lodash";

interface MainFooterTypesListProps {}

const SEARCH_SPEC_PAGE = "/search/spec";
const SEARCH_LANG_PAGE = "/search/lang";
const SEARCH_ADMIN_PAGE = "/search/admin";

const MainFooterTypesList: FC<MainFooterTypesListProps> = () => {
    const { t } = useTranslation();
    const { width } = useWindowDimensions();

    const isDesktop = width >= MIN_DESKTOP_WIDTH;
    const location = window.location.pathname;

    const [specialties, setSpecialties] = useState<SpecialtyType[]>([]);
    const [languages, setLanguages] = useState<LanguageType[]>([]);
    const [admins, setAdmins] = useState<BaseTypeDTO[]>([]);

    const visibleLanguages = location === SEARCH_LANG_PAGE;
    const visibleAdmins = location === SEARCH_ADMIN_PAGE;
    const visibleSpecialties = !visibleLanguages && !visibleAdmins;

    const getSearchItemPath = (itemId: string) => {
        return visibleSpecialties
            ? `${SEARCH_SPEC_PAGE}?specialty=${itemId}`
            : visibleLanguages
            ? `${SEARCH_LANG_PAGE}?language=${itemId}`
            : visibleAdmins
            ? SEARCH_ADMIN_PAGE
            : "";
    };

    const slicedTypes = useMemo(() => {
        return _.chunk(
            (visibleSpecialties ? specialties : visibleLanguages ? languages : visibleAdmins ? admins : []).slice(
                0,
                FOOTER_TYPES_LIMIT
            ),
            FOOTER_TYPES_COLUMN_LIMIT
        );
    }, [specialties, languages, admins]);

    useEffect(() => {
        const service = typesServiceFactory();
        (async () => {
            const [specs, langs, admins] = await Promise.all([
                service.getCachedSpecialties(),
                service.getCachedLanguages(),
                service.getMockAdmin(t("footer.right.admin")),
            ]);
            setSpecialties(specs);
            setLanguages(langs);
            setAdmins(admins);
        })();
    }, [width]);

    return (
        <div className="footer__right">
            <div className="footer__top footer__top_start">
                <div className={`footer__type ${visibleSpecialties ? "active" : ""}`}>
                    <RouterLink useAnchorElement path="/search/spec">
                        {t("footer.right.medSpec")}
                    </RouterLink>
                </div>
                <div className={`footer__type ${visibleLanguages ? "active" : ""}`}>
                    <RouterLink useAnchorElement path="/search/lang">
                        {t("footer.right.lang")}
                    </RouterLink>
                </div>
                <div className={`footer__type ${visibleAdmins ? "active" : ""}`}>
                    <RouterLink useAnchorElement path="/search/admin">
                        {t("footer.right.admin")}
                    </RouterLink>
                </div>
            </div>
            {isDesktop ? (
                <MainFooterTypeColumns typesList={slicedTypes} getSearchItemPath={getSearchItemPath} />
            ) : (
                <MainFooterTypesSelect specialties={specialties} languages={languages} admins={admins} />
            )}
        </div>
    );
};

export default MainFooterTypesList;
