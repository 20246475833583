import { getEnumTranslatedDropDownOptions } from "../../tools/EnumTools";
import { SelectProps } from "../models/SelectProps";
import { Translate } from "../models/Translate";

export enum SupportTicketCustomerRole {
    GUEST = 1,
    SPECIALIST = 2,
}

const translatePrefix = "enums.supportTicketCustomerRole.";

export function getSupportTicketCustomerRoleTranslation(
    t: Translate,
    requestCustomerRole: SupportTicketCustomerRole
): SelectProps {
    return {
        value: requestCustomerRole?.toString(),
        label: t(
            requestCustomerRole && `${translatePrefix}${requestCustomerRole}`
        ),
    };
}

export function getSupportTicketCustomerRoleDropdownOptions(
    t: Translate
): SelectProps[] {
    return getEnumTranslatedDropDownOptions(
        SupportTicketCustomerRole,
        translatePrefix,
        t
    );
}
