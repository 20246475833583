import React, { FC, useEffect, useState } from "react";
import { withTranslation } from "react-i18next";
import { User } from "../../../domain/models/User";
import MainHeaderNotifications from "./main-header-notifications/MainHeaderNotifications";
import NewConferenceProcessButton from "../../new-conference-process/NewConferenceProcessButton";
import { CartToolsService } from "../../../services/CartToolsService";
import LanguageSelect from "../../shared/LanguageSelect";
import RouterLink from "../../shared/RouterLink";

interface MainHeaderRightProps {
    t(key?: string, values?: object): string;
    user?: User;
    isOpenedMenu: boolean;
    mobileMenuHandler: () => void;
}

const MainHeaderRight: FC<MainHeaderRightProps> = (props) => {
    const [isSearching, setIsSearching] = useState(false);
    const [isOpenedDropdown, setIsOpenedDropdown] = useState(false);
    const modalRef: React.RefObject<typeof NewConferenceProcessButton> =
        React.createRef<typeof NewConferenceProcessButton>();

    const searchButtonHandler = () => {
        setIsSearching(!isSearching);
    };
    const openedMenuHandler = () => {
        setIsOpenedDropdown(!isOpenedDropdown);
    };

    useEffect(() => {
        CartToolsService.initAllCartModalHandlers(modalRef);
    }, []);

    return (
        <div className="header__right">
            <LanguageSelect wrapperClassName="header__dropdown" />
            {props.user.isAuthenticated ? (
                <>
                    <div onClick={openedMenuHandler} className="header__dropdown dropdown header__dropdown--menu">
                        {props.user.imageUrl ? <img className="dropdown__image" src={props.user.imageUrl} /> : <></>}
                        <div className="dropdown__text">
                            {props.user.name}
                            <div
                                className={`dropdown__option__main dropdown__option__main--menu ${
                                    isOpenedDropdown ? "active" : ""
                                }`}
                            >
                                <div className="dropdown__option__item">
                                    <RouterLink useAnchorElement className="dropdown__option__text" path="/consults">
                                        {props.t("mainHeader.userOptions.consults")}
                                    </RouterLink>
                                </div>
                                <div className="dropdown__option__item">
                                    <RouterLink useAnchorElement className="dropdown__option__text" path="/favorites">
                                        {props.t("mainHeader.userOptions.favorites")}
                                    </RouterLink>
                                </div>
                                <div className="dropdown__option__item">
                                    <RouterLink useAnchorElement className="dropdown__option__text" path="/settings">
                                        {props.t("mainHeader.userOptions.settings")}
                                    </RouterLink>
                                </div>
                                <div className="dropdown__option__item">
                                    <RouterLink useAnchorElement className="dropdown__option__text" path="/support">
                                        {props.t("mainHeader.userOptions.support")}
                                    </RouterLink>
                                </div>
                                <div className="dropdown__option__item">
                                    <RouterLink useAnchorElement className="dropdown__option__text" path="/logout">
                                        {props.t("mainHeader.userOptions.logout")}
                                    </RouterLink>
                                </div>
                            </div>
                        </div>
                    </div>
                    <NewConferenceProcessButton ref={modalRef} />
                    <MainHeaderNotifications />
                </>
            ) : (
                <>
                    <div className="header__login">
                        <RouterLink path="/login">{props.t("mainHeader.login")}</RouterLink>
                    </div>
                    <div className="header__reg">
                        <RouterLink path="/registration">{props.t("mainHeader.registration")}</RouterLink>
                    </div>
                </>
            )}
            <div
                onClick={searchButtonHandler}
                className={`header__action--search header__action ${isSearching ? "active" : ""}`}
            >
                <img className="header__action--image search__loupe-img" src="/static/images/search.svg" />
            </div>
            <div className="reg__first--input header__input">
                <form action="/page/search" method="get">
                    <input type="text" name="q" placeholder={props.t("mainHeader.search")} />
                </form>
            </div>
            <button id="burger-menu" onClick={props.mobileMenuHandler} className={props.isOpenedMenu ? "active" : ""}>
                <div className={`box_items ${props.isOpenedMenu ? "active" : ""}`}>
                    <div className="box box_item1"></div>
                    <div className="box box_item2"></div>
                    <div className="box box_item3"></div>
                </div>
            </button>
        </div>
    );
};

export default withTranslation()(MainHeaderRight);
