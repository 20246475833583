import { FC, useEffect, useState } from "react";
import { withTranslation } from "react-i18next";
import MainHeaderLeft from "./MainHeaderLeft";
import React from "react";
import MainHeaderRight from "./MainHeaderRight";
import { userServiceFactory } from "../../../services/UserServiceImpl";
import { User } from "../../../domain/models/User";
import LoadingPanel from "../../shared/LoadingPanel";

interface MainHeaderProps {
    t(key?: string, values?: object): string;
}

const MainHeader: FC<MainHeaderProps> = () => {
    const [user, setUser] = useState<User>(null);
    const [isLoaded, setIsLoaded] = useState(false);
    const [isOpenedMenu, setIsOpenedMenu] = useState(false);
    const mobileMenuHandler = () => {
        setIsOpenedMenu(!isOpenedMenu);
    };

    useEffect(() => {
        (async function () {
            const userService = userServiceFactory();
            const currentUser = await userService.getCachedUser();
            if (currentUser) {
                setUser(currentUser);
            }
            setIsLoaded(true);
        })();
    }, []);

    return (
        <>
            {isLoaded ? (
                <header className="header">
                    <MainHeaderLeft user={user} isOpenedMenu={isOpenedMenu} />
                    <MainHeaderRight user={user} mobileMenuHandler={mobileMenuHandler} isOpenedMenu={isOpenedMenu} />
                </header>
            ) : (
                <LoadingPanel isVisible />
            )}
        </>
    );
};

export default withTranslation()(MainHeader);
