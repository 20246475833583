import React, { FC, useEffect, useState } from "react";
import { withTranslation } from "react-i18next";
import { NotificationModel } from "../../../../domain/models/NotificationModel";
import notificationServiceFactory from "../../../../services/NotificationServiceImpl";
import { NotificationFields } from "../../../../domain/enums/NotificationFields";
import { SortingOrder } from "../../../../domain/enums/SortingOrder";
import NotificationsList from "./NotificationsList";
import { DEFAULT_PAGE } from "../../../../domain/constants/Constants";
import RouterLink from "../../../shared/RouterLink";

interface NotificationsProps {
    t(key?: string, values?: object): string;
}

const notificationsLimit = 3;

const MainHeaderNotifications: FC<NotificationsProps> = (props) => {
    const [notificationsVisibility, setNotificationsVisibility] = useState(false);
    const [isPersonal, setIsPersonal] = useState(true);
    const [isUnRead, setIsUnRead] = useState(false);
    const [notifications, setNotifications] = useState<NotificationModel[]>([]);
    useEffect(() => {
        const notificationService = notificationServiceFactory();
        (async () => {
            const data = await notificationService.getNotifications(
                DEFAULT_PAGE,
                notificationsLimit,
                isPersonal,
                NotificationFields.CREATED,
                SortingOrder.DESC
            );
            setNotifications(data);
            if (data.length) {
                setIsUnRead(data.some((item) => !item.isRead));
            }
        })();
    }, [isPersonal]);

    const readNotificationsCallback = () => {
        setIsUnRead(false);
    };

    return (
        <div
            onMouseEnter={() => setNotificationsVisibility(true)}
            onMouseLeave={() => setNotificationsVisibility(false)}
            className={"header__dropdown" + (isUnRead ? " active" : "")}
        >
            <img className="header__action--image notif__bell" src="/static/images/bell.png" />
            {notificationsVisibility ? (
                <div className="header__action__notif">
                    <div className="header__action__nav">
                        <div
                            className={"notif__tabs__nav" + (isPersonal ? " showTab" : "")}
                            onClick={() => setIsPersonal(true)}
                        >
                            {props.t("mainHeader.notifications.personal")}
                        </div>
                        <div
                            className={"notif__tabs__nav" + (isPersonal ? "" : " showTab")}
                            onClick={() => setIsPersonal(false)}
                        >
                            {props.t("mainHeader.notifications.public")}
                        </div>
                    </div>
                    <NotificationsList
                        notifications={notifications}
                        readNotificationsCallback={readNotificationsCallback}
                    />
                    <div className="bottom__show__allNotification">
                        <RouterLink useAnchorElement path="/notifications">
                            {props.t("mainHeader.notifications.showAll")}
                        </RouterLink>
                    </div>
                </div>
            ) : (
                <></>
            )}
        </div>
    );
};

export default withTranslation()(MainHeaderNotifications);
