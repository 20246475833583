import { Mapper } from "../domain/mappers/mapper";
import { SimpleMapper } from "./SimpleMapper";
import { Education } from "../domain/models/Education";
import { EducationDTO } from "../domain/models/dto/EducationDTO";
import { EducationModel } from "../models/EducationModel";

class EducationMapper extends SimpleMapper<Education, EducationDTO> {
    protected entityConstructor = EducationModel;

    protected fromDTOFields: string[] = [
        "id",
        "userId",
        "type",
        "specialtyName",
        "institutionName",
        "graduationYear",
        "fileHide",
        "fileName",
        "filePath",
        "validate",
        "added",
        "educationType",
    ];
}

export default function educationMapperFactory(): Mapper<
    Education,
    EducationDTO
> {
    return new EducationMapper();
}
