import React from "react";
import { NewConferenceState } from "./NewConferenceState";

export class NewConferenceRequiredSpecialist extends NewConferenceState {
    render() {
        return (
            <div className="content content_margin-top">
                <div className="modal active" id="searchCM">
                    <div className="modal__body_ns advice_ns">
                        <div className="advice__top_ns">
                            <img src="/static/images/infotrans.png" alt="" />
                            <div className="advice__top--headline_ns">{this.props.t("general.information")}</div>
                        </div>
                        <div className="advice__body_ns">
                            <div className="advice__body--text_ns">
                                {this.props.t("newConferenceProcess.requiredSpec.specText")}
                            </div>
                            <div className="advice__buttons_ns">
                                <a href="/search/spec">
                                    <div className="advice__order_ns">
                                        {this.props.t("newConferenceProcess.createConsultation")}
                                    </div>
                                </a>
                                <a href="/search/spec/concilium">
                                    <div className="advice__find_ns">
                                        {this.props.t("newConferenceProcess.createConcilium")}
                                    </div>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
