import { SpecialistType } from "../domain/enums/SpecialistType";
import { UserSpecialistStatus } from "../domain/enums/UserSpecialistStatus";
import { SpecialtyDTO } from "../domain/models/dto/SpecialtyDTO";
import { User } from "../domain/models/User";
import { UserSpecialist } from "../domain/models/UserSpecialist";
import { AwardModel } from "./AwardModel";
import { BaseModelImpl } from "./BaseModelImpl";
import { EducationModel } from "./EducationModel";
import { ExperienceModel } from "./ExperienceModel";
import { SkillModel } from "./SkillModel";
import * as _ from "lodash";

export class UserSpecialistModel
    extends BaseModelImpl<UserSpecialist>
    implements UserSpecialist
{
    id?: string;
    status?: UserSpecialistStatus;
    specialistType?: SpecialistType;

    aboutSelf?: string;
    priceExtra?: number;
    priceUsual?: number;
    quantityConciliums?: number;
    quantityFeedbacks?: number;
    quantityRates?: number;
    rating?: number;
    videoUrl?: string;

    specialty?: SpecialtyDTO;
    user?: User;

    newVideoUrl?: string;
    newAboutSelf?: string;
    newPriceUsual?: number;
    newPriceExtra?: number;

    awards?: AwardModel[];
    educations?: EducationModel[];
    experiences?: ExperienceModel[];
    skills?: SkillModel[];

    constructor(data: UserSpecialist) {
        super(data);
    }

    get isCreated(): boolean {
        return this.status === UserSpecialistStatus.CREATED;
    }

    get isApproved(): boolean {
        return this.status === UserSpecialistStatus.APPROVED;
    }

    get isNew(): boolean {
        return !!this.id;
    }

    get isMedicalSpecialist(): boolean {
        return this.specialistType == SpecialistType.MEDICAL_SPECIALIST;
    }

    get isAdministrator(): boolean {
        return this.specialistType == SpecialistType.ADMINISTRATOR;
    }

    get isTranslator(): boolean {
        return this.specialistType == SpecialistType.TRANSLATOR;
    }

    get hasPhoto(): boolean {
        return _.get(this.user, "hasPhoto", false);
    }

    get hasSpecialty(): boolean {
        return !!this.specialty;
    }

    get hasLanguages(): boolean {
        return !!_.get(this.user, "hasLanguages", false);
    }

    get hasAboutSelf(): boolean {
        return !!this.aboutSelf || !!this.newAboutSelf;
    }

    get hasEducations(): boolean {
        return !!_.get(this, "educations.length");
    }

    get allEducationsComplete(): boolean {
        return (
            this.hasEducations &&
            !this.educations.find(
                (education) => !education.isInformationComplete
            )
        );
    }

    get hasExperiences(): boolean {
        return !!_.get(this, "experiences.length");
    }

    get allExperiencesComplete(): boolean {
        return (
            this.hasExperiences &&
            !this.experiences.find(
                (experience) => !experience.isInformationComplete
            )
        );
    }

    get hasPriceUsual(): boolean {
        return _.isNumber(this.priceUsual) && this.priceUsual > 0;
    }

    get hasPriceExtra(): boolean {
        return _.isNumber(this.priceExtra) && this.priceExtra > 0;
    }

    get hasPrices(): boolean {
        return this.hasPriceUsual && this.hasPriceExtra;
    }

    get hasAwards(): boolean {
        return !!_.get(this, "awards.length");
    }

    get allAwardsComplete(): boolean {
        return (
            this.hasAwards &&
            !this.awards.find((award) => !award.isInformationComplete)
        );
    }

    get hasVideo(): boolean {
        return !!this.videoUrl || !!this.newVideoUrl;
    }

    get hasSkills(): boolean {
        return !!_.get(this, "skills.length");
    }

    get allSkillsComplete(): boolean {
        return (
            this.hasAwards &&
            !this.skills.find((skill) => !skill.isInformationComplete)
        );
    }

    get isBasicInformationComplete(): boolean {
        return (
            this.hasPhoto &&
            this.hasLanguages &&
            this.hasAboutSelf &&
            this.hasEducations &&
            this.allEducationsComplete &&
            this.hasExperiences &&
            this.allExperiencesComplete &&
            this.hasPrices
        );
    }

    get isInformationComplete(): boolean {
        return this.isMedicalSpecialist
            ? this.isBasicInformationComplete && this.hasSpecialty
            : this.isTranslator || this.isAdministrator
            ? this.isBasicInformationComplete
            : false;
    }

    get isOptionalInformationComplete(): boolean {
        return (
            this.hasAwards &&
            this.allAwardsComplete &&
            this.hasVideo &&
            this.hasSkills &&
            this.allSkillsComplete
        );
    }
}
