import React, { FC } from "react";
import { Route, Routes } from "react-router-dom";
import LoginPage from "../components/login/LoginPage";
import RegistrationPage from "../components/registration/RegistrationPage";
import MainPage from "../components/main-page/MainPage";
import NotFound from "./NotFound";

interface UnAuthRoutesProps {}

const UnAuthRoutes: FC<UnAuthRoutesProps> = () => {
    return (
        <Routes>
            <Route path="/" element={<MainPage />} />
            <Route path="/login" element={<LoginPage />} />
            <Route path="/registration" element={<RegistrationPage />} />
            <Route path="/*" element={<NotFound />} />
        </Routes>
    );
};

export default UnAuthRoutes;
