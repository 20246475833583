import { RegistrationDTO } from "../models/dto/RegistrationDTO";

export interface RegistrationForm extends RegistrationDTO {
    confirmPassword: string;
}

export const registrationInitialValues: RegistrationForm = {
    name: "",
    surname: "",
    patronimyc: "",
    email: "",
    phone: "",
    languageId: "",
    birthday: "",
    countryId: "",
    city: "",
    timezone: "",
    currencyId: "",
    password: "",
    confirmPassword: ""
}
