import moment from "moment";
import {
    DATETIME_FORMAT,
    EXPRESS_HOURS_DELTA,
    MIN_TIME_TO_CONCILIUM,
} from "../domain/constants/Constants";
import { Cart } from "../domain/models/Cart";
import { CartDTO } from "../domain/models/dto/CartDTO";
import { CreateConciliumPriceDTO } from "../domain/models/dto/CreateConsiliumPriceDTO";
import { BaseServiceImpl } from "../services/BaseServiceImpl";
import { UserSpecialist } from "../domain/models/UserSpecialist";
import { User } from "../domain/models/User";
import { Schedule } from "../domain/models/Schedule";
import { scheduleFactory } from "./ScheduleModel";
import * as _ from "lodash";
import { CustomFile } from "../domain/models/CustomFile";

export class CartModel extends BaseServiceImpl implements Cart {
    id: string;
    status: number;

    /**
     * meeting time in UTC format
     */
    meetingTime: string;
    duration: number;

    recordingAudio: boolean;
    recordingVideo: boolean;
    description: string;

    // TODO :: remove this
    // filePath: string;
    // fileName: string;

    skipExtra: boolean;
    skipLang: boolean;
    skipAdmin: boolean;

    expirationTime: string;
    isExpired: boolean;

    user: User;
    specialists: UserSpecialist[] = [];
    files: CustomFile[] = [];

    isConcilium?: boolean;

    schedule: Schedule;

    /**
     * local meeting time
     */
    meetingTimeM: moment.Moment;
    /**
     * local meeting time end
     */
    meetingTimeEndM: moment.Moment;
    timePoints: moment.Moment[];
    isApplicableTimeRange: boolean = false;

    price?: CreateConciliumPriceDTO;

    skipServerUtcToLocalConversion: boolean = false;

    private _requiredSpec: boolean = true;
    private _requiredLang: boolean = true;
    private _requiredAdmin: boolean = true;
    private _finalPrice: number;

    constructor(data: CartDTO, skipServerUtcToLocalConversion: boolean) {
        super();

        this.schedule = scheduleFactory();

        this.isConcilium = false;
        this.skipServerUtcToLocalConversion = skipServerUtcToLocalConversion;

        this.price = {
            quantityMedics: 0,
            quantityTranslators: 0,
            quantityAdmins: 0,
            costMedics: 0,
            costTranslators: 0,
            costAdmins: 0,
        };
        Object.assign(this, data || {});
        this.initMeetingTime();
    }

    get medicsExist() {
        return this.price.quantityMedics > 0;
    }

    get translatorsExist() {
        return this.price.quantityTranslators > 0;
    }

    get adminsExist() {
        return this.price.quantityAdmins > 0;
    }

    get notEnoughFinance() {
        return !!this.user && this.user.balance < this.finalPrice;
    }

    get isSmallTime() {
        const currentUserTime = this.user.getUserCurrentTime();
        const thirtyMinutesLater = currentUserTime.add(
            MIN_TIME_TO_CONCILIUM,
            "minutes"
        );
        return this.meetingTimeM < thirtyMinutesLater;
    }

    get isExpress() {
        const currentUserTime = this.user.getUserCurrentTime();
        const threeHoursLater = currentUserTime.add(
            EXPRESS_HOURS_DELTA,
            "hours"
        );
        return this.meetingTimeM < threeHoursLater;
    }

    get finalPrice() {
        this._finalPrice =
            this.price.costMedics +
            this.price.costTranslators +
            this.price.costAdmins;
        return this._finalPrice;
    }

    get isMeetingTimeSet() {
        return !!this.meetingTime;
    }

    get requiredSpec() {
        return this._requiredSpec;
    }

    get requiredLang() {
        return this._requiredLang;
    }

    get requiredAdmin() {
        return this._requiredAdmin;
    }

    get hasSchedule(): boolean {
        return this.schedule.hasSchedule;
    }

    get hasCorrectMeetingTime(): boolean {
        return this.isMeetingTimeSet && this.isApplicableTimeRange;
    }

    setDuration(value: number) {
        this.duration = value;
        this.initMeetingTime();
    }

    setMeetingTime(localTime: moment.Moment) {
        this.meetingTime = localTime
            ? this.skipServerUtcToLocalConversion
                ? localTime.clone().utc().format(DATETIME_FORMAT)
                : localTime.clone().format(DATETIME_FORMAT)
            : "";
        this.initMeetingTime();
    }

    setDescription(value: string) {
        this.description = value;
    }

    /**
     * TODO :: clear meetingTime if combined schedule does not contain appropriate time.
     * @param {moment.Moment[]} combinedSchedule - list of available time points in local time
     */
    setCombinedSchedule(combinedSchedule: moment.Moment[]) {
        this.schedule.setCombinedSchedule(combinedSchedule);
        this.initCartScheduleProperties();
    }

    getMedicalSpecialists(): UserSpecialist[] {
        return this.specialists.filter((spec) => spec.isMedicalSpecialist);
    }

    getTranslators(): UserSpecialist[] {
        return this.specialists.filter((spec) => spec.isTranslator);
    }

    getAdministrators(): UserSpecialist[] {
        return this.specialists.filter((spec) => spec.isAdministrator);
    }

    defineIfSpecRequired() {
        const specCount = this.getMedicalSpecialists().length;
        this._requiredSpec = !specCount;
        this.isConcilium = specCount > 1;
    }

    defineIfLangRequired() {
        if (!this.user) {
            return;
        }
        if (this.getTranslators().length) {
            this._requiredLang = false;
        } else {
            const specialistsWithKnownLanguages = this.specialists.filter(
                (specialist) => {
                    return !_.isEmpty(
                        _.intersection(
                            _.get(this, "user.languages", []),
                            _.get(specialist, "user.languages", [])
                        )
                    );
                }
            );

            this._requiredLang =
                specialistsWithKnownLanguages.length !==
                this.specialists.length;
        }
        return this._requiredLang;
    }

    defineIfAdminRequired() {
        const specialistsCount = this.getMedicalSpecialists().length;
        const administratorsCount = this.getAdministrators().length;
        this._requiredAdmin = specialistsCount > 1 && !administratorsCount;
    }

    removeSpecialistById(specId: string) {
        this.specialists = this.specialists.filter(
            (spec) => spec.id !== specId
        );
        this.updateState();
    }

    removeFileById(fileId: string) {
        this.files = this.files.filter(
            (file) => file.id !== fileId
        );
        this.updateState();
    }

    setStatus(value: number) {
        if (value) {
            this.status = value;
        }
    }

    setPrice(value: CreateConciliumPriceDTO) {
        if (value) {
            this.price = value;
        }
    }

    setUser(user) {
        this.user = user;
        this.updateState();
    }

    setSpecialists(specialists) {
        this.specialists = specialists;
        this.updateState();
    }

    setFiles(files) {
        this.files = files;
        this.updateState();
    }

    updateState(): void {
        this.defineIfSpecRequired();
        this.defineIfLangRequired();
        this.defineIfAdminRequired();
    }

    addSpecialist(specialist: UserSpecialist) {
        if (!specialist) {
            return;
        }
        this.specialists.push(specialist);
        this.updateState();
    }

    addFile(file: CustomFile) {
        if (!file) {
            return;
        }
        this.files.push(file);
        this.updateState();
    }

    initMeetingTime() {
        if (!this.meetingTime) {
            this.meetingTimeM = null;
            this.meetingTimeEndM = null;
            this.timePoints = [];
            this.isApplicableTimeRange = false;
            return;
        }
        this.meetingTimeM = this.skipServerUtcToLocalConversion
            ? moment.utc(this.meetingTime).local()
            : moment(this.meetingTime);
        this.meetingTimeEndM = this.meetingTimeM
            .clone()
            .add(this.duration, "hours");
        this.initCartScheduleProperties();
    }

    update(cart: Cart) {
        if (!cart) {
            return;
        }
        if (cart.priceDetails) {
            this.setPrice(cart.priceDetails);
        }
        // TODO :: remove this
        // if (cart.fileName) {
        //     this.fileName = cart.fileName;
        // }
        // if (cart.filePath) {
        //     this.filePath = cart.filePath;
        // }
    }

    isIncludesTimePoint(time: moment.Moment): boolean {
        if (!time || !this.isMeetingTimeSet) {
            return false;
        }

        return !!this.timePoints.find((timePoint) => timePoint.isSame(time));
    }

    isStartTimePoint(time: moment.Moment): boolean {
        if (!time || !this.isMeetingTimeSet) {
            return false;
        }

        return this.meetingTimeM.isSame(time);
    }

    initCartScheduleProperties() {
        this.timePoints = this.schedule.getTimePoints(
            this.meetingTimeM,
            this.meetingTimeEndM
        );
        this.isApplicableTimeRange = this.schedule.isApplicableTimeRange(
            this.timePoints
        );
    }

    setSkipExpress() {
        this.skipExtra = true;
    }

    setSkipLang(value: boolean = true) {
        this.skipLang = value;
    }

    setSkipAdmin(value: boolean = true) {
        this.skipAdmin = value;
    }
}
