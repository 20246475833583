import { getEnumTranslatedDropDownOptions } from "../../tools/EnumTools";
import { SelectProps } from "../models/SelectProps";
import { Translate } from "../models/Translate";

const translatePrefix = "enums.transactionStatus.";

export enum TransactionStatus {
    IN_PROGRESS = 1,
    WAITING = 2,
    COMPLETED = 3,
    CANCELED = 4,
    WAITING_FOR_TRANSFER = 5,
}

export function getTransactionStatusDropdownOptions(
    t: Translate,
    excludeValues: any[] = []
): SelectProps[] {
    return getEnumTranslatedDropDownOptions(
        TransactionStatus,
        translatePrefix,
        t,
        excludeValues
    );
}
