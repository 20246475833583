import { useEffect } from "react";

export const useFullWebsiteVersion = (btnClassName: string) => {
    useEffect(() => {
        const btn = document.querySelector(`.${btnClassName}`);
        btn.addEventListener("click", function () {
            var viewport = document.querySelector("meta[name=viewport]");
            viewport.setAttribute("content", "width=1600");
        });
    }, []);
};
