import _ from "lodash";
import React from "react";
import { CartStatus } from "../../domain/enums/CartStatus";
import { NewConferenceRequiredAdmin } from "./NewConferenceRequiredAdmin";
import { NewConferenceRequiredLang } from "./NewConferenceRequiredLang";
import { NewConferenceRequiredSpecialist } from "./NewConferenceRequiredSpecialist";
import { NewConferenceSpelialistsSelectDateTime } from "./NewConferenceSpelialistsSelectDateTime";
import { NewConferenceState } from "./NewConferenceState";

export default class NewConferenceSpelialistsList extends NewConferenceState {
    constructor(props) {
        super(props);
    }

    goToNextStep = () => {
        this.props.context.transitionTo(
            NewConferenceSpelialistsList.getNextStepComponentConstructor(this.cartService.cart)
        );
    };

    cancel(): void {
        this.props.context.handlerCancel();
    }

    handleSpecialistRemoved = async (specId) => {
        const result = await this.cartService.deleteSpec(specId);

        if (result !== specId) {
            return;
        }

        await this.cartService.clearAndUpdateCartSpecialistsFlags();
        await this.cartService.uploadCartSchedule();

        this.cartService.cart.removeSpecialistById(specId);
        this.forceUpdate();
        // TODO :: if user on specialist search page then need to reload page to update search params
        // window.location.reload();
    };

    componentDidMount = async () => {
        await this.cartService.updateCurrentCartStatus(CartStatus.ADDING_SPECIALISTS);
    };

    static getNextStepComponentConstructor = (cart): any => {
        if (cart.requiredSpec) {
            return NewConferenceRequiredSpecialist;
        }

        if (cart.requiredLang && !cart.skipLang) {
            return NewConferenceRequiredLang;
        }

        if (cart.requiredAdmin && !cart.skipAdmin) {
            return NewConferenceRequiredAdmin;
        }

        return NewConferenceSpelialistsSelectDateTime;
    };

    render() {
        return (
            <div className="modal active">
                <div className="modal__body_ns consilium">
                    <div className="comquest__top_ns">
                        <div className="comquest__top--left_ns">
                            {this.props.t("newConferenceProcess.specList.header")}
                        </div>
                        <div
                            className="comquest__top--right modal__close_ns removeQueryParams"
                            onClick={() => this.cancel()}
                        >
                            <img src="/static/images/close.png" alt="" />
                        </div>
                    </div>
                    {this.cartService.cart.specialists.map((specialist) => (
                        <div className="consilium__list_ns" id={specialist.id} key={specialist.id}>
                            <div className="consilium__list--left">
                                <img className="consilium__photo_ns" src={_.get(specialist.user, "imageUrl")} alt="" />
                                <div className="consilium__photo--text">
                                    <a href={"/details/specialist/" + specialist.id}>
                                        <div className="consilium__photo--name_ns">
                                            {_.get(specialist.user, "fullName")}
                                        </div>
                                    </a>
                                    {specialist.isMedicalSpecialist && (
                                        <div className="consilium__photo--desc_ns">{specialist.specialty.title}</div>
                                    )}
                                    {specialist.isTranslator && (
                                        <div className="consilium__photo--desc_ns">
                                            {this.props.t("newConferenceProcess.requiredLang.lang")}
                                        </div>
                                    )}
                                    {specialist.isAdministrator && (
                                        <div className="consilium__photo--desc_ns">
                                            {this.props.t("newConferenceProcess.requiredAdmin.admin")}
                                        </div>
                                    )}
                                </div>
                            </div>
                            <div
                                className="consilium__list--right_ns"
                                onClick={() => {
                                    this.handleSpecialistRemoved(specialist.id);
                                }}
                            >
                                <img src="/static/images/remove.png" alt="" />
                                <div className="consilium__list--text">
                                    {this.props.t("newConferenceProcess.specList.remove")}
                                </div>
                            </div>
                        </div>
                    ))}
                    <a href="/search/spec/concilium">
                        <div className="consilium__list--add_ns">
                            <img src="/static/images/listadd.png" alt="" />
                            <div className="consilium__list--addtext_ns">
                                {this.props.t("newConferenceProcess.specList.add")}
                                <br /> {this.props.t("newConferenceProcess.specList.spec")}
                            </div>
                        </div>
                    </a>
                    <div className="consilium__bottom_ns">
                        <div className="consilium__bottom--text_ns">
                            <a href="/search/spec/concilium">{this.props.t("newConferenceProcess.specList.search")}</a>
                        </div>
                        <div
                            className="consilium__bottom--button_ns"
                            onClick={async () => {
                                await this.goToNextStep();
                            }}
                        >
                            <a>{this.props.t("newConferenceProcess.specList.next")}</a>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
