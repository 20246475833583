import { BehaviorSubject } from "rxjs";
import { Errors } from "../domain/enums/Errors";
import { ErrorServiceFactory } from "./ErrorService";
import { cartServiceFactory } from "./CartServiceImpl";
import { CartStatus } from "../domain/enums/CartStatus";
import { userServiceFactory } from "./UserServiceImpl";
import { RefObject } from "react";

/**
 * This service is used to link existing controls,
 * generated by back-end, with react application.
 */
export class CartToolsService {
    static addToCartErrorSubject = new BehaviorSubject<Errors>(null);
    static isAddingSpecialist: boolean = false;

    static initAddSpecialistButtons(modalRef: any) {
        const buttons = CartToolsService.getAddSpecialistsButtons();
        if (!buttons.length) {
            // console.log(
            //     "initAddSpecialistButtons => Add Specialist buttons were not found"
            // );
            return;
        }
        for (let i = 0; i < buttons.length; i++) {
            const button = buttons.item(i);
            button.addEventListener("click", async (event) => {
                event.stopPropagation();

                const userService = userServiceFactory();
                const user = await userService.getCachedUser();

                if (user.isAnonymous) {
                    window.location.replace("/login");
                    return;
                }

                CartToolsService.setDisabled(buttons, true);
                await CartToolsService.addSpecialistToCartById(
                    button.getAttribute("specialist_id"),
                    modalRef
                );
                setTimeout(() => {
                    CartToolsService.setDisabled(buttons, false);
                }, 1000);
            });
        }
    }

    // TODO :: pass appropriate properties
    static async addSpecialistToCartById(specialistId: string, modalRef: any) {
        if (!specialistId || CartToolsService.isAddingSpecialist) {
            return;
        }
        CartToolsService.isAddingSpecialist = true;
        const cartService = cartServiceFactory();
        const cart = await cartService.getCachedCart();
        try {
            cart.addSpecialist(await cartService.addSpec(specialistId));
            await cartService.clearAndUpdateCartSpecialistsFlags();
            if (modalRef.current) {
                modalRef.current.setOpenContext(true);
            }
        } catch (err) {
            CartToolsService.addToCartErrorSubject.next(
                ErrorServiceFactory().getErrorCode(err)
            );
        }
        CartToolsService.isAddingSpecialist = false;
    }

    static async initOpenCartModalHandler(modalRef?: any) {
        const buttons = document.getElementsByClassName("openModalCard");
        if (!buttons.length) {
            // console.log(
            //     "initOpenCartModalHandler => Open Cart buttons were not found"
            // );
            return;
        }
        for (let i = 0; i < buttons.length; i++) {
            const button = buttons.item(i);
            button.addEventListener("click", (event) => {
                event.stopPropagation();
                if (modalRef.current) {
                    modalRef.current.setOpenContext(true);
                }
            });
        }
    }

    static async initOpenCartModalNexStepHandler(modalRef?: any) {
        const buttons = document.getElementsByClassName(
            "openCartModalNextStep"
        );
        if (!buttons.length) {
            // console.log(
            //     "initOpenCartModalHandler => Open Cart Next Step buttons were not found"
            // );
            return;
        }
        for (let i = 0; i < buttons.length; i++) {
            const button = buttons.item(i);
            button.addEventListener("click", (event) => {
                event.stopPropagation();
                if (!modalRef.current) {
                    return;
                }
                try {
                    modalRef.current.setInitialStatus(
                        CartStatus.SPECIALISTS_ADDED
                    );
                    modalRef.current.setOpenContext(true);
                } catch (err) {
                    console.log(
                        "CartToolsService.initOpenCartModalNexStepHandler => ERROR:"
                    );
                    console.log(err);
                }
            });
        }
    }

    static getAddSpecialistsButtons(): NodeListOf<Element> {
        return document.querySelectorAll(".addSpec");
    }

    static setDisabled(elements: NodeListOf<Element>, disabled: boolean) {
        if (!elements.length) {
            return;
        }
        for (let i = 0; i < elements.length; i++) {
            disabled
                ? elements[i].setAttribute("disabled", "")
                : elements[i].removeAttribute("disabled");
        }
    }

    static initAllCartModalHandlers(
        modalRef: RefObject<any>,
        onContentLoaded = false
    ) {
        const init = () => {
            CartToolsService.initAddSpecialistButtons(modalRef);
            CartToolsService.initOpenCartModalHandler(modalRef);
            CartToolsService.initOpenCartModalNexStepHandler(modalRef);
        };

        if (onContentLoaded) {
            document.addEventListener("DOMContentLoaded", (_event) => init());
        } else {
            init();
        }
    }
}
