import React, { FC, useState } from "react";
import { useTranslation } from "react-i18next";
import { CustomDropDown } from "../../../domain/components/CustomDropDown";
import RouterLink from "../RouterLink";

interface LinkDropDownProps {
    titleKey: string;
    current?: CustomDropDown;
    data: CustomDropDown[];
    getLinkToRedirect: (item: CustomDropDown) => string;
    useAnchorElement?: boolean;
}

const LinkDropDown: FC<LinkDropDownProps> = (props) => {
    const { t } = useTranslation();
    const [isVisibility, setIsVisibility] = useState(false);

    const dropDownHandler = () => {
        setIsVisibility(!isVisibility);
    };

    return (
        <div className="footer__column selectlink" onClick={dropDownHandler}>
            <div className="selectlink-control">{props.current ? props.current.label : t(props.titleKey)}</div>
            <ul style={{ display: isVisibility ? "block" : "none" }}>
                {props.data.map((item) => (
                    <li>
                        <RouterLink
                            useAnchorElement={props.useAnchorElement}
                            className="footer__link"
                            path={props.getLinkToRedirect(item)}
                        >
                            {item.label}
                        </RouterLink>
                    </li>
                ))}
            </ul>
        </div>
    );
};

export default LinkDropDown;
