import React, { FC, useEffect, useState } from "react";
import { CountryType } from "../../../domain/models/types/CountryType";
import typesServiceFactory from "../../../services/TypesService";
import { getValidationClass } from "../../../tools/FormTools";
import { FormikProps } from "formik";
import { SchemaFactoryData } from "../../../models/SchemaFactory";
import { Translate } from "../../../domain/models/Translate";
import { withTranslation } from "react-i18next";

interface CountryTypeDropDownProps {
    form: FormikProps<any>;
    schema: SchemaFactoryData;
    fieldName?: string;
    t: Translate;
}

const CountryTypeDropDown: FC<CountryTypeDropDownProps> = (props) => {
    const [countries, setCountries] = useState<CountryType[]>([]);
    const fieldName = props.fieldName || "countryId";

    useEffect(() => {
        const service = typesServiceFactory();
        (async () => {
            setCountries(await service.getCountries());
        })();
    }, []);

    return (
        <select
            className={`textField ${getValidationClass(props.form, fieldName)}`}
            name={fieldName}
            onChange={props.form.handleChange}
            value={props.form.values[fieldName]}
            placeholder={props.schema.fields[fieldName].placeholder}
        >
            <option value="" disabled selected>
                {props.t("registration.selectPlaceholders.country")}
            </option>
            {countries.map((value) => {
                return (
                    <option key={value.id} value={value.id}>
                        {value.title}
                    </option>
                );
            })}
        </select>
    );
};

export default withTranslation()(CountryTypeDropDown);
