import React, { Component } from "react";
import { SpecialistType } from "../../domain/enums/SpecialistType";
import ModalWrapper from "../modal/ModalWrapper";

interface SpecialtySelectionModalProps {
    userId: string;
    handler: (specType?) => Promise<void>;
    close: () => void;
    t(key?: string): string;
}

interface SpecialtySelectionModalState {
    specialtySelectionError: string;
}

export default class SpecialtySelectionModal extends Component<
    SpecialtySelectionModalProps,
    SpecialtySelectionModalState
> {
    constructor(props: SpecialtySelectionModalProps) {
        super(props);
        this.state = { specialtySelectionError: "" };
    }

    selectSpecialty = (specialtyType: SpecialistType) => {
        this.setState(
            {
                specialtySelectionError: "",
            },
            async () => {
                try {
                    await this.props.handler(specialtyType);
                    this.props.close();
                } catch (err) {
                    this.setState({
                        specialtySelectionError: this.props.t("errors.standardError"),
                    });
                    console.log(err);
                }
            }
        );
    };

    render = () => {
        const t = this.props.t;
        return (
            // <div className="modal modal--spec-select active">
            <ModalWrapper>
                <div className="modal__background"></div>
                <div className="modal__body">
                    <div className="reg__three">
                        <div className="reg__three--top">
                            <div className="reg__three--img">
                                <img src="/static/images/specreg.png" alt="" />
                            </div>
                            <div className="reg__three--heading">{t("specialtySelection.selectCategory")}:</div>
                            <div className="reg__three--text">{t("specialtySelection.oneCategory")}</div>
                        </div>

                        <div className="reg__three--bottom">
                            <div className="reg__three--cat">
                                <button
                                    style={{
                                        backgroundColor: "transparent",
                                        border: "medium none",
                                    }}
                                    onClick={() => {
                                        this.selectSpecialty(SpecialistType.MEDICAL_SPECIALIST);
                                    }}
                                    className="spec__space"
                                >
                                    <div className="reg__three--column">
                                        <img className="spec__img" src="/static/images/ludina.png" alt="" />
                                        <div className="reg__three--column_heading">
                                            {t("specialtySelection.medicalSpec")}
                                        </div>
                                    </div>
                                </button>
                                <div className="reg__three--column">
                                    <div className="reg__three--column_text">
                                        {t("specialtySelection.medicalSpecText")}
                                    </div>
                                </div>
                            </div>

                            <div className="reg__three--cat">
                                <button
                                    style={{
                                        backgroundColor: "transparent",
                                        border: "medium none",
                                    }}
                                    onClick={() => {
                                        this.selectSpecialty(SpecialistType.TRANSLATOR);
                                    }}
                                    className="spec__space"
                                >
                                    <div className="reg__three--column">
                                        <img className="trans__img" src="/static/images/translator.png" alt="" />
                                        <div className="reg__three--column_heading">
                                            {t("specialtySelection.translateSpec")}
                                        </div>
                                    </div>
                                </button>
                                <div className="reg__three--column">
                                    <div className="reg__three--column_text">
                                        {t("specialtySelection.translateSpecText")}
                                    </div>
                                </div>
                            </div>

                            <div className="reg__three--cat">
                                <button
                                    style={{
                                        backgroundColor: "transparent",
                                        border: "medium none",
                                    }}
                                    onClick={() => {
                                        this.selectSpecialty(SpecialistType.ADMINISTRATOR);
                                    }}
                                    className="spec__space"
                                >
                                    <div className="reg__three--column">
                                        <img className="admin__img" src="/static/images/adminadmin.png" alt="" />
                                        <div className="reg__three--column_heading">
                                            {t("specialtySelection.adminSpec")}
                                        </div>
                                    </div>
                                </button>
                                <div className="reg__three--column">
                                    <div className="reg__three--column_text">
                                        {t("specialtySelection.adminSpecText")}
                                    </div>
                                </div>
                            </div>
                        </div>

                        {this.state.specialtySelectionError && (
                            <div className="form-field-error">{this.state.specialtySelectionError}</div>
                        )}

                        <div className="reg__three--start" onClick={this.props.close}>
                            <div className="reg__three--start_text">{t("specialtySelection.user")}</div>
                        </div>
                    </div>
                </div>
            </ModalWrapper>
        );
    };
}
