import { Mapper } from "../domain/mappers/mapper";
import { SimpleMapper } from "./SimpleMapper";
import { Skill } from "../domain/models/Skill";
import { SkillDTO } from "../domain/models/dto/SkillDTO";
import { SkillModel } from "../models/SkillModel";

class SkillMapper extends SimpleMapper<Skill, SkillDTO> {
    protected entityConstructor = SkillModel;

    protected fromDTOFields: string[] = [
        "id",
        "userId",
        "skill",
        "validate",
        "added",
    ];
}

export default function skillMapperFactory(): Mapper<Skill, SkillDTO> {
    return new SkillMapper();
}
