import * as _ from "lodash";
import { Errors } from "../domain/enums/Errors";
import { ErrorService } from "../domain/services/ErrorService";

export class ErrorServiceImpl implements ErrorService {
    getErrorData(error: Error) {
        try {
            return _.get(JSON.parse(error.message), "data.data");
        } catch (err) {
            console.log(err);
            return null;
        }
    }

    getErrorCode(error: Error): Errors {
        return _.get(this.getErrorData(error), "message.errorCode");
    }

    getErrorTranslateKey(error: Error, prefix: string = '', defaultValue?: string) {
        const errorCode = this.getErrorCode(error);
        if (!errorCode) {
            return prefix + defaultValue || null;
        }
        return prefix + Errors[errorCode];
    }

    getErrorTranslateKeyByErrorCode(errorCode: Errors, prefix: string = '') {
        if (!_.isString(errorCode)) {
            return null;
        }
        return prefix + Errors[errorCode];
    }

    async throwResponseError(response) {
        const responseJSON = await response.json();
        throw new Error(
            JSON.stringify({
                statusText: response.statusText,
                data: responseJSON,
            })
        );
    }
}

export function ErrorServiceFactory(): ErrorService {
    return new ErrorServiceImpl();
}
