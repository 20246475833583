import { Field, Form, Formik } from "formik";
import React, { Component } from "react";
import * as Yup from "yup";
import { userSpecialistPromocodeServiceFactory } from "../../services/UserSpecialistPromocodeService";
import { UserSpecialistPromocodeService } from "../../domain/services/UserSpecialistPromocodeService";
import { ErrorServiceImpl, ErrorServiceFactory } from "../../services/ErrorService";
import FixedHeightError from "../shared/FixedHeightError";
import ModalWrapper from "../modal/ModalWrapper";

interface UsePromocodeModalProps {
    close: () => void;
    t(key?: string): string;
}

export default class UsePromocodeModal extends Component<UsePromocodeModalProps, any> {
    userSpecialistPromocodeService: UserSpecialistPromocodeService;
    errorService: ErrorServiceImpl;
    constructor(props) {
        super(props);
        this.state = {
            namePattern: new RegExp("^[A-Z0-9]{10}$"),
        };

        this.userSpecialistPromocodeService = userSpecialistPromocodeServiceFactory();
        this.errorService = ErrorServiceFactory();
    }

    render() {
        const t = this.props.t;
        return (
            <ModalWrapper>
                <div className="modal__background"></div>
                <div className="modal__body">
                    <div className="advice__top_ns_use_promocode_modal">
                        <div className="advice__top--headline_ns base_modal_body_advice_top">
                            {t("usePromocode.header")}
                        </div>
                    </div>
                    <div className="reg__two--bottom_use_promocode_modal">
                        <Formik
                            initialValues={{ name: "" }}
                            validationSchema={Yup.object().shape({
                                name: Yup.string().matches(this.state.namePattern, t("usePromocode.nameError")),
                            })}
                            onSubmit={async (values, { setSubmitting, setErrors }) => {
                                setSubmitting(true);
                                const { name } = values;
                                if (!name) {
                                    setErrors({
                                        name: t("usePromocode.enterValue"),
                                    });
                                    setSubmitting(false);
                                    return;
                                }
                                try {
                                    await this.userSpecialistPromocodeService.usePromocode(name);
                                    window.location.replace("/spec/request");
                                } catch (err) {
                                    const translationKey = this.errorService.getErrorTranslateKey(err);
                                    setErrors({
                                        name: t(translationKey ? `errors.${translationKey}` : "errors.standardError"),
                                    });
                                }
                                setSubmitting(false);
                            }}
                        >
                            {() => (
                                <Form>
                                    <div style={{ paddingLeft: "57px", paddingRight: "40px", width: "540px" }}>
                                        <Field name="name">
                                            {({ field, meta }) => (
                                                <div className="advice__body_use_promocode_modal">
                                                    <div className="promocode-input-container">
                                                        <div className="reg__first--input use_promocode_input_container">
                                                            <input
                                                                placeholder={t("usePromocode.placeholder")}
                                                                {...field}
                                                            />
                                                        </div>
                                                    </div>
                                                    <FixedHeightError meta={meta} />
                                                </div>
                                            )}
                                        </Field>

                                        <div className="footer-button-container">
                                            <button className="use_promocode_button" type="submit">
                                                <span className="reg__three--column_heading">
                                                    {t("usePromocode.activate")}
                                                </span>
                                            </button>

                                            <a className="use_promocode_button" href="/spec/request">
                                                <span className="reg__three--column_heading">{t("general.skip")}</span>
                                            </a>
                                        </div>
                                    </div>
                                </Form>
                            )}
                        </Formik>
                    </div>
                </div>
            </ModalWrapper>
        );
    }
}
