import _ from "lodash";
import React from "react";
import { useTranslation } from "react-i18next";
import BaseModal from "./BaseModal";

export interface MaximumOfSpecialistAddedModalProps {
    close: () => void;
    editButtonHandler: (handler?: () => void) => void;
    message: string;
}

export default function MaximumOfSpecialistAddedModal(props: MaximumOfSpecialistAddedModalProps) {
    const { t } = useTranslation();
    return (
        <BaseModal close={props.close} headerKey={"general.information"} headerImage={"/static/images/infotrans.png"}>
            <div className="advice__body--text">{t(props.message)}</div>
            <div className="advice__buttons small__advice__buttons">
                <a
                    onClick={() => {
                        props.editButtonHandler();
                        props.close();
                    }}
                >
                    <div className="advice__order" style={{ minWidth: "120px" }}>
                        {t("maxSpecialistsModal.edit")}
                    </div>
                </a>
                <a onClick={props.close}>
                    <div className="advice__find" style={{ minWidth: "120px" }}>
                        {t("general.closeBtn")}
                    </div>
                </a>
            </div>
        </BaseModal>
    );
}
