import { RegistrationForm } from "../domain/forms/RegistrationForm";
import { IUserMapper } from "../domain/mappers/user-mapper";
import { RegistrationDTO } from "../domain/models/dto/RegistrationDTO";
import { UserDTO } from "../domain/models/dto/UserDTO";
import { User } from "../domain/models/User";
import { userFactory } from "../models/UserModel";

export class UserMapper implements IUserMapper<User, UserDTO> {
    protected skipServerUtcToLocalConversion: boolean;

    constructor(skipServerUtcToLocalConversion: boolean) {
        this.skipServerUtcToLocalConversion = skipServerUtcToLocalConversion;
    }

    toDTO(user: User): UserDTO {
        if (!user) {
            return null;
        }
        return null;
    }

    toRegistrationDTO(data: RegistrationForm): RegistrationDTO {
        return {
            name: data.name,
            surname: data.surname,
            patronimyc: data.patronimyc,
            email: data.email,
            phone: data.phone,
            languageId: data.languageId,
            birthday: data.birthday,
            countryId: data.countryId,
            city: data.city,
            timezone: data.timezone,
            currencyId: data.currencyId,
            password: data.password,
        }
    }

    fromDTO(userData: UserDTO): User {
        if (!userData) {
            return null;
        }
        return userFactory(
            {
                id: userData.id || "",
                balance: userData.balance || 0,
                isAnonymous: !!userData.isAnonymous,

                name: userData.name || "",
                surname: userData.surname || "",
                imageUrl: userData.imageUrl || "",
                languages: userData.languages || [""],
                timezone: userData.timezone,
                email: userData.email || "",
                phone: userData.phone || "",
                birthday: userData.birthday,
                isSpecialist: userData.isSpecialist,
                specialistMode: userData.specialistMode,
            },
            this.skipServerUtcToLocalConversion
        );
    }
}

export function userMapperFactory(
    skipServerUtcToLocalConversion: boolean
): IUserMapper<User, UserDTO> {
    return new UserMapper(skipServerUtcToLocalConversion);
}
