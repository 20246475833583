import React, { Component } from "react";
import { Cart } from "../../domain/models/Cart";
import TimeView from "./TimeView";
import classNames from "classnames";
import _ from "lodash";

export interface WeekCalendarProps {
    cart: Cart;
    initialIntervalIndex?: number;
    initialScrollTop?: number;
    week: moment.Moment[][];
    ref: React.Ref<WeekCalendar>; // this is small hack to avoid ref absence error
    handleTimeCellClick: (time: moment.Moment) => void;
    visibleIntervalIndexChanged: (intervalIndex: number, currentScrollTop: number) => void;
}

export interface IWeekCalendar {
    getDayHeader(date: moment.Moment): string;
    getCellClassNames(time: moment.Moment): any;
    isTimeCellSelected(time: moment.Moment): boolean;
    scrollToInterval(index: number): boolean;
}

const INTERVAL_PREFIX = "interval_";

export default class WeekCalendar extends Component<WeekCalendarProps> implements IWeekCalendar {
    intervals: { [property: string]: HTMLDivElement } = {};
    calendarContainer: React.RefObject<HTMLDivElement>;
    activeIntervalIndex: number = 0;
    currentScrollTop: number = 0;
    id: string;
    constructor(props) {
        super(props);
        this.calendarContainer = React.createRef<HTMLDivElement>();
        this.id = _.uniqueId(INTERVAL_PREFIX);
    }

    getDayHeader = (date: moment.Moment) => {
        const day = date.date();
        const month = date.month();
        return `${day < 10 ? "0" + day : day}.${month + 1 < 10 ? "0" + (month + 1) : month + 1}`;
    };

    getCellClassNames = (time: moment.Moment) => {
        const cart = this.props.cart;
        const disabled = !cart.schedule.isScheduleIncludes(time);
        const isTimeCellSelected = this.isTimeCellSelected(time);
        const isApplicableTimeRange = cart.isApplicableTimeRange;
        return classNames({
            "calendar__cell-checkbox_ns": true,
            disabled: disabled,
            selected_active_schedule_cell: !disabled && isTimeCellSelected && isApplicableTimeRange,
            active_schedule_cell: !disabled && !isTimeCellSelected,
            time_range_is_not_valid: isTimeCellSelected && !isApplicableTimeRange,
        });
    };

    /**
     *
     * @param {moment.Moment} time - selected local time
     * @returns
     */
    isTimeCellSelected = (time: moment.Moment) => {
        const cart = this.props.cart;
        if (!cart.isMeetingTimeSet) {
            return false;
        }
        return cart.isIncludesTimePoint(time);
    };

    scrollToInterval = (index: number): boolean => {
        if (!_.isNumber(index) || !this.calendarContainer || !this.calendarContainer.current) {
            return false;
        }
        const interval = this.intervals[`${this.id}_${index}`];
        if (!interval) {
            console.log("WeekCalendar.scrollToInterval => no interval found");
            return false;
        }
        var topPos = (interval as any).offsetTop;
        (this.calendarContainer.current as any).scrollTop = topPos;
        return topPos;
    };

    calendarContainerScroll = () => {
        if (!this.calendarContainer || !this.calendarContainer.current) {
            return;
        }
        const scrollTop = (this.calendarContainer.current as any).scrollTop;
        this.currentScrollTop = scrollTop;
        const activeIntervalIndex = Math.max(
            ..._.values(this.intervals)
                .filter((interval: any) => {
                    return interval && !!_.get(interval, "dataset.intervalIndex") && interval.offsetTop <= scrollTop;
                })
                .map((interval) => {
                    return +_.get(interval, "dataset.intervalIndex", "0");
                })
        );
        this.activeIntervalIndex = activeIntervalIndex;
        this.props.visibleIntervalIndexChanged(activeIntervalIndex, scrollTop);
    };

    componentDidMount = () => {
        if (this.props.initialScrollTop != this.currentScrollTop && _.get(this, "calendarContainer.current")) {
            (this.calendarContainer.current as any).scrollTop = this.props.initialScrollTop;
            return;
        }
        if (this.props.initialIntervalIndex != this.activeIntervalIndex) {
            this.scrollToInterval(this.props.initialIntervalIndex);
            this.activeIntervalIndex = this.props.initialIntervalIndex;
        }
    };

    render() {
        const week = this.props.week;
        const id = this.id;
        return (
            <div className="calendar__week">
                <div className="mfirst__schedule">
                    <div className="mfirst__schedule--column">
                        <div className="mfirst__schedule--day_ns"></div>
                    </div>
                    {week.map((day, dayIndex) => (
                        <div className="mfirst__schedule--column" key={`week_${id}_dayLabel_${dayIndex}`}>
                            <div className="mfirst__schedule--day_ns">{this.getDayHeader(day[0])}</div>
                        </div>
                    ))}
                </div>
                <div
                    className="calendar_scrolling_div"
                    ref={this.calendarContainer}
                    onScroll={this.calendarContainerScroll}
                >
                    {new Array(4).fill(null).map((_value, interval) => (
                        <div
                            ref={(interval: any) => {
                                if (interval) {
                                    this.intervals[interval.id] = interval;
                                }
                            }}
                            className="schedule_interval calendar__interval-container"
                            id={`${id}_${interval}`}
                            key={`${id}_${interval}`}
                            data-interval-index={interval}
                        >
                            <div className="mfirst__schedule--column">
                                {new Array(12).fill(null).map((_value, cell_index) => (
                                    <div
                                        className="mfirst__schedule--time_ns"
                                        key={`week_${id}_timeLabel_${cell_index}`}
                                    >
                                        <TimeView time={week[0][interval * 12 + cell_index]} />
                                    </div>
                                ))}
                            </div>
                            {week.map((_day, j) => (
                                <div className="mfirst__schedule--column" key={`week_${id}_day_${j}`}>
                                    {new Array(12).fill(null).map((_value, z) => (
                                        <label
                                            className="mfirst__schedule--cell_ns"
                                            key={`week_${id}_day_${j}_time_${z}`}
                                        >
                                            <div
                                                className={this.getCellClassNames(week[j][interval * 12 + z])}
                                                onClick={() =>
                                                    this.props.handleTimeCellClick(week[j][interval * 12 + z])
                                                }
                                            />
                                        </label>
                                    ))}
                                </div>
                            ))}
                        </div>
                    ))}
                </div>
            </div>
        );
    }
}
