import { Mapper } from "../domain/mappers/mapper";
import { SpecialtyTypeDTO } from "../domain/models/dto/types/SpecialtyTypeDTO";
import { SpecialtyType } from "../domain/models/types/SpecialtyType";
import { SpecialtyTypeModel } from "../models/SpecialtyTypeModel";
import { SimpleMapper } from "./SimpleMapper";

class SpecialtyTypeMapper extends SimpleMapper<
    SpecialtyType,
    SpecialtyTypeDTO
> {
    protected entityConstructor = SpecialtyTypeModel;
    protected fromDTOFields: string[] = ["id", "title"];
}

export default function specialtyTypeMapperFactory(): Mapper<
    SpecialtyType,
    SpecialtyTypeDTO
> {
    return new SpecialtyTypeMapper();
}
