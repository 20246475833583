import React, { FC, useState } from "react";
import { withTranslation } from "react-i18next";
import { User } from "../../../../domain/models/User";
import BecomeASpecialistButton from "../../../become-a-specialist/BecomeASpecialistButton";
import RouterLink from "../../../shared/RouterLink";

interface MainMobileHeaderLeftProps {
    t(key?: string, values?: object): string;
    user?: User;
}

const MainMobileHeaderLeft: FC<MainMobileHeaderLeftProps> = (props) => {
    const [isOpenedMenu, setIsOpenedMenu] = useState(false);
    const menuDropdownHandler = () => {
        setIsOpenedMenu(!isOpenedMenu);
    };

    return (
        <div className="mobile-menu__left">
            {props.user.isAuthenticated && (
                <div onClick={menuDropdownHandler} className="header__dropdown dropdown header__dropdown--menu">
                    {props.user.imageUrl ? <img className="dropdown__image" src={props.user.imageUrl} /> : <></>}
                    <div className="dropdown__text">
                        {props.user.name}
                        <div
                            className={`dropdown__option__main dropdown__option__main--menu ${
                                isOpenedMenu ? "active" : ""
                            }`}
                        >
                            <div className="dropdown__option__item">
                                <RouterLink useAnchorElement className="dropdown__option__text" path="/consults">
                                    {props.t("mainHeader.userOptions.consults")}
                                </RouterLink>
                            </div>
                            <div className="dropdown__option__item">
                                <RouterLink useAnchorElement className="dropdown__option__text" path="/favorites">
                                    {props.t("mainHeader.userOptions.favorites")}
                                </RouterLink>
                            </div>
                            <div className="dropdown__option__item">
                                <RouterLink useAnchorElement className="dropdown__option__text" path="/settings">
                                    {props.t("mainHeader.userOptions.settings")}
                                </RouterLink>
                            </div>
                            <div className="dropdown__option__item">
                                <RouterLink useAnchorElement className="dropdown__option__text" path="/support">
                                    {props.t("mainHeader.userOptions.support")}
                                </RouterLink>
                            </div>
                            <div className="dropdown__option__item">
                                <RouterLink useAnchorElement className="dropdown__option__text" path="/logout">
                                    {props.t("mainHeader.userOptions.logout")}
                                </RouterLink>
                            </div>
                        </div>
                    </div>
                </div>
            )}
            <RouterLink useAnchorElement className="header__link" path="/features">
                {props.t("mainHeader.featuresBtn")}
            </RouterLink>
            <RouterLink useAnchorElement className="header__link" path="/search">
                {props.t("mainHeader.chooseSpecBtn")}
            </RouterLink>
            {props.user && !props.user.isSpecialist ? (
                <BecomeASpecialistButton />
            ) : (
                <RouterLink useAnchorElement className="header__link" path="/spec/request">
                    {props.t("mainHeader.requestBtn")}
                </RouterLink>
            )}
        </div>
    );
};

export default withTranslation()(MainMobileHeaderLeft);
