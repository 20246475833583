import {
    DEFAULT_MAX_FILES_COUNT,
    DEFAULT_TRANSACTION_TIMEOUT_SEC,
    FIVE_MB_BYTES,
    IMAGE_ALLOWED_EXTENSIONS_STR,
    SIXTY_MB_BYTES,
    TEXT_ALLOWED_EXTENSIONS_STR,
    VIDEO_ALLOWED_EXTENSIONS_STR,
} from "./domain/constants/Constants";
import { environment } from "./environments/environment";
import * as _ from "lodash";

export const conciliumLink = environment.CONCILIUM_LINK;
export const adminApiURL = environment.ADMIN_API_URL;
export const allowConferenceRestart =
    _.get(environment, "ALLOW_CONFERENCE_RESTART", "").toLowerCase() === "true";

export const paypalClientId = environment.PAYPAL_CLIENT_ID;
export const allowUsePromocodes =
    _.get(environment, "ALLOW_USE_PROMOCODES", "").toLowerCase() === "true";

/**
 * If true that mean that back-end will expect dates to be already converted to UTC.
 * Otherwise it will convert dates to UTC basic on the user timezone, defined in User model.
 */
export const skipServerUtcToLocalConversion =
    _.get(
        environment,
        "SKIP_SERVER_UTC_TO_LOCAL_CONVERSION",
        ""
    ).toLowerCase() === "true";

export const maxFileSizeBytes = +_.get(
    environment,
    "MAX_FILE_SIZE_BYTES",
    FIVE_MB_BYTES
);

export const imageAllowedExtensions: string[] = JSON.parse(
    _.get(environment, "IMAGE_ALLOWED_EXTENSIONS", IMAGE_ALLOWED_EXTENSIONS_STR)
);

export const textAllowedExtensions: string[] = JSON.parse(
    _.get(environment, "TEXT_ALLOWED_EXTENSIONS", TEXT_ALLOWED_EXTENSIONS_STR)
);

export const documentsAllowedExtensions = textAllowedExtensions.concat(
    imageAllowedExtensions
);

export const maxVideoSizeBytes = +_.get(
    environment,
    "MAX_VIDEO_SIZE_BYTES",
    SIXTY_MB_BYTES
);

export const videoAllowedExtensions = JSON.parse(
    _.get(environment, "VIDEO_ALLOWED_EXTENSIONS", VIDEO_ALLOWED_EXTENSIONS_STR)
);

export const maxFilesCount = +_.get(
    environment,
    "MAX_FILES_COUNT",
    DEFAULT_MAX_FILES_COUNT
);

export const transactionTimeoutSec = +_.get(
    environment,
    "TRANSACTION_TIMEOUT_SEC",
    DEFAULT_TRANSACTION_TIMEOUT_SEC
);

export const recaptchaSiteKey = _.get(environment, "RECAPTCHA_SITE_KEY", "");

export const hideCommunityPages =
    _.get(environment, "HIDE_COMMUNITY_PAGES", "").toLowerCase() === "true";
