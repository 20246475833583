import { Mapper } from "../domain/mappers/mapper";
import { SimpleMapper } from "./SimpleMapper";
import { Award } from "../domain/models/Award";
import { AwardDTO } from "../domain/models/dto/AwardDTO";
import { AwardModel } from "../models/AwardModel";

class AwardMapper extends SimpleMapper<Award, AwardDTO> {
    protected entityConstructor = AwardModel;

    protected fromDTOFields: string[] = [
        "id",
        "userId",
        "type",
        "title",
        "fileHide",
        "fileName",
        "filePath",
        "validate",
        "added",
        "awardType",
    ];
}

export default function awardMapperFactory(): Mapper<Award, AwardDTO> {
    return new AwardMapper();
}
