import React, { FC } from "react";

interface SimpleClipProps {
    small?: boolean;
    width?: number;
    height?: number;
}

const SimpleClip: FC<SimpleClipProps> = (props) => {
    const defaultClass = "msecond__file--icon";
    const smallClipClass = "msecond__file--icon-small";
    const defaultSize = 18;

    return (
        <img
            className={props.small ? smallClipClass : defaultClass}
            width={props.width || defaultSize}
            height={props.height || defaultSize}
            src="/static/images/clip.png"
        />
    );
}

export default SimpleClip;
