import * as Yup from "yup";
import { SchemaFactoryProps } from "../models/SchemaFactory";

export const timeValidate = (props: SchemaFactoryProps) =>
    Yup.string()
        .matches(/^([0-9]{2})\:([0-9]{2})$/, " ")
        .required(props.t("errors.commonErrors.required"));

export const dateValidate = (props: SchemaFactoryProps) =>
    Yup.string()
        .matches(/^([0-9]{4})\-([0-1]{1})([0-9]{1})\-([0-9]{2})$/, " ")
        .required(props.t("errors.commonErrors.required"));
