import React, { FC, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { hideCommunityPages } from "../../Config";
import LanguageSelect from "../shared/LanguageSelect";
import MainFooterTypesList from "./MainFooterTypesList";
import { User } from "../../domain/models/User";
import { userServiceFactory } from "../../services/UserServiceImpl";
import BecomeASpecialistButton from "../become-a-specialist/BecomeASpecialistButton";
import { useFullWebsiteVersion } from "../../hooks/useFullWebsiteVersion";
import RouterLink from "../shared/RouterLink";

interface MainFooterProps {}

const MainFooter: FC<MainFooterProps> = () => {
    const { t } = useTranslation();
    const [user, setUser] = useState<User>(null);

    useEffect(() => {
        (async function () {
            const userService = userServiceFactory();
            const currentUser = await userService.getCachedUser();
            if (currentUser) {
                setUser(currentUser);
            }
        })();
    }, []);

    const fullVersionBtn = "footer__btn--version";
    useFullWebsiteVersion(fullVersionBtn);

    return (
        <footer className="footer">
            <div className="footer__left">
                <div className="footer__top footer__top--border">
                    <RouterLink path="/">
                        <img className="footer__logo" src="/static/images/logol.png" />
                    </RouterLink>
                    <div className="footer__social">
                        <RouterLink useAnchorElement className="footer__social--item" path="#">
                            <img src="/static/images/fb.png" />
                        </RouterLink>
                        <RouterLink useAnchorElement className="footer__social--item" path="#">
                            <img src="/static/images/yt.png" />
                        </RouterLink>
                        <RouterLink useAnchorElement className="footer__social--item" path="#">
                            <img src="/static/images/vk.png" />
                        </RouterLink>
                        <RouterLink useAnchorElement className="footer__social--item" path="#">
                            <img src="/static/images/gp.png" />
                        </RouterLink>
                    </div>
                </div>
                <div className="footer__body footer__body_start">
                    <div className="footer__column">
                        <RouterLink useAnchorElement className="footer__link" path="/about">
                            {t("footer.aboutUs")}
                        </RouterLink>
                        <RouterLink useAnchorElement className="footer__link" path="/features">
                            {t("footer.features")}
                        </RouterLink>
                        {!user?.isSpecialist && <BecomeASpecialistButton buttonClassName="footer__link calendarShow" />}
                        {!hideCommunityPages && (
                            <RouterLink useAnchorElement className="footer__link" path="/community/articles">
                                {t("footer.articles")}
                            </RouterLink>
                        )}
                        <RouterLink useAnchorElement className="footer__link" path="/support">
                            {t("footer.support")}
                        </RouterLink>
                    </div>
                    <div className="footer__slogan">
                        <div className="footer__slogan--text">{t("footer.slogan")}</div>
                        <div className="footer__slogan--buttons">
                            <div className="footer__slogan--button">
                                <RouterLink useAnchorElement path="/search/spec">
                                    {t("footer.spec")}
                                </RouterLink>
                            </div>
                            <div className="footer__slogan--button">
                                <RouterLink useAnchorElement path="/search/spec/concilium">
                                    {t("footer.conc")}
                                </RouterLink>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <MainFooterTypesList />
            <div className="footer__bottom">
                <div className="footer__subcolumn">
                    <LanguageSelect
                        wrapperClassName="header__dropdown dropdown_white"
                        optionsContainerClassName="dropdown_top"
                    />
                    {!user?.id && (
                        <div className="footer__logins">
                            <RouterLink className="footer__logins--login" path="/login">
                                {t("footer.login")}
                            </RouterLink>
                            <RouterLink className="footer__logins--login" path="/registration">
                                {t("footer.register")}
                            </RouterLink>
                        </div>
                    )}
                </div>
                <div className="footer__subcolumn">
                    <div className="footer__copy">
                        Copyright © 2018 /
                        <RouterLink useAnchorElement path="/terms_of_use">
                            {t("footer.termsOfUse")}
                        </RouterLink>
                        /
                        <RouterLink useAnchorElement path="/privacy_policy">
                            {t("footer.privacyPolicy")}
                        </RouterLink>
                    </div>
                    <button className={fullVersionBtn}>{t("footer.fullVersion")}</button>
                </div>
            </div>
        </footer>
    );
};

export default MainFooter;
