import { Mapper } from "../domain/mappers/mapper";
import { UserSpecialistDTO } from "../domain/models/dto/UserSpecialistDTO";
import { UserSpecialist } from "../domain/models/UserSpecialist";
import { UserSpecialistModel } from "../models/UserSpecialistModel";
import * as _ from "lodash";
import { userMapperFactory } from "./UserMapper";
import { SimpleMapper } from "./SimpleMapper";
import awardMapperFactory from "./AwardMapper";
import educationMapperFactory from "./EducationMapper";
import experienceMapperFactory from "./ExperienceMapper";
import skillMapperFactory from "./SkillMapper";

export class UserSpecialistMapper extends SimpleMapper<
    UserSpecialist,
    UserSpecialistDTO
> {
    protected fromDTOFields: string[] = [
        "id",
        "status",
        "specialistType",

        "aboutSelf",
        "priceExtra",
        "priceUsual",
        "quantityConciliums",
        "quantityFeedbacks",
        "quantityRates",
        "rating",
        "videoUrl",

        "specialty",
        "user",

        "newVideoUrl",
        "newAboutSelf",
        "newPriceUsual",
        "newPriceExtra",

        "awards",
        "educations",
        "experiences",
        "skills",
    ];

    protected skipServerUtcToLocalConversion: boolean;

    constructor(skipServerUtcToLocalConversion: boolean) {
        super();
        this.skipServerUtcToLocalConversion = skipServerUtcToLocalConversion;
    }

    fromDTO(dto: UserSpecialistDTO): UserSpecialist {
        if (!dto) {
            return null;
        }

        const model = super.fromDTO(dto);

        const awardMapper = awardMapperFactory();
        const educationMapper = educationMapperFactory();
        const experienceMapper = experienceMapperFactory();
        const skillMapper = skillMapperFactory();

        return new UserSpecialistModel({
            ...model,
            specialty: dto.specialty || {
                title: "",
            },
            user: userMapperFactory(
                this.skipServerUtcToLocalConversion
            ).fromDTO(dto.user),

            awards: (dto.awards || []).map((awardDTO) =>
                awardMapper.fromDTO(awardDTO)
            ),
            educations: (dto.educations || []).map((educationsDTO) =>
                educationMapper.fromDTO(educationsDTO)
            ),
            experiences: (dto.experiences || []).map((experienceDTO) =>
                experienceMapper.fromDTO(experienceDTO)
            ),
            skills: (dto.skills || []).map((skillDTO) =>
                skillMapper.fromDTO(skillDTO)
            ),
        });
    }
}

export function userSpecialistMapperFactory(
    skipServerUtcToLocalConversion: boolean
): Mapper<UserSpecialist, UserSpecialistDTO> {
    return new UserSpecialistMapper(skipServerUtcToLocalConversion);
}
