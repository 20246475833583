import React, { FC } from "react";
import { Link } from "react-router-dom";
import { useInRouterContext } from "react-router-dom";

interface RouterLinkProps {
    path: string;
    className?: string;
    useAnchorElement?: boolean;
}

const RouterLink: FC<RouterLinkProps> = (props) => {
    const inRouterContext = useInRouterContext();

    if (inRouterContext && !props.useAnchorElement) {
        return (
            <Link to={props.path} className={props.className}>
                {props.children}
            </Link>
        );
    }

    return (
        <a href={props.path} className={props.className}>
            {props.children}
        </a>
    );
};

export default RouterLink;
