import { i18n } from "i18next";
import React, { FC, useState } from "react";
import { Translate } from "../../domain/models/Translate";
import { withTranslation } from "react-i18next";

interface LanguageSelectProps {
    t: Translate;
    i18n: i18n;
    wrapperClassName: string;
    optionsContainerClassName?: string;
}

const LanguageSelect: FC<LanguageSelectProps> = (props) => {
    const [isChangingLanguage, setIsChangeLanguage] = useState(false);
    const languageDropdownHandler = () => {
        setIsChangeLanguage(!isChangingLanguage);
    };

    return (
        <div onClick={languageDropdownHandler} className={`${props.wrapperClassName} dropdown`}>
            <img className="dropdown__image" src={`/static/images/flag_${props.i18n.language}.png`} />
            <div className="dropdown__text">
                {props.t("fields.language")}
                <div
                    className={`${
                        props.optionsContainerClassName || ""
                    } dropdown__option__main dropdown__option__main--lang ${isChangingLanguage ? "active" : ""}`}
                >
                    {props.i18n.language != "ru" ? (
                        <div className="dropdown__option__item">
                            <a className="dropdown__option__text" href={`/language?hl=ru&next=${window.location.href}`}>
                                {props.t("mainHeader.languages.ru")}
                            </a>
                        </div>
                    ) : (
                        <></>
                    )}
                    {props.i18n.language != "uk" ? (
                        <div className="dropdown__option__item">
                            <a className="dropdown__option__text" href={`/language?hl=uk&next=${window.location.href}`}>
                                {props.t("mainHeader.languages.uk")}
                            </a>
                        </div>
                    ) : (
                        <></>
                    )}
                    {props.i18n.language != "en" ? (
                        <div className="dropdown__option__item">
                            <a className="dropdown__option__text" href={`/language?hl=en&next=${window.location.href}`}>
                                {props.t("mainHeader.languages.en")}
                            </a>
                        </div>
                    ) : (
                        <></>
                    )}
                    {props.i18n.language != "pl" ? (
                        <div className="dropdown__option__item">
                            <a className="dropdown__option__text" href={`/language?hl=pl&next=${window.location.href}`}>
                                {props.t("mainHeader.languages.pl")}
                            </a>
                        </div>
                    ) : (
                        <></>
                    )}
                </div>
            </div>
        </div>
    );
};

export default withTranslation()(LanguageSelect);
