import moment from "moment";
import { Experience } from "../domain/models/Experience";
import { BaseModelImpl } from "./BaseModelImpl";
import { User } from "../domain/models/User";
import * as _ from "lodash";

export class ExperienceModel
    extends BaseModelImpl<Experience>
    implements Experience
{
    id: string;
    userId: string;
    currentJob: boolean;
    fromDate: string;
    toDate: string;
    position: string;
    employment: string;
    body: string;
    fileHide: boolean;
    fileName: string;
    filePath: string;
    validate: boolean;
    added: string;

    fromDateM: moment.Moment;
    toDateM: moment.Moment;

    constructor(data: Experience) {
        super(data);
    }

    get isInformationComplete(): boolean {
        return (
            !!this.employment &&
            !!this.fromDate &&
            (this.currentJob ? !this.toDate : !!this.toDate) &&
            !!this.position &&
            !!this.body
        );
    }

    getIsCorrectBeginDate(user: User): boolean {
        return (
            !!_.get(user, "minLaborDateM") &&
            !!this.fromDateM &&
            this.fromDateM <= moment() &&
            this.fromDateM >= user.minLaborDateM
        );
    }

    getIsCorrectEndDate(user: User): boolean {
        return this.currentJob
            ? !this.toDateM
            : !!_.get(user, "minLaborDateM") &&
                  !!this.toDateM &&
                  this.toDateM <= moment() &&
                  this.toDateM >= user.minLaborDateM;
    }

    getIsCorrectDatesRange(user: User): boolean {
        return (
            this.getIsCorrectBeginDate(user) &&
            this.getIsCorrectEndDate(user) &&
            (this.currentJob ? true : this.toDate >= this.fromDate)
        );
    }
}
