import { LoginDTO } from "../models/dto/LoginDTO";

export interface LoginForm extends LoginDTO {
    recaptchaToken: string;
}

export const loginInitialValues: LoginForm = {
    email: "",
    password: "",
    recaptchaToken: "",
};
