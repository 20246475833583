import _ from "lodash";
import React from "react";
import { useTranslation } from "react-i18next";
import BaseModal from "./BaseModal";

export interface ChangeSpecialistModalProps {
    close: () => void;
    headerImage: string;
    headerMessage: string;
    message: string;
    buttonLabel: string;
    actionURL: string;
}

export default function ChangeSpecialistModal(props: ChangeSpecialistModalProps) {
    const { t } = useTranslation();
    return (
        <BaseModal close={props.close} headerKey={props.headerMessage} headerImage={props.headerImage}>
            <div className="advice__body--text">{props.message}</div>
            <div className="advice__buttons">
                <div className="advice__button" onClick={() => props.close()}>
                    {t("general.continue")}
                </div>
                <a href={props.actionURL}>
                    <div className="advice__button advice__button--row">{t(props.buttonLabel)}</div>
                </a>
            </div>
        </BaseModal>
    );
}
