import React, { Component } from "react";
import ModalWrapper from "../modal/ModalWrapper";

interface AuthTypeSelectionModalProps {
    close: () => void;
    t(key?: string): string;
}

export default class AuthTypeSelectionModal extends Component<AuthTypeSelectionModalProps, any> {
    render() {
        const t = this.props.t;
        return (
            // <div className="modal modal-request active">
            <ModalWrapper>
                <div className="modal__background" onClick={this.props.close}></div>
                <div className="modal__body">
                    <div>
                        <div className="reg__two">
                            <div className="reg__two--top">
                                <div className="reg__two--img">
                                    <img src="/static/images/reg__two.png" alt="" />
                                </div>
                                <div className="reg__two--heading">{t("authTypeSelection.text")}</div>
                                <div className="reg__two--subheading"></div>
                            </div>
                            <div className="reg__two--bottom">
                                <a className="reg__two--continue reg__two--continue_text-modal" href="/login">
                                    {t("authTypeSelection.login")}
                                </a>
                                <a className="reg__two--continue reg__two--continue_text-modal" href="/registration">
                                    {t("authTypeSelection.registration")}
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </ModalWrapper>
        );
    }
}
