import { FormikProps, useFormik } from "formik";
import React, { FC, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { registrationSchemaFactory } from "../../validation-schemas/RegistrationSchema";
import { RegistrationForm as IRegistrationForm, registrationInitialValues } from "../../domain/forms/RegistrationForm";
import { ErrorServiceFactory } from "../../services/ErrorService";
import { userServiceFactory } from "../../services/UserServiceImpl";
import RegistrationSuccessModal from "./RegistrationSuccessModal";
import RegistrationForm from "./RegistrationForm";
import FixedSimpleError from "../shared/FixedSimpleError";
import LoadingPanel from "../shared/LoadingPanel";
import MainHeader from "../headers/main-header/MainHeader";
import MainFooter from "../footer/MainFooter";
import { useRouterNavigate } from "../../hooks/useRouterNavigate";

interface RegistrationPageProps {}

const errorService = ErrorServiceFactory();
const userService = userServiceFactory();

const RegistrationPage: FC<RegistrationPageProps> = (_props) => {
    const { t } = useTranslation();
    const registrationSchema = useMemo(() => registrationSchemaFactory({ t }), [t]);
    const [error, setError] = useState<string>(null);
    const [success, setSuccess] = useState(false);
    const [load, setLoad] = useState(true);
    const navigate = useRouterNavigate();

    useEffect(() => {
        (async () => {
            const user = await userService.getCachedUser();
            setLoad(false);
            if (user?.id) {
                navigate("/consults");
            }
        })();
    }, []);

    const registrationForm: FormikProps<IRegistrationForm> = useFormik({
        enableReinitialize: true,
        initialValues: registrationInitialValues,
        validationSchema: registrationSchema.schema,
        onSubmit: async (values) => {
            try {
                setError(null);
                const user = await userService.registration(values);
                if (!user) {
                    setError(t("errors.standardError"));
                } else {
                    setSuccess(true);
                }
            } catch (err) {
                const nameError = errorService.getErrorTranslateKey(err);
                setError(nameError ? t(`errors.${nameError}`) : t("errors.standardError"));
            }
        },
    });

    return (
        <>
            <title>
                {t("project.name")} - {t("registration.common.registrationLabel")}
            </title>
            <MainHeader />
            <div className="content content_margin-top">
                <LoadingPanel isVisible={load} />
                <RegistrationForm form={registrationForm} schema={registrationSchema} />
                <RegistrationSuccessModal success={success} />
                <FixedSimpleError error={error} className="failed_input_info registration_form_error" />
            </div>
            <MainFooter />
        </>
    );
};

export default RegistrationPage;
