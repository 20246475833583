import { FormikProps } from "formik";
import React, { FC } from "react";
import { RegistrationForm } from "../../domain/forms/RegistrationForm";
import { useTranslation } from "react-i18next";
import { getValidationClass } from "../../tools/FormTools";
import { SchemaFactoryData } from "../../models/SchemaFactory";
import FixedSimpleError from "../shared/FixedSimpleError";
import CountryTypeDropDown from "../shared/custom-drop-downs/CountryTypeDropDown";
import TimezonesDropDown from "../shared/custom-drop-downs/TimezonesDropDown";
import CurrencyTypeDropDown from "../shared/custom-drop-downs/CurrencyTypeDropDown";
import LanguageTypeDropDown from "../shared/custom-drop-downs/LanguageTypeDropDown";
import languageServiceFactory from "../../services/LanguageServiceImpl";
import PhoneInput from "react-phone-number-input";
import "react-phone-number-input/style.css";
import FormInput from "../shared/FormInput";
import RouterLink from "../shared/RouterLink";

interface RegistrationFormProps {
    form: FormikProps<RegistrationForm>;
    schema: SchemaFactoryData;
}

const languageService = languageServiceFactory();

const RegistrationForm: FC<RegistrationFormProps> = ({ form, schema }) => {
    const { t } = useTranslation();
    return (
        <form onSubmit={form.handleSubmit}>
            <div className="reg__firstmain">
                <div className="reg__first--top">
                    <div className="reg__first--top_link active">
                        <RouterLink path="/login">{t("registration.common.loginLabel")}</RouterLink>
                    </div>
                    <div className="reg__first--top_link">
                        <RouterLink path="/registration">{t("registration.common.registrationLabel")}</RouterLink>
                    </div>
                </div>
                <div className="reg__first--fields">
                    <div className="reg__first--headline">{t("registration.common.generalLabel")}</div>
                    <div className="reg__first--fields_wrap">
                        <div className="reg__first--fields_left">
                            <div className="reg__first--input">
                                <input
                                    className={`textField ${getValidationClass(form, "surname")}`}
                                    type="text"
                                    name="surname"
                                    onChange={form.handleChange}
                                    value={form.values.surname}
                                    minLength={schema.fields.surname.min}
                                    maxLength={schema.fields.surname.max}
                                    placeholder={schema.fields.surname.placeholder}
                                />
                                <FixedSimpleError error={form.errors.surname} className="failed_input_info" />
                            </div>
                            <div className="reg__first--input">
                                <FormInput form={form} schema={schema} name="name" type="text" />
                            </div>

                            <div className="reg__first--input registration_input">
                                <input
                                    className={`textField ${getValidationClass(form, "patronimyc")}`}
                                    type="text"
                                    name="patronimyc"
                                    onChange={form.handleChange}
                                    value={form.values.patronimyc}
                                    minLength={schema.fields.patronimyc.min}
                                    maxLength={schema.fields.patronimyc.max}
                                    placeholder={schema.fields.patronimyc.placeholder}
                                />
                                <FixedSimpleError error={form.errors.patronimyc} className="failed_input_info" />
                            </div>

                            <div className="reg__first--date">
                                <div className="reg__first--date_row">
                                    <div className="reg__first--date_text reg__first--headline">
                                        {t("registration.common.birthdayLabel")}
                                    </div>
                                </div>
                                <div className="reg__first--input">
                                    <FormInput form={form} schema={schema} name="birthday" type="date" />
                                </div>
                            </div>
                        </div>

                        <div className="reg__first--fields_right">
                            <div className="reg__first--input">
                                <FormInput form={form} schema={schema} name="email" type="text" />
                            </div>

                            <div className="reg__first--input">
                                <PhoneInput
                                    international={true}
                                    limitMaxLength={true}
                                    className={`textField ${getValidationClass(form, "phone")}`}
                                    name="phone"
                                    value={form.values.phone}
                                    placeholder={schema.fields.phone.placeholder}
                                    onChange={(value) => {
                                        form.setFieldValue("phone", value);
                                    }}
                                    labels={languageService.getCurrentPhoneNumberLocale()}
                                />
                                <FixedSimpleError
                                    error={form.errors.phone}
                                    className="failed_input_info"
                                    useTouched
                                    touched={form.touched.phone}
                                />
                            </div>

                            <div className="reg__first--input">
                                <CountryTypeDropDown form={form} schema={schema} />
                                <FixedSimpleError error={form.errors.countryId} className="failed_input_info" />
                            </div>
                            <div className="reg__first--input">
                                <FormInput form={form} schema={schema} name="city" type="text" />
                            </div>

                            <div className="specsettings__skills--headline_wrap">
                                <div className="specsettings__heading">
                                    <b>{t("registration.common.timezoneLabel")}</b>
                                    <div className="infotext_positionTwo infotext_box infotext_text">
                                        {t("registration.common.timezoneInfo")}
                                    </div>
                                </div>

                                <div className="reg__first--input">
                                    <TimezonesDropDown form={form} schema={schema} />
                                    <FixedSimpleError
                                        error={form.errors.timezone}
                                        className="failed_input_info"
                                        useTouched
                                        touched={form.touched.timezone}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="reg__first--date_column">
                    <div className="reg__first--date_block">
                        <div className="specsettings__heading">
                            <b>{t("registration.common.currencyLabel")}</b>
                            <div className="infotext_positionTwo infotext_box infotext_text">
                                {t("registration.common.currencyInfo")}
                            </div>
                        </div>
                        <div className="reg__first--input" style={{ width: "100%" }}>
                            <CurrencyTypeDropDown form={form} schema={schema} />
                            <FixedSimpleError error={form.errors.currencyId} className="failed_input_info" />
                        </div>
                    </div>

                    <div className="reg__first--date_block">
                        <div className="specsettings__heading">
                            <b>{t("registration.common.languageLabel")}</b>
                            <div className="infotext_positionTwo infotext_box infotext_text">
                                {t("registration.common.languageInfo")}
                            </div>
                        </div>
                        <div className="reg__first--input">
                            <LanguageTypeDropDown form={form} schema={schema} />
                            <FixedSimpleError error={form.errors.languageId} className="failed_input_info" />
                        </div>
                    </div>
                </div>

                <div className="reg__first--password">
                    <div className="reg__first--headline">{t("registration.common.passwordLabel")}</div>
                    <div className="reg__first--fields_wrap">
                        <div className="reg__first--input reg__first--date_block">
                            <FormInput form={form} schema={schema} name="password" type="password" />
                        </div>
                        <div className="reg__first--input reg__first--date_block">
                            <input
                                className={`textField ${getValidationClass(form, "confirmPassword")}`}
                                type="password"
                                name="confirmPassword"
                                onChange={form.handleChange}
                                value={form.values.confirmPassword}
                                minLength={schema.fields.confirmPassword.min}
                                maxLength={schema.fields.confirmPassword.max}
                                placeholder={schema.fields.confirmPassword.placeholder}
                            />
                            <FixedSimpleError error={form.errors.confirmPassword} className="failed_input_info" />
                        </div>
                    </div>
                </div>

                <button
                    disabled={!form.isValid}
                    className="registration_btn reg__first--apply_text"
                    type="submit"
                    id="user-registration-submit"
                >
                    {t("registration.common.submitButtonLabel")}
                </button>
            </div>
        </form>
    );
};

export default RegistrationForm;
