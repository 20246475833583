import { useNavigate } from "react-router-dom";
import { useInRouterContext } from "react-router";

export const useRouterNavigate = () => {
    const inRouterContext = useInRouterContext();
    const navigate = inRouterContext
        ? useNavigate()
        : (path: string) => window.location.replace(path);

    const navigateToPage = (path: string) => {
        window.scrollTo({ top: 0 });
        navigate(path);
    };

    return navigateToPage;
};
