import { Mapper } from "../domain/mappers/mapper";
import { CurrencyType } from "../domain/models/types/CurrencyType";
import { CurrencyTypeDTO } from "../domain/models/dto/types/CurrencyTypeDTO";
import { CurrencyTypeModel } from "../models/CurrencyTypeModel";
import { SimpleMapper } from "./SimpleMapper";

class CurrencyTypeMapper extends SimpleMapper<CurrencyType, CurrencyTypeDTO> {
    protected entityConstructor = CurrencyTypeModel;

    protected fromDTOFields: string[] = [
        "id",
        "title",
    ];
}

export default function currencyTypeMapperFactory(): Mapper<CurrencyType, CurrencyTypeDTO> {
    return new CurrencyTypeMapper();
}
