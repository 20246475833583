import { deleteRequest, get, post } from "../tools/Tools";
import { conciliumLink, skipServerUtcToLocalConversion } from "../Config";
import { CartService } from "../domain/services/new-conference-process/CartService";
import { Cart } from "../domain/models/Cart";
import { cartMapperFactory } from "../mappers/CartMapper";
import { BaseServiceImpl } from "./BaseServiceImpl";
import _ from "lodash";
import { userSpecialistMapperFactory } from "../mappers/UserSpecialistMapper";
import { UserSpecialist } from "../domain/models/UserSpecialist";
import moment from "moment";
import { CartStatus } from "../domain/enums/CartStatus";
import { CustomFile } from "../domain/models/CustomFile";
import fileMapperFactory from "../mappers/FileMapper";

export class CartServiceImpl extends BaseServiceImpl implements CartService {
    private static _instance: CartService;
    private _cart: Cart;

    private constructor() {
        super();
    }

    get cart(): Cart {
        return this._cart;
    }

    get isSpecialistsAdded(): boolean {
        return !!this.cart.specialists?.length;
    }

    static getCartServiceInstance(): CartService {
        if (!CartServiceImpl._instance) {
            CartServiceImpl._instance = new CartServiceImpl();
        }
        return CartServiceImpl._instance;
    }

    async getCachedCart(reload?: boolean, clearIfExpired?: boolean, getFiles?: boolean) {
        if (!this._cart || reload) {
            this._cart = await this.getCart(clearIfExpired, getFiles);
        }
        return this._cart;
    }

    async getCart(
        clearIfExpired: boolean = false,
        getFiles: boolean = false
    ): Promise<Cart> {
        const params = `?clearIfExpired=${clearIfExpired}&getFiles=${getFiles}`;
        return cartMapperFactory(skipServerUtcToLocalConversion).fromDTO(
            this.getData(await get(`${conciliumLink || ""}/api/cart${params}`))
        );
    }

    async addSpec(specialistId: string): Promise<UserSpecialist> {
        if (!specialistId) {
            return;
        }
        return userSpecialistMapperFactory(
            skipServerUtcToLocalConversion
        ).fromDTO(
            this.getData(
                await post(`${conciliumLink}/api/cart/specialist`, {
                    specialistId,
                })
            )
        );
    }

    async createNewConcilium(): Promise<Cart> {
        return this.getData(await post(`${conciliumLink || ""}/concilium`, {}));
    }

    /**
     * @returns list of available time points in local time
     */
    async getCartCombinedSchedule(): Promise<moment.Moment[]> {
        return this.getData(
            await get(`${conciliumLink}/api/cart/combined-schedule`),
            []
        ).map((timePoint) =>
            skipServerUtcToLocalConversion
                ? moment.utc(timePoint).local()
                : moment(timePoint)
        );
    }

    async saveCart(cart: Cart, getPriceDetails?: boolean): Promise<Cart> {
        const url =
            `${conciliumLink}/api/cart` +
            (getPriceDetails ? "?getPriceDetails=true" : "");
        const mapper = cartMapperFactory(skipServerUtcToLocalConversion);
        return mapper.fromDTO(
            this.getData(await post(url, mapper.toDTO(cart)))
        );
    }

    async uploadCartSchedule() {
        this.cart.setCombinedSchedule(await this.getCartCombinedSchedule());
    }

    async saveAndUpdate(getPriceDetails?: boolean): Promise<Cart> {
        const cart = await this.saveCart(this.cart, getPriceDetails);
        this.cart.update(cart);
        return this.cart;
    }

    async updateCurrentCartStatus(status: CartStatus): Promise<void> {
        if (!_.isFinite(status) || !this.cart || this.cart.status === status) {
            return;
        }

        try {
            this.cart.setStatus(status);
            await this.saveAndUpdate();
        } catch (err) {
            console.log("CartServiceImpl.updateCurrentCartStatus => ERROR:");
            console.log(err);
        }
    }

    async addFileToConcilium(file: CustomFile): Promise<any> {
        if (!file) {
            return;
        }
        const url = `${conciliumLink}/api/cart/file`;
        const mapper = fileMapperFactory();
        return this.cart.update(
            this.getData(await post(url, mapper.toDTO(file)))
        );
    }

    async clearAndUpdateCartSpecialistsFlags(): Promise<Cart> {
        if (!this.cart) {
            return this.cart;
        }

        try {
            this.cart.setSkipLang(null);
            this.cart.setSkipAdmin(null);
            return this.saveAndUpdate();
        } catch (err) {
            console.log(
                "CartServiceImpl.clearAndUpdateCartSpecialistsFlags => ERROR:"
            );
            console.log(err);
        }
    }

    async deleteSpec(specialistId: string): Promise<string> {
        if (!specialistId) {
            return;
        }
        return this.getData(
            await deleteRequest(
                `${conciliumLink}/api/cart/specialist/${specialistId}`
            )
        );
    }
}

export function cartServiceFactory(): CartService {
    return CartServiceImpl.getCartServiceInstance();
}
