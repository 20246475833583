import * as _ from "lodash";
import { showComponentById } from "./DocumentTools";

export function getDateLocale(date) {
    return new Date(date).toLocaleDateString("en-US", {
        localeMatcher: "best fit",
        hour12: false,
        timeZone: "GMT",
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
        hour: "2-digit",
        minute: "2-digit",
        second: "2-digit",
    });
}

export function getValidationClass(form: any, fieldName: string): string {
    return form &&
        fieldName &&
        form.touched[fieldName] &&
        form.errors[fieldName]
        ? "invalid"
        : "";
}

export function getBooleanTranslation(
    t: (key?: string) => string,
    value: boolean
): string {
    return _.isBoolean(value)
        ? value
            ? t("general.yes")
            : t("general.no")
        : "";
}

/**
 * Initializes on click handler for submit button.
 * Before default handler happens this function run validation function.
 * If some of them returns false then default handler is canceled.
 * That prevents submitting of incorrect data.
 *
 * @param {string} submitButtonId - Id of submit button
 * @param {Function[]} fieldsValidators - list of validation functions that checks if submit is possible
 * @param {boolean} concurrent - defines if need to do all checks of cancel process if any validator failed
 * @param {string[]} errorMessageIdsToClear - list of Ids of error message components that should be cleared before new validation.
 * @returns
 */
export function initFormSubmitHandler(
    submitButtonId: string,
    fieldsValidators: (() => boolean)[],
    concurrent: boolean = false,
    errorMessageIdsToClear: string[] = []
) {
    if (!submitButtonId || _.isEmpty(fieldsValidators)) {
        return;
    }

    const button = document.getElementById(submitButtonId);

    if (!button) {
        return;
    }

    button.addEventListener("click", (event: MouseEvent) => {
        if (!_.isEmpty(errorMessageIdsToClear)) {
            errorMessageIdsToClear.forEach((errorMessageId) => {
                showComponentById(errorMessageId, false);
            });
        }

        let validationResults = !concurrent
            ? fieldsValidators.map((validator) => validator())
            : [
                  !fieldsValidators.find((validator) => {
                      return !validator();
                  }),
              ];
        if (
            validationResults.filter((validatorResult) => !validatorResult)
                .length
        ) {
            event.preventDefault();
        }
    });
}
