import moment from "moment-timezone";
import {
    DATETIME_FORMAT,
    DEFAULT_USER_PHOTO,
    MIN_LABOR_AGE,
} from "../domain/constants/Constants";
import { UserDTO } from "../domain/models/dto/UserDTO";
import { User } from "../domain/models/User";
import * as _ from "lodash";

export class UserModel implements User {
    id: string;
    email: string;
    phone: string;
    imageUrl: string;
    balance: number;
    isAnonymous: boolean;
    isSpecialist: boolean;
    specialistMode: boolean;
    name: string;
    surname: string;
    timezone: string;
    languages: string[];
    birthday: string;
    birthdayM: moment.Moment;
    minLaborDateM: moment.Moment;

    skipServerUtcToLocalConversion: boolean = false;

    constructor(data: UserDTO, skipServerUtcToLocalConversion: boolean) {
        Object.assign(this, data || {});
        this.skipServerUtcToLocalConversion = skipServerUtcToLocalConversion;
        this.birthdayM = this.birthday ? moment(this.birthday) : null;
        this.minLaborDateM = this.birthdayM ? this.birthdayM.clone().add(MIN_LABOR_AGE, "years") : null;
    }

    get fullName() {
        return `${this.name} ${this.surname}`;
    }

    get hasPhoto(): boolean {
        return !!this.imageUrl && this.imageUrl != DEFAULT_USER_PHOTO;
    }

    get hasLanguages(): boolean {
        return !!_.get(this, "languages.length");
    }

    get isAuthenticated(): boolean {
        return !this.isAnonymous;
    }

    getLanguageFilterStr(): string {
        return this.languages.reduce((acc, current) => {
            return (acc += "&language=" + current);
        }, "");
    }

    getUserCurrentTime(): moment.Moment {
        /**
         * In case if needed to define user's time by user's timezone,
         * need to get user time within user's timezone and recreate it in local browsers time
         */
        return !this.skipServerUtcToLocalConversion && this.timezone
            ? moment(moment().tz(this.timezone).format(DATETIME_FORMAT))
            : moment();
    }
}

export function userFactory(
    data: UserDTO,
    skipServerUtcToLocalConversion: boolean
): User {
    return new UserModel(data, skipServerUtcToLocalConversion);
}
