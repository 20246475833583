import { Mapper } from "../domain/mappers/mapper";
import { LanguageType } from "../domain/models/types/LanguageType";
import { LanguageTypeDTO } from "../domain/models/dto/types/LanguageTypeDTO";
import { LanguageTypeModel } from "../models/LanguageTypeModel";
import { SimpleMapper } from "./SimpleMapper";

class LanguageTypeMapper extends SimpleMapper<LanguageType, LanguageTypeDTO> {
    protected entityConstructor = LanguageTypeModel;

    protected fromDTOFields: string[] = [
        "id",
        "title",
    ];
}

export default function languageTypeMapperFactory(): Mapper<LanguageType, LanguageTypeDTO> {
    return new LanguageTypeMapper();
}
