import { getEnumTranslatedDropDownOptions } from "../../tools/EnumTools";
import { SelectProps } from "../models/SelectProps";
import { Translate } from "../models/Translate";

export enum RecordStatus {
    CREATED = 1,
    IN_REVIEW = 2,
    APPROVED = 3,
    DECLINED = 4,
}

const translatePrefix = "enums.recordStatus.";

export function getRecordStatusTranslation(
    t: Translate,
    recordStatus: RecordStatus
): SelectProps {
    return {
        value: recordStatus?.toString(),
        label: t(recordStatus && `${translatePrefix}${recordStatus}`),
    };
}

export function getRecordStatusDropdownOptions(
    t: Translate,
    excludeValues: any[] = []
): SelectProps[] {
    return getEnumTranslatedDropDownOptions(
        RecordStatus,
        translatePrefix,
        t,
        excludeValues
    );
}
