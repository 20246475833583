import _ from "lodash";
import { conciliumLink } from "../Config";
import { WidgetService } from "../domain/services/WidgetService";
import { Langs } from "../enums/langs-enum";
import { WidgetPages } from "../enums/widget-pages-enum";
import { widgetMapperFactory } from "../mappers/WidgetMapper";
import { get } from "../tools/Tools";
import { WidgetBlocks } from "../domain/models/Widget";

class WidgetServiceImpl implements WidgetService {
    async getAllWidgets(page: WidgetPages, lang: Langs): Promise<WidgetBlocks> {
        try {
            return widgetMapperFactory().fromBlockDTO(
                _.get(
                    await get(
                        `${conciliumLink}/api/widgets?page=${page}&lang=${lang}`
                    ),
                    "data"
                )
            );
        } catch (err) {
            console.log("WidgetServiceImpl.getAllWidgets => ", err);
        }
    }
}

export default function widgetServiceFactory(): WidgetService {
    return new WidgetServiceImpl();
}
