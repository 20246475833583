export enum CartStatus {
    ADDING_SPECIALISTS = 1,
    TIME_IS_SET = 2,
    PAYMENT_REQUIRED = 3,
    PROCESS_COMPLETED = 4,

    /**
     * This is specific status, used to made Cart process to skip first step.
     */
    SPECIALISTS_ADDED = 10,
}
