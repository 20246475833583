import React, { FC } from "react";
import { useTranslation } from "react-i18next";
import RouterLink from "../components/shared/RouterLink";

interface NotFoundProps {}

const NotFound: FC<NotFoundProps> = () => {
    const { t } = useTranslation();
    return (
        <div className="error-page__page-wrap">
            <title>{t("notFound.title")}</title>
            <div className="error-container">
                <div className="error-content-center">
                    <div className="error-column error-text-center">
                        <span className="error-title">{t("notFound.title")}</span>
                        <div className="error-message">{t("notFound.message")}</div>
                        <RouterLink path="/" className="error-btn-link">
                            {t("notFound.link")}
                        </RouterLink>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default NotFound;
