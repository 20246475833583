import { Mapper } from "../domain/mappers/mapper";
import { Cart } from "../domain/models/Cart";
import { CartDTO } from "../domain/models/dto/CartDTO";
import { CartModel } from "../models/CartModel";
import * as _ from "lodash";
import { userSpecialistMapperFactory } from "./UserSpecialistMapper";
import { SimpleMapper } from "./SimpleMapper";
import fileMapperFactory from "./FileMapper";

export class CartMapper extends SimpleMapper<Cart, CartDTO> {
    protected toDTOFields?: string[] = [
        "meetingTime",
        "duration",
        "recordingAudio",
        "recordingVideo",
        "description",
        // TODO :: remove this
        // "filePath",
        // "fileName",
        "status",
        "skipExtra",
        "skipLang",
        "skipAdmin",
    ];

    protected fromDTOFields?: string[] = [
        "id",
        "priceDetails",
        ...this.toDTOFields,
        "expirationTime",
        "isExpired",
    ];

    protected entityConstructor = CartModel;

    protected skipServerUtcToLocalConversion: boolean;

    constructor(skipServerUtcToLocalConversion: boolean) {
        super();
        this.skipServerUtcToLocalConversion = skipServerUtcToLocalConversion;
    }

    fromDTO(cartDTO: CartDTO): Cart {
        if (!cartDTO) {
            return null;
        }
        const cart = super.fromDTO(
            cartDTO,
            this.skipServerUtcToLocalConversion
        );

        const files = [];
        if (cartDTO.files) {
            cartDTO.files.map((file) => {
                files.push(
                    fileMapperFactory().fromDTO(file)
                )
            })
        }

        const specialists = [];
        if (cartDTO.specialists) {
            cartDTO.specialists.map((spec) => {
                specialists.push(
                    userSpecialistMapperFactory(
                        this.skipServerUtcToLocalConversion
                    ).fromDTO(spec)
                );
            });
        }

        /**
         * Need to set default value for consultation duration for correct work.
         * TODO :: add appropriate default value to DB and models.
         */
        cart.duration = cart.duration || 1;

        cart.setSpecialists(specialists);
        cart.setFiles(files);
        return cart;
    }
}

export function cartMapperFactory(
    skipServerUtcToLocalConversion: boolean
): Mapper<Cart, CartDTO> {
    return new CartMapper(skipServerUtcToLocalConversion);
}
