import React, { FC, useMemo } from "react";
import { SpecialtyType } from "../../domain/models/types/SpecialtyType";
import { LanguageType } from "../../domain/models/types/LanguageType";
import { BaseTypeDTO } from "../../domain/models/dto/types/BaseTypeDTO";
import LinkDropDown from "../shared/custom-drop-downs/LinkDropDown";
import { CustomDropDown } from "../../domain/components/CustomDropDown";

interface MainFooterTypesSelectProps {
    specialties: SpecialtyType[];
    languages: LanguageType[];
    admins: BaseTypeDTO[];
}

const SEARCH_SPEC_PAGE = "/search/spec";
const SEARCH_LANG_PAGE = "/search/lang";
const SEARCH_ADMIN_PAGE = "/search/admin";

const MainFooterTypesSelect: FC<MainFooterTypesSelectProps> = (props) => {
    const searchParams = new URLSearchParams(window.location.search);
    const specialtyId = searchParams.get("specialty");
    const languageId = searchParams.get("language");

    const getLinkToSpecPage = (item: CustomDropDown) => {
        return `${SEARCH_SPEC_PAGE}?specialty=${item.value}`;
    };

    const getLinkToLangPage = (item: CustomDropDown) => {
        return `${SEARCH_LANG_PAGE}?language=${item.value}`;
    };

    const getLinkToAdminPage = () => {
        return SEARCH_ADMIN_PAGE;
    };

    const dropDownSpecialties = useMemo(
        () =>
            props.specialties.map((item) => {
                return { value: item.id, label: item.title };
            }),
        [props.specialties]
    );

    const dropDownLanguages = useMemo(
        () =>
            props.languages.map((item) => {
                return { value: item.id, label: item.title };
            }),
        [props.languages]
    );

    const dropDownAdmins = useMemo(
        () =>
            props.admins.map((item) => {
                return { value: item.id, label: item.title };
            }),
        [props.admins]
    );

    const getCurrentDropDownSpecialty = () => {
        return dropDownSpecialties.find((item) => item.value === specialtyId);
    };

    const getCurrentDropDownLanguage = () => {
        return dropDownLanguages.find((item) => item.value === languageId);
    };

    return (
        <div className="footer__body footer__selectlink">
            <div className="footer__column selectlink">
                <LinkDropDown
                    useAnchorElement
                    titleKey="footer.right.medSpec"
                    current={getCurrentDropDownSpecialty()}
                    data={dropDownSpecialties}
                    getLinkToRedirect={getLinkToSpecPage}
                />
                <LinkDropDown
                    useAnchorElement
                    titleKey="footer.right.lang"
                    current={getCurrentDropDownLanguage()}
                    data={dropDownLanguages}
                    getLinkToRedirect={getLinkToLangPage}
                />
                <LinkDropDown
                    useAnchorElement
                    titleKey="footer.right.admin"
                    data={dropDownAdmins}
                    getLinkToRedirect={getLinkToAdminPage}
                />
            </div>
        </div>
    );
};

export default MainFooterTypesSelect;
