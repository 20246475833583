import React, { FC, useState } from "react";
import { userSpecialistServiceFactory } from "../../../../services/UserSpecialistServiceImpl";
import { User } from "../../../../domain/models/User";
import { SupportTicketCustomerRole } from "../../../../domain/enums/SupportTicketCustomerRole";
import { Translate } from "../../../../domain/models/Translate";
import { withTranslation } from "react-i18next";

interface MobileMenuButtonsProps {
    t: Translate;
    user: User;
}

const userSpecialistService = userSpecialistServiceFactory();

const MobileMenuButtons: FC<MobileMenuButtonsProps> = (props) => {
    const [specMode, setSpecMode] = useState(props.user.specialistMode);
    const specModeHandler = async () => {
        await userSpecialistService.changeSpecialistMode(!specMode);
        setSpecMode(!specMode);
        location.reload();
    };

    return (
        <div className="mobile-menu__buttons">
            {specMode ? (
                <div className="nav__switch switch">
                    <div className="switch__option">
                        <img className="switch__option--image" src="/static/images/user_icon.png" />
                        <div className="switch__option--text">
                            {props.t(`enums.supportTicketCustomerRole.${SupportTicketCustomerRole.GUEST}`)}
                        </div>
                    </div>
                    <div onClick={specModeHandler}>
                        <div className="switch__switch active_spec">
                            <img className="switch_approved" src="/static/images/approved.png" />
                        </div>
                    </div>
                    <div className="switch__option active">
                        <object className="switch__option--image" data="/static/images/doc_icon.svg"></object>
                        <div className="switch__option--text">
                            {props.t(`enums.supportTicketCustomerRole.${SupportTicketCustomerRole.SPECIALIST}`)}
                        </div>
                    </div>
                </div>
            ) : (
                <div className="nav__switch switch active">
                    <div className="switch__option active">
                        <object className="switch__option--image" data="/static/images/user_icon.svg"></object>
                        <div className="switch__option--text">
                            {props.t(`enums.supportTicketCustomerRole.${SupportTicketCustomerRole.GUEST}`)}
                        </div>
                    </div>
                    <div onClick={specModeHandler}>
                        <div className="switch__switch active_user"></div>
                    </div>
                    <div className="switch__option">
                        <img className="switch__option--image" src="/static/images/doc_icon.png" />
                        <div className="switch__option--text">
                            {props.t(`enums.supportTicketCustomerRole.${SupportTicketCustomerRole.SPECIALIST}`)}
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

export default withTranslation()(MobileMenuButtons);
