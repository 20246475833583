import React from "react";
import Popup from "reactjs-popup";

interface LoadingPanelProps {
    isVisible: boolean;
}
export default function LoadingPanel(props: LoadingPanelProps) {
    return (
        <>
            {props.isVisible ? (
                <Popup
                    open={true}
                    modal
                    overlayStyle={{ zIndex: 999999 }}
                    closeOnDocumentClick={false}
                    closeOnEscape={false}
                >
                    <div className="loader-wrapper loader-overlay">
                        <div className="loader"></div>
                    </div>
                </Popup>
            ) : (
                <></>
            )}
        </>
    );
}
