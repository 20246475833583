import moment from "moment";
import { Education } from "../domain/models/Education";
import { User } from "../domain/models/User";
import { BaseTypeDTO } from "../domain/models/dto/types/BaseTypeDTO";
import { BaseModelImpl } from "./BaseModelImpl";

export class EducationModel
    extends BaseModelImpl<Education>
    implements Education
{
    id: string;
    userId: string;
    type: string;
    specialtyName: string;
    institutionName: string;
    graduationYear: number;
    fileHide: boolean;
    fileName: string;
    filePath: string;
    validate: boolean;
    added: string;
    educationType: BaseTypeDTO;

    constructor(data: Education) {
        super(data);
    }

    get isInformationComplete(): boolean {
        return (
            !!this.institutionName &&
            !!this.graduationYear &&
            !!this.specialtyName &&
            !!this.fileName
        );
    }

    getIsValidGraduationYear(user: User): boolean {
        return !!(
            this.graduationYear &&
            user.minLaborDateM &&
            this.graduationYear <= moment.utc().year() &&
            this.graduationYear >=
                user.minLaborDateM.year()
        );
    }
}
