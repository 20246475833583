import React from "react";
import { getValidationClass } from "../../tools/FormTools";
import { FormikProps } from "formik";
import { SchemaFactoryData } from "../../models/SchemaFactory";
import FixedSimpleError from "./FixedSimpleError";

interface FormInputProps<T> {
    form: FormikProps<T>;
    schema: SchemaFactoryData;
    name: string;
    type?: string;
}

function FormInput<T>({ form, schema, name, type }: FormInputProps<T>) {
    return (
        <>
            <input
                className={`textField ${getValidationClass(form, name)}`}
                type={type || "text"}
                name={name}
                onChange={form.handleChange}
                onBlur={() => form.setFieldTouched(name, true)}
                value={form.values[name]}
                minLength={schema.fields[name].min}
                maxLength={schema.fields[name].max}
                placeholder={schema.fields[name].placeholder}
            />
            <FixedSimpleError
                error={form.errors[name]}
                className="failed_input_info"
                useTouched
                touched={form.touched[name]}
            />
        </>
    );
}

export default FormInput;
