import * as Yup from "yup";
import { SchemaFactoryProps, SchemaFactoryData, FieldsData } from "../models/SchemaFactory";

export const loginSchemaFactory = (props: SchemaFactoryProps): SchemaFactoryData => {
    const t = props.t;

    const fields: FieldsData = {
        email: {
            min: 4,
            max: 64,
            placeholder: t("login.fields.email")
        },
        password: {
            min: 1,
            max: 50,
            placeholder: t("login.fields.password")
        },
    }

    return {
        schema: Yup.object().shape({
            email: Yup.string()
                .email(t("errors.commonErrors.invalidEmail"))
                .min(fields.email.min, t("errors.commonErrors.min") + fields.email.min)
                .max(fields.email.max, t("errors.commonErrors.max") + fields.email.max)
                .required(t("errors.commonErrors.required")),
            password: Yup.string()
                .min(fields.password.min, t("errors.commonErrors.min") + fields.password.min)
                .max(fields.password.max, t("errors.commonErrors.max") + fields.password.max)
                .required(t("errors.commonErrors.required")),
        }), fields
    };
}
