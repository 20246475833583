import { SimpleMapper } from "./SimpleMapper";
import { Widget, WidgetBlocks } from "../domain/models/Widget";
import { WidgetBlockDTOs, WidgetDTO } from "../domain/models/dto/WidgetDTO";
import { WidgetModel } from "../models/WidgetModel";
import { IWidgetMapper } from "../domain/mappers/widget-mapper";

export class WidgetMapper extends SimpleMapper<Widget, WidgetDTO> {
    protected entityConstructor = WidgetModel;
    protected fromDTOFields: string[] = [
        "id",
        "lang",
        "page",
        "block",
        "blockName",
        "title",
        "body",
        "sort",
    ];

    fromBlockDTO(blockDTOs: WidgetBlockDTOs): WidgetBlocks {
        return Object.entries(blockDTOs).reduce((acc, [blockName, dto]) => {
            acc[blockName] = this.fromDTO(dto);
            return acc;
        }, {});
    }
}

export function widgetMapperFactory(): IWidgetMapper<Widget, WidgetDTO> {
    return new WidgetMapper();
}
