import _ from "lodash";
import React from "react";
import { useTranslation } from "react-i18next";
import ModalWrapper from "./ModalWrapper";

export interface BaseModalProps {
    headerKey: string;
    close: () => void;
    children: any;
    headerImage?: string;
    wrapperClass?: string;
}

export default function BaseModal(props: BaseModalProps) {
    const { t } = useTranslation();
    const headerImage = props.headerImage || "/static/images/m11.png";
    return (
        <ModalWrapper class={props.wrapperClass}>
            <div className="modal__body advice">
                <div className="advice__top">
                    <img src={headerImage} alt="" />
                    <div className="advice__top--headline">{t(props.headerKey)}</div>
                    <div
                        className="comquest__top--right modal__close"
                        style={{ top: "26px" }}
                        onClick={() => props.close()}
                    >
                        <img id="modal__close" src="/static/images/close.png" alt="" />
                    </div>
                </div>
                <div className="advice__body">{..._.isArray(props.children) ? props.children : [props.children]}</div>
            </div>
        </ModalWrapper>
    );
}
