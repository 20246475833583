import * as _ from "lodash";
import { post, get, deleteRequest } from "../tools/Tools";
import { conciliumLink, skipServerUtcToLocalConversion } from "../Config";
import { UserSpecialistService } from "../domain/services/UserSpecialistService";
import { PromocodeValidationDTO } from "../domain/models/dto/PromocodesDTO";
import { UserSpecialistDTO } from "../domain/models/dto/UserSpecialistDTO";
import { userSpecialistMapperFactory } from "../mappers/UserSpecialistMapper";
import { BaseServiceImpl } from "./BaseServiceImpl";
import { BehaviorSubject, Observable } from "rxjs";
import { filter, distinctUntilChanged } from "rxjs/operators";
import { UserSpecialist } from "../domain/models/UserSpecialist";

export class UserSpecialistServiceImpl
    extends BaseServiceImpl
    implements UserSpecialistService
{
    static _currentUserSpecialist: UserSpecialist;
    static readonly currentUserSpecialistSubject =
        new BehaviorSubject<UserSpecialist>(null);
    static readonly currentUserSpecialist$ =
        UserSpecialistServiceImpl.currentUserSpecialistSubject
            .asObservable()
            .pipe(
                filter((specialist) => !!specialist),
                distinctUntilChanged()
            );

    static mapper = userSpecialistMapperFactory(skipServerUtcToLocalConversion);

    currentUserSpecialist$: Observable<UserSpecialist>;
    currentUserSpecialistSubject: BehaviorSubject<UserSpecialist> =
        UserSpecialistServiceImpl.currentUserSpecialistSubject;

    constructor() {
        super();
        this.currentUserSpecialist$ =
            UserSpecialistServiceImpl.currentUserSpecialist$;
    }

    async createUserSpecialist(specType: number): Promise<UserSpecialistDTO> {
        if (!specType) {
            return;
        }
        await post(`${conciliumLink}/api/user-specialist`, {
            specType: specType,
        });
    }

    async getPromocodeValidation(
        specialistId: string
    ): Promise<PromocodeValidationDTO> {
        if (!specialistId) {
            return;
        }
        return _.get(
            await get(
                `${conciliumLink}/promocode/validation?specialistId=${specialistId}`
            ),
            "data"
        );
    }

    async removeSpecRequest(): Promise<void> {
        return deleteRequest(`${conciliumLink}/api/user-specialist`);
    }

    async getUserSpecialist(): Promise<UserSpecialist> {
        return UserSpecialistServiceImpl.getUserSpecialist();
    }

    static async getUserSpecialist(
        getExtended?: boolean
    ): Promise<UserSpecialist> {
        return UserSpecialistServiceImpl.mapper.fromDTO(
            UserSpecialistServiceImpl.getData(
                await get(
                    `${conciliumLink}/api/user-specialist${
                        getExtended ? "?getExtended=true" : ""
                    }`
                )
            )
        );
    }

    async getCurrentCachedUserSpecialist(
        getExtended?: boolean
    ): Promise<UserSpecialist> {
        if (!UserSpecialistServiceImpl._currentUserSpecialist) {
            UserSpecialistServiceImpl._currentUserSpecialist =
                await UserSpecialistServiceImpl.getUserSpecialist(getExtended);
            UserSpecialistServiceImpl.currentUserSpecialistSubject.next(
                UserSpecialistServiceImpl._currentUserSpecialist
            );
        }
        return UserSpecialistServiceImpl._currentUserSpecialist;
    }

    async changeSpecialistMode(specialistMode: boolean): Promise<boolean> {
        try {
            return !!(await post(`/api/user-specialist/specialist-mode`, {specialistMode}));
        }
        catch (err) {
            console.log("UserSpecialistServiceImpl.changeSpecialistMode => ERROR:");
            console.log(err);
        }
    }
}

export function userSpecialistServiceFactory(): UserSpecialistService {
    return new UserSpecialistServiceImpl();
}
