import React, { FC } from "react";
import { withTranslation } from "react-i18next";
import { User } from "../../../domain/models/User";
import BecomeASpecialistButton from "../../become-a-specialist/BecomeASpecialistButton";
import MainHeaderMenu from "./main-header-menu/MainHeaderMenu";
import RouterLink from "../../shared/RouterLink";

interface MainHeaderLeftProps {
    t(key?: string, values?: object): string;
    user?: User;
    isOpenedMenu: boolean;
}

const MainHeaderLeft: FC<MainHeaderLeftProps> = (props) => {
    return (
        <div className="header__left">
            <RouterLink path="/">
                <img className="header__logo" src="/static/images/logo.png" />
            </RouterLink>
            <MainHeaderMenu user={props.user} isOpenedMenu={props.isOpenedMenu} />
            <RouterLink useAnchorElement className="header__link" path="/features">
                {props.t("mainHeader.featuresBtn")}
            </RouterLink>
            <RouterLink useAnchorElement className="header__link" path="/search">
                {props.t("mainHeader.chooseSpecBtn")}
            </RouterLink>
            {props.user && !props.user.isSpecialist ? (
                <BecomeASpecialistButton />
            ) : (
                <RouterLink useAnchorElement className="header__link" path="/spec/request">
                    {props.t("mainHeader.requestBtn")}
                </RouterLink>
            )}
        </div>
    );
};

export default withTranslation()(MainHeaderLeft);
