import React, { FC } from "react";
import { BrowserRouter } from "react-router-dom";
import { AuthProvider } from "../../context/AuthProvider";
import ReactApp from "./ReactApp";

interface RootProps {}

const Root: FC<RootProps> = () => {
    return (
        <BrowserRouter>
            <AuthProvider>
                <ReactApp />
            </AuthProvider>
        </BrowserRouter>
    );
};

export default Root;
