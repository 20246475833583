import { Award } from "../domain/models/Award";
import { BaseTypeDTO } from "../domain/models/dto/types/BaseTypeDTO";
import { BaseModelImpl } from "./BaseModelImpl";

export class AwardModel extends BaseModelImpl<Award> implements Award {
    id: string;
    userId: string;
    type: string;
    title: string;
    fileHide: boolean;
    fileName: string;
    filePath: string;
    validate: boolean;
    added: string;
    awardType: BaseTypeDTO;

    constructor(data: Award) {
        super(data);
    }

    get isInformationComplete(): boolean {
        return !!this.title && !!this.fileName;
    }
}
