import * as _ from "lodash";
import { post } from "../tools/Tools";
import { conciliumLink } from "../Config";
import { UserSpecialistPromocodeService } from "../domain/services/UserSpecialistPromocodeService";
import { UserSpecialistPromocodeDTO } from "../domain/models/dto/UserSpecialistPromocodeDTO";

export class UserSpecialistPromocodeServiceImpl
    implements UserSpecialistPromocodeService
{
    async usePromocode(
        promocodeName: string
    ): Promise<UserSpecialistPromocodeDTO> {
        if (!promocodeName) {
            return;
        }
        return await post(
            `${conciliumLink}/api/promocode/user_specialists_promocode`,
            {
                promocodeName: promocodeName,
            }
        );
    }
}

export function userSpecialistPromocodeServiceFactory() {
    return new UserSpecialistPromocodeServiceImpl();
}
