import { Mapper } from "../domain/mappers/mapper";
import { FileDTO } from "../domain/models/dto/FileDTO";
import { CustomFile } from "../domain/models/CustomFile";
import { FileModel } from "../models/FileModel";
import { SimpleMapper } from "./SimpleMapper";

class FileMapper extends SimpleMapper<CustomFile, FileDTO> {
    protected entityConstructor = FileModel;

    protected fromDTOFields: string[] = [
        "id",
        "parentType",
        "purposeType",
        "extension",
        "filePath",
        "fileName",
        "parentId",
        "fileUrl",
        "originalFileName",
        "creatorId",
        "created",
        "updated",
    ]

    protected toDTOFields: string[] = [...this.fromDTOFields, "dataUrl"];
}

export default function fileMapperFactory(): Mapper<CustomFile, FileDTO> {
    return new FileMapper()
}
