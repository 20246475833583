import React, { FC } from "react";

interface SimpleClosingButtonProps {
    callback: () => void;
    width?: number;
    height?: number;
}

const SimpleClosingButton: FC<SimpleClosingButtonProps> = (props) => {
    const defaultSize = 15;

    return (
        <div
            // className="comquest__top--right modal__close"
            onClick={props.callback}
            style={{cursor: "pointer"}}
        >
            <img
                id="modal__close"
                alt=""
                src="/static/images/close.png"
                width={props.width || defaultSize}
                height={props.height || defaultSize}
            />
        </div>
    );
}

export default SimpleClosingButton;
