import React, { FC } from "react";
import { useTranslation } from "react-i18next";
import { WidgetBlocks } from "../../domain/models/Widget";
import { useSlideImages } from "../../hooks/useSlideImages";
import { useSmoothScroll } from "../../hooks/useSmoothScroll";
import RouterLink from "../shared/RouterLink";

interface MainPageContentProps {
    widgetBlocks: WidgetBlocks;
}

const MainPageContent: FC<MainPageContentProps> = (props) => {
    const { t } = useTranslation();

    useSlideImages("main__pagination--pag", "slide");
    useSmoothScroll("main__scroll", "main__body");

    return (
        <div className="content content_margin-top">
            <div className="main">
                <div className="main__top main__top--main">
                    <div className="main__slides">
                        <div className="main__slides--content">
                            <div className="main__slides--content-inner">
                                <div className="main__slides--text">{props.widgetBlocks.isl1.body}</div>
                                <div className="main__slides--subheadline">{props.widgetBlocks.isl1.title}</div>
                                <div className="main__slides--buttons">
                                    <div className="main__slides--button main__slides--button_blue">
                                        <RouterLink useAnchorElement path="/search/spec">
                                            {t("mainPage.searchSpec")}
                                        </RouterLink>
                                    </div>
                                    <div className="main__slides--button">
                                        <RouterLink useAnchorElement path="/search/spec/concilium">
                                            {t("mainPage.searchConc")}
                                        </RouterLink>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div id="slide1" className="main__slides--slide slide active">
                            <div className="main__slides--imageOne"></div>
                        </div>
                        <div id="slide2" className="main__slides--slide slide">
                            <div className="main__slides--imageTwo"></div>
                        </div>
                        <div id="slide3" className="main__slides--slide slide">
                            <div className="main__slides--imageThree"></div>
                        </div>
                    </div>
                    <div className="main__pagination">
                        <div id="pag1" className="main__pagination--pag active"></div>
                        <div id="pag2" className="main__pagination--pag"></div>
                        <div id="pag3" className="main__pagination--pag"></div>
                    </div>
                    <div className="main__scroll">
                        <div className="main__scroll--text main__scroll--text--black">{t("mainPage.title")}</div>
                        <img className="main__scroll--icon" src="/static/images/scroll.png" alt="" />
                    </div>
                </div>
                <div className="main__body">
                    <div className="main__body--line">
                        <div className="main__column">
                            <div className="main__column--heading">{props.widgetBlocks.imb1.title}</div>
                            <div className="main__column--text">{props.widgetBlocks.imb1.body}</div>
                            <div className="main__column--link">
                                <RouterLink useAnchorElement path="/search/spec">
                                    <div className="main__column--link_text">{t("mainPage.searchSpec")}</div>
                                </RouterLink>
                                <RouterLink useAnchorElement path="/search">
                                    <img className="main__column--link_icon" src="/static/images/mainlink.png" alt="" />
                                </RouterLink>
                            </div>
                        </div>
                        <div className="main__column main__columnImage main__columnImageOne">
                            <img src="../../static/images/main1.png" alt="" />
                        </div>
                    </div>
                    <div className="main__body--line">
                        <div className="main__column main__columnImage main__columnImageTwo">
                            <img src="../../static/images/main2.png" alt="" />
                        </div>
                        <div className="main__column">
                            <div className="main__column--heading">{props.widgetBlocks.imb2.title}</div>
                            <div className="main__column--text">{props.widgetBlocks.imb2.body}</div>
                            <div className="main__column--link">
                                <RouterLink useAnchorElement path="/search/spec">
                                    <div className="main__column--link_text">{t("mainPage.searchSpec")}</div>
                                </RouterLink>
                                <RouterLink useAnchorElement path="/search">
                                    <img className="main__column--link_icon" src="/static/images/mainlink.png" alt="" />
                                </RouterLink>
                            </div>
                        </div>
                    </div>
                    <div className="main__body--line">
                        <div className="main__column">
                            <div className="main__column--heading">{props.widgetBlocks.imb3.title}</div>
                            <div className="main__column--text">{props.widgetBlocks.imb3.body}</div>
                            <div className="main__column--link">
                                <RouterLink useAnchorElement path="/search/spec/concilium">
                                    <div className="main__column--link_text">{t("mainPage.searchConc")}</div>
                                </RouterLink>
                                <RouterLink useAnchorElement path="/search">
                                    <img className="main__column--link_icon" src="/static/images/mainlink.png" alt="" />
                                </RouterLink>
                            </div>
                        </div>
                        <div className="main__column main__columnImage main__columnImageThree">
                            <img src="../../static/images/main3.png" alt="" />
                        </div>
                    </div>
                </div>
                <div className="main__works">
                    <img className="main__works--bg" src="/static/images/mainbg.png" alt="" />
                    <div className="main__works--filter"></div>
                    <div className="main__works--heading">{t("mainPage.howItWorks")}</div>
                    <div className="main__works--stages">
                        <div className="main__works--stage">
                            <div className="main__works--stage_top">
                                <img src="/static/images/stage1.png" alt="" />
                            </div>
                            <div className="main__works--stage_heading">{props.widgetBlocks.ihw1.title}</div>
                            <div className="main__works--stage_text">{props.widgetBlocks.ihw1.body}</div>
                        </div>
                        <div className="main__works--stage">
                            <div className="main__works--stage_top">
                                <img src="/static/images/stage2.png" alt="" />
                            </div>
                            <div className="main__works--stage_heading">{props.widgetBlocks.ihw2.title}</div>
                            <div className="main__works--stage_text">{props.widgetBlocks.ihw2.body}</div>
                        </div>
                        <div className="main__works--stage">
                            <div className="main__works--stage_top">
                                <img src="/static/images/stage3.png" alt="" />
                            </div>
                            <div className="main__works--stage_heading">{props.widgetBlocks.ihw3.title}</div>
                            <div className="main__works--stage_text">{props.widgetBlocks.ihw3.body}</div>
                        </div>
                    </div>
                    <RouterLink className="main__works--button" path="/registration">
                        {t("mainPage.start")}
                    </RouterLink>
                </div>
            </div>
        </div>
    );
};

export default MainPageContent;
