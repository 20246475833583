import moment from "moment";
import React from "react";
import { NewConferenceState } from "./NewConferenceState";
import NewConferenceSpelialistsList from "./NewConferenceSpelialistsList";
import { NewConferenceDetailsView } from "./details-view/NewConferenceDetailsView";
import { NewConferenceExtra as NewConferenceExpress } from "./NewConferenceExpress";
import { NewConferenceSmallTime } from "./NewConferenceSmallTime";
import SpecialistsList from "./SpecialistsList";
import MultipleWeeksSwiperCalendar from "./MultipleWeeksSwiperCalendar";

export class NewConferenceSpelialistsSelectDateTime extends NewConferenceState {
    constructor(props) {
        super(props);
        this.state = {
            dateTimeCurrentPage: 0,
            showError: false,
        };
    }

    goToNextStep(): void {
        this.props.context.transitionTo(
            NewConferenceSpelialistsSelectDateTime.getNextStepComponentConstructor(this.cartService.cart)
        );
    }

    goToPrevStep(): void {
        this.props.context.transitionTo(NewConferenceSpelialistsList);
    }

    cancel(): void {
        this.props.context.handlerCancel();
    }

    handleNextButtonClick = async () => {
        const cart = this.cartService.cart;
        if (cart.hasCorrectMeetingTime) {
            this.goToNextStep();
        } else {
            this.setState({ showError: true });
        }
    };

    showPrevWeek = () => {
        const weeks = this.cartService.cart.schedule.schedule;
        this.state.dateTimeCurrentPage !== 0
            ? this.setState({
                  dateTimeCurrentPage: this.state.dateTimeCurrentPage - 1,
              })
            : this.setState({
                  dateTimeCurrentPage: weeks.length - 1,
              });
    };

    showNextWeek = () => {
        const weeks = this.cartService.cart.schedule.schedule;
        this.state.dateTimeCurrentPage < weeks.length - 1
            ? this.setState({
                  dateTimeCurrentPage: this.state.dateTimeCurrentPage + 1,
              })
            : this.setState({
                  dateTimeCurrentPage: 0,
              });
    };

    getErrorTranslateKey = () => {
        const cart = this.cartService.cart;
        return !cart.isMeetingTimeSet
            ? "newConferenceProcess.dateTime.warning"
            : "newConferenceProcess.dateTime.selectedRangeIsNotValid";
    };

    handleTimeCellClick = (time: moment.Moment) => {
        const cart = this.cartService.cart;
        if (!cart.schedule.isScheduleIncludes(time)) {
            return;
        }
        // Clears selected time if click on start of the range
        cart.setMeetingTime(cart.isStartTimePoint(time) ? null : time);
        this.setState({ showError: false });
    };

    static getNextStepComponentConstructor = (cart): any => {
        if (cart.isSmallTime) {
            return NewConferenceSmallTime;
        }

        if (cart.isExpress && !cart.skipExtra) {
            return NewConferenceExpress;
        }

        return NewConferenceDetailsView;
    };

    render() {
        const cart = this.cartService.cart;
        const weeks = cart.schedule.schedule;
        return (
            <div>
                <div id="create-concilium-container"></div>
                <div className="content content_margin-top">
                    <div className="create-concilium-form">
                        <div className="modal active" id="mfirst">
                            <div className="modal__body_ns" style={{ backgroundColor: "transparent" }}>
                                <button
                                    type="submit"
                                    className="modal__step modal__step_next screen_buttons form-action-button"
                                >
                                    <div className="modal__step--count_ns">
                                        {this.props.t("newConferenceProcess.payment.step_2")}.
                                    </div>
                                    <div className="modal__step--info">
                                        <div className="modal__step--name_ns">
                                            {this.props.t("newConferenceProcess.payment.detailsText")}
                                        </div>
                                        <img
                                            className="modal__step--arrow_ns"
                                            src="/static/images/arrowg.png"
                                            onClick={this.handleNextButtonClick}
                                        />
                                    </div>
                                </button>
                                <a className="modal__close_ns" onClick={() => this.cancel()}>
                                    <img src="/static/images/close.png" />
                                </a>
                                <div className="mfirst__top_ns modal__block__color-white">
                                    <div className="mfirst__line_ns">
                                        <div className="mfirst__column">
                                            <div className="mfirst__headline_ns">
                                                {this.props.t("newConferenceProcess.details.proposal")}{" "}
                                                {this.cartService.cart.isConcilium
                                                    ? this.props.t("newConferenceProcess.details.concilium")
                                                    : this.props.t("newConferenceProcess.details.consultation")}
                                            </div>
                                        </div>
                                        <div className="mfirst__column">
                                            <a onClick={() => this.goToPrevStep()}>
                                                <div className="mfirst__button_ns">
                                                    {this.props.t("newConferenceProcess.dateTime.edit")}
                                                </div>
                                            </a>
                                        </div>
                                    </div>
                                    <div className="msecond__list">
                                        <SpecialistsList t={this.props.t} />
                                        {this.cartService.cart.specialists.length == 0 && (
                                            <a href="/search/spec/concilium">
                                                <div className="mfirst__doc mfirst__doc_half">
                                                    <img
                                                        className="mfirst__doc--pic_ns"
                                                        src="/static/images/listadd.png"
                                                        alt=""
                                                    />
                                                    <div className="mfirst__doc--info">
                                                        <div className="mfirst__doc--name_ns">
                                                            {this.props.t("newConferenceProcess.specList.add") +
                                                                this.props.t("newConferenceProcess.specList.spec")}
                                                        </div>
                                                    </div>
                                                </div>
                                            </a>
                                        )}
                                    </div>
                                </div>
                                <div
                                    className="mfirst__bottom_ns modal__block__color-grey mfirst__bottom_ns--no-padd"
                                    style={{ backgroundColor: "rgba(245, 243, 241) !important" }}
                                >
                                    <div className="calendar__top">
                                        <a
                                            className="calendar__arrow--prev_ns"
                                            id="backBtn"
                                            onClick={this.showPrevWeek}
                                        >
                                            {"<"}
                                        </a>
                                        <div className="calendar__info">
                                            <div className="mfirst__headline_ns">
                                                {this.props.t("newConferenceProcess.dateTime.choiseDateTime")}{" "}
                                                {this.cartService.cart.isConcilium
                                                    ? this.props.t("newConferenceProcess.dateTime.conciliumLabel")
                                                    : this.props.t("newConferenceProcess.dateTime.consultationLabel")}
                                            </div>
                                            <div className="mfirst__headlineSmall_ns">
                                                {this.props.t("newConferenceProcess.dateTime.dateTimeText")}{" "}
                                                {this.cartService.cart.isConcilium
                                                    ? this.props.t("newConferenceProcess.dateTime.conciliumLabel")
                                                    : this.props.t("newConferenceProcess.dateTime.consultationLabel")}
                                                .
                                            </div>
                                        </div>
                                        <a
                                            className="calendar__arrow--next_ns"
                                            id="nextBtn"
                                            onClick={this.showNextWeek}
                                        >
                                            {">"}
                                        </a>
                                    </div>
                                    <div className="calendar__checkbox">
                                        <div className="mfirst__headline_ns">
                                            {this.props.t("newConferenceProcess.dateTime.duration")}{" "}
                                            {this.cartService.cart.isConcilium
                                                ? this.props.t("newConferenceProcess.dateTime.conciliumLabel")
                                                : this.props.t("newConferenceProcess.dateTime.consultationLabel")}
                                        </div>
                                        <div className="calendar__checkbox_wrap_ns">
                                            <div className="calendar__checkbox-item">
                                                <div className="mfirst__headlineSmall_ns">
                                                    {this.props.t("newConferenceProcess.dateTime.hour_1")}
                                                </div>
                                                <input
                                                    type="radio"
                                                    name="duration"
                                                    className="calendar_input-box_ns"
                                                    id="radio-box1"
                                                    value="2"
                                                    onClick={() => {
                                                        this.cartService.cart.setDuration(1);
                                                        this.forceUpdate();
                                                    }}
                                                    defaultChecked={this.cartService.cart.duration === 1}
                                                />
                                                <label htmlFor="radio-box1"></label>
                                            </div>
                                            <div className="calendar__checkbox-item">
                                                <div className="mfirst__headlineSmall_ns">
                                                    {this.props.t("newConferenceProcess.dateTime.hour_2")}
                                                </div>
                                                <input
                                                    type="radio"
                                                    name="duration"
                                                    className="calendar_input-box_ns"
                                                    id="radio-box2"
                                                    value="4"
                                                    onClick={() => {
                                                        this.cartService.cart.setDuration(2);
                                                        this.forceUpdate();
                                                    }}
                                                    defaultChecked={this.cartService.cart.duration === 2}
                                                />
                                                <label htmlFor="radio-box2"></label>
                                            </div>
                                            <div className="calendar__checkbox-item">
                                                <div className="mfirst__headlineSmall_ns">
                                                    {this.props.t("newConferenceProcess.dateTime.hour_3")}
                                                </div>
                                                <input
                                                    type="radio"
                                                    name="duration"
                                                    className="calendar_input-box_ns"
                                                    id="radio-box3"
                                                    value="6"
                                                    onClick={() => {
                                                        this.cartService.cart.setDuration(3);
                                                        this.forceUpdate();
                                                    }}
                                                    defaultChecked={this.cartService.cart.duration === 3}
                                                />
                                                <label htmlFor="radio-box3"></label>
                                            </div>
                                        </div>
                                    </div>
                                    {this.state.showError && (
                                        <div className="calendar__checkbox">
                                            <div className="mfirst__headline_ns" style={{ color: "red" }}>
                                                {this.props.t(this.getErrorTranslateKey())}
                                            </div>
                                        </div>
                                    )}
                                    {this.cartService.cart.hasSchedule && (
                                        <MultipleWeeksSwiperCalendar
                                            weeks={weeks}
                                            handleTimeCellClick={this.handleTimeCellClick.bind(this)}
                                            cart={this.cartService.cart}
                                            activeWeekIndex={this.state.dateTimeCurrentPage}
                                        ></MultipleWeeksSwiperCalendar>
                                    )}
                                    <div className="mfirst__bottom_NextPrev mobile_buttons mobile_buttons--pad">
                                        <button
                                            type="submit"
                                            className="modal__step modal__step_next-mobile mobile_buttons form-action-button"
                                            data-modal="msecond"
                                        >
                                            <div className="modal__step--count_ns">
                                                {this.props.t("newConferenceProcess.payment.step_2")}.
                                            </div>
                                            <div className="modal__step--info">
                                                <div className="modal__step--name_ns">
                                                    {this.props.t("newConferenceProcess.payment.detailsText")}
                                                </div>
                                                <img
                                                    className="modal__step--arrow_ns"
                                                    src="/static/images/arrowg.png"
                                                    onClick={this.handleNextButtonClick}
                                                />
                                            </div>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
