import { conciliumLink } from "../Config";
import { FileDTO } from "../domain/models/dto/FileDTO";
import { CustomFile } from "../domain/models/CustomFile";
import { FileService } from "../domain/services/FileService";
import fileMapperFactory from "../mappers/FileMapper";
import { deleteRequest, post } from "../tools/Tools";
import { BaseServiceImpl } from "./BaseServiceImpl";
import { ParentType } from "../enums/parent-type";

interface IRemoveFileResponse {
    status: boolean;
}

class FileServiceImpl extends BaseServiceImpl implements FileService {
    private baseFilesUrl = `${conciliumLink}/api/v1/files`;

    async uploadFile(file: CustomFile, parentId: string, parentType: ParentType = ParentType.CONCILIUM): Promise<CustomFile> {
        try {
            if (!file) {
                return null;
            }

            const mapper = fileMapperFactory();

            return mapper.fromDTO(
                this.getData<FileDTO>(
                    await post(this.baseFilesUrl, {
                        ...mapper.toDTO(file), parentType, parentId
                    })
                )
            );
        } catch (err) {
            console.log("FileServiceImpl.uploadFile", err);
        }
    }

    async removeFile(fileId: string): Promise<boolean> {
        try {
            if (!fileId) {
                return false;
            }

            return this.getData<IRemoveFileResponse>(
                await deleteRequest(`${this.baseFilesUrl}/${fileId}`), { status: false }
            ).status;
        } catch (err) {
            console.log("FileServiceImpl.removeFile", err);
        }
    }
}


export function fileServiceFactory(): FileService {
    return new FileServiceImpl();
}
