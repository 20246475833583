import * as queryString from "query-string";

export function getQueryParams() {
    return queryString.parse(window.location.search);
}

export function removeURLQueryParameter(url: string, parameterName: string) {
    if (!url || !parameterName) {
        return;
    }
    var r = new URL(url);
    r.searchParams.delete(parameterName);
    return r.href;
}

export function updateQueryParametersSilent(url: string) {
    if (history.pushState) {
        window.history.pushState({}, "", url);
    } else {
        document.location.href = url;
    }
}

export function removeCurrentURLQueryParameter(
    parameterName: string,
    reload?: boolean
) {
    if (!parameterName) {
        return;
    }
    const url = removeURLQueryParameter(window.location.href, parameterName);
    if (reload) {
        window.location.replace(url);
        return;
    }
    updateQueryParametersSilent(url);
}

export function redirect(pathname: string, forceReload = false) {
    const current = window.location.pathname;
    if (forceReload && (pathname === current)) {
        window.location.reload();
    } else {
        const url = new URL(pathname);
        window.location.replace(url);
    }
}
