import _ from "lodash";
import moment from "moment";
import { TIME_POINT_DURATION_MIN } from "../domain/constants/Constants";
import { Schedule } from "../domain/models/Schedule";

class ScheduleModel implements Schedule {
    schedule: Array<Array<Array<moment.Moment>>>;
    /**
     * list of available time points in local time
     */
    combinedSchedule: moment.Moment[];

    constructor() {
        this.schedule = this.generateWeeks();
    }

    get hasSchedule(): boolean {
        return !!this.schedule[0][0][0];
    };

    get hasCombinedSchedule(): boolean {
        return !_.isEmpty(this.combinedSchedule);
    }

    generateWeeks(weeksCount: number = 4) {
        const MIN_CONCILIUM_DURATION_MINUTES = 30;
        const DAY_IN_MINUTES = 24 * 60;

        const timePartsCount = Math.round(DAY_IN_MINUTES / MIN_CONCILIUM_DURATION_MINUTES);
        const weeks = [];
        const monday = this.getMonday();
        for (let weekIndex = 0; weekIndex < weeksCount; weekIndex++) {
            const days = [];
            const weekStart = monday.clone().add(7 * weekIndex, "days");

            for (let dayIndex = 0; dayIndex < 7; dayIndex++) {
                const timeParts = [];
                for (let timeIndex = 0; timeIndex < timePartsCount; timeIndex++) {
                    timeParts.push(
                        weekStart
                            .clone()
                            .add(dayIndex, "day")
                            .add(MIN_CONCILIUM_DURATION_MINUTES * timeIndex, "minutes")
                    );
                }
                days.push(timeParts);
            }
            weeks.push(days);
        }
        return weeks;
    }

    getMonday() {
        const monday = moment().day(1).hour(0).minute(0).second(0).millisecond(0); // 1 - Monday
        /**
         * Week starts on sunday. That mean monday will be in future.
         * If so need to get monday of previous week.
         */
        return monday > moment() ? monday.subtract(7, 'days') : monday;
    }

    /**
     * @param {moment.Moment[]} combinedSchedule - list of available time points in local time
     */
    setCombinedSchedule(combinedSchedule: moment.Moment[]) {
        this.combinedSchedule = combinedSchedule;
    }

    isScheduleIncludes(time: moment.Moment): boolean {
        return this.hasCombinedSchedule && !!this.combinedSchedule.find((timePart) => time.isSame(timePart));
    };

    getTimePoints(startTime: moment.Moment, endTime: moment.Moment): moment.Moment[] {
        const result = [];
        if (!startTime || !endTime || startTime >= endTime) {
            return result;
        }
        let timePoint = startTime.clone();
        let timePointEnd = timePoint.clone().add(TIME_POINT_DURATION_MIN, 'minutes');
        while (timePoint <= endTime && timePointEnd <= endTime) {
            result.push(timePoint);
            timePoint = timePointEnd;
            timePointEnd = timePoint.clone().add(TIME_POINT_DURATION_MIN, 'minutes');
        }
        return result;
    }

    isApplicableTimeRange(timePoints: moment.Moment[]): boolean {
        if (_.isEmpty(timePoints)) {
            return false;
        }

        return !timePoints.find(timePoint => !this.isScheduleIncludes(timePoint));
    }
}

export function scheduleFactory(): Schedule {
    return new ScheduleModel();
}
