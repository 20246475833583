import React from "react";
import ReactDOM from "react-dom";
import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import { translations } from "../../translations";
import cookie from "react-cookies";
import { DEFAULT_LANGUAGE } from "../../domain/constants/Constants";
import Root from "./Root";

i18n.use(initReactI18next).init({
    resources: translations,
    lng: cookie.load("locale") || DEFAULT_LANGUAGE,
});

const container = document.getElementById("react-app");
if (container) {
    ReactDOM.render(<Root />, container);
}
