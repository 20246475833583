import ImageUploading, { ImageListType } from "react-images-uploading";
import React, { FC, useState } from "react";
import { ParentType } from "../../enums/parent-type";
import { FileModel } from "../../models/FileModel";
import _ from "lodash";
import i18n from "i18next";
import { documentsAllowedExtensions, maxFileSizeBytes, maxFilesCount } from "../../Config";
import { ONE_MB_BYTES } from "../../domain/constants/Constants";
import { getExtensionByMimeType } from "../../tools/FileTools";

interface AddFileButtonProps {
    t(key?: string): string;
    parentType: ParentType;
    children: any;
    className: string;

    addImage(image: any): void;
    setError(error: string): void;

    currentFilesCount: number;

    allowedExtensions?: string[];
    maxSizeBytes?: number;
    maxFilesCount?: number;
}

const AddFileButton: FC<AddFileButtonProps> = (props) => {
    const [images, setImage] = useState<ImageListType>([]);

    const onChange = (newImages: ImageListType) => {
        const maxSize = props.maxSizeBytes || maxFileSizeBytes;
        const maxCount = props.maxFilesCount || maxFilesCount;
        const allowedExtensions = props.allowedExtensions || documentsAllowedExtensions;

        if (props.currentFilesCount >= maxCount) {
            return props.setError(
                i18n.t("errors.TOO_MANY_FILES", {
                    maxFilesCount: maxCount,
                })
            );
        }

        const image = newImages[0];
        if (!image.file) {
            return;
        }
        const file = new FileModel({
            dataUrl: image.dataURL,
            parentType: props.parentType,
            extension: getExtensionByMimeType(_.get(image, "file.type", "")),
            fileName: _.get(image, "file.name", "").split(".")[0],
        });
        file.setSize(_.get(image, "file.size", 0));

        if (!file.size) {
            return props.setError(i18n.t("errors.FILE_IS_EMPTY"));
        }

        if (!file.isExtensionValid(allowedExtensions)) {
            return props.setError(
                i18n.t("errors.FILE_EXTENSION_IS_NOT_ALLOWED", {
                    allowedExtensions: allowedExtensions.join(", "),
                })
            );
        }

        if (!file.isSizeValid(maxSize)) {
            return props.setError(
                i18n.t("errors.FILE_SIZE_IS_TOO_BIG", {
                    maxFileSize: (maxSize / ONE_MB_BYTES).toFixed(2),
                })
            );
        }

        setImage(newImages);
        props.addImage(file);
        props.setError("");
    };

    return (
        <div>
            <ImageUploading
                allowNonImageType={true}
                acceptType={props.allowedExtensions || documentsAllowedExtensions}
                value={images}
                onChange={onChange}
                multiple={false}
            >
                {({ onImageUpload, dragProps }) => (
                    <div onClick={onImageUpload} {...dragProps} className={props.className}>
                        {..._.isArray(props.children) ? props.children : [props.children]}
                    </div>
                )}
            </ImageUploading>
        </div>
    );
};

export default AddFileButton;
