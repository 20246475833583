import { useEffect, useState } from "react";
import { SLIDER_RUNNER_SPEED_MS } from "../domain/constants/Constants";

export function useSlideImages(
    listPagClass: string,
    listImgClass: string,
    activeClass = "active"
) {
    const [currentSlide, setCurrentSlide] = useState(0);

    useEffect(() => {
        const listPag = document.querySelectorAll(`.${listPagClass}`);
        const listImg = document.querySelectorAll(`.${listImgClass}`);

        function slide(slide = currentSlide) {
            listPag.forEach((el) => el.classList.remove(activeClass));
            listImg.forEach((el) => el.classList.remove(activeClass));
            if (listPag[slide]) listPag[slide].classList.add(activeClass);
            if (listImg[slide]) listImg[slide].classList.add(activeClass);
            setCurrentSlide(slide === listPag.length - 1 ? 0 : slide + 1);
        }

        const sliderRunner = setInterval(() => slide(), SLIDER_RUNNER_SPEED_MS);
        const eventHandlerFactory = (index) => {
            return () => slide(index);
        };

        listPag.forEach((el, index) => {
            el.addEventListener("click", eventHandlerFactory(index));
        });

        return () => {
            clearInterval(sliderRunner);
            listPag.forEach((el, index) =>
                el.removeEventListener("click", eventHandlerFactory(index))
            );
        };
    }, [currentSlide, listPagClass, listImgClass, activeClass]);
}
