import React, { FC } from "react";
import { Route, Routes } from "react-router-dom";
import UnAuthRoutes from "../../routes/UnAuthRoutes";
import { useAuth } from "../../context/AuthProvider";
import LoadingPanel from "../shared/LoadingPanel";
import { useRouterScroll } from "../../hooks/useRouterScroll";

interface ReactAppProps {}

const ReactApp: FC<ReactAppProps> = () => {
    const { loading } = useAuth();
    useRouterScroll();

    if (loading) {
        return <LoadingPanel isVisible={loading} />;
    }

    return (
        <Routes>
            <Route path="/*" element={<UnAuthRoutes />} />
        </Routes>
    );
};

export default ReactApp;
