import React from "react";
import { getDateStr } from "../../tools/DateTimeTools";
import { NewConferenceState } from "./NewConferenceState";

export class NewConferenceLatest extends NewConferenceState {
    cancel(): void {
        this.props.context.handlerCancel();
    }

    render() {
        const cart = this.cartService.cart;
        const time = cart.meetingTimeM;
        const specialists = cart.specialists;
        const firstSpecialist = cart.getMedicalSpecialists()[0];
        return (
            <div className="modal active">
                <div className="modal__body modal__body--advice advice">
                    <div className="comquest__top--right modal__close closeConciliumAddedModal">
                        <img src="/static/images/close.png" alt="" onClick={() => this.cancel()} />
                    </div>

                    <div className="advice__top">
                        <img
                            src={`/static/images${this.cartService.cart.isConcilium ? "/mm5.png" : "/mm4.png"}`}
                            alt=""
                        />
                        <div className="advice__top--headline">
                            {this.cartService.cart.isConcilium
                                ? this.props.t("newConferenceProcess.latest.concilium")
                                : this.props.t("newConferenceProcess.latest.consultation")}
                        </div>
                    </div>

                    <div className="advice__body">
                        {this.cartService.cart.isConcilium ? (
                            <>
                                <div className="payment__history--cons">
                                    {specialists.map((specialist) => {
                                        return (
                                            <div className="payment__history--cons_wrap" key={specialist.id}>
                                                <img
                                                    className="payment__history--icon"
                                                    src={specialist.user.imageUrl}
                                                    alt=""
                                                />
                                            </div>
                                        );
                                    })}
                                </div>
                                <div className="advice__dots">...</div>
                            </>
                        ) : (
                            <>
                                <div className="consilium__list--left" key={firstSpecialist.id}>
                                    <img className="consilium__photo" src={firstSpecialist.user.imageUrl} alt="" />
                                    <div className="consilium__photo--text">
                                        <div className="consilium__photo--name">{firstSpecialist.user.fullName}</div>
                                        <div className="consilium__photo--desc">{firstSpecialist.specialty.title}</div>
                                    </div>
                                </div>
                            </>
                        )}
                        <div className="advice__body--text">
                            {this.props.t("newConferenceProcess.latest.text_1")}
                            {getDateStr(time)} {this.props.t("newConferenceProcess.latest.text_2")}
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
