import { UserService } from "../domain/services/UserService";
import { userMapperFactory } from "../mappers/UserMapper";
import { conciliumLink, skipServerUtcToLocalConversion } from "../Config";
import { get, post } from "../tools/Tools";
import * as _ from "lodash";
import { User } from "../domain/models/User";
import { RegistrationForm } from "../domain/forms/RegistrationForm";
import { LoginForm } from "../domain/forms/LoginForm";

export class UserServiceImpl implements UserService {
    private static instance: UserService;
    private _user: User;

    private constructor() {}

    get user(): User {
        return this._user;
    }

    static getInstance() {
        if (!UserServiceImpl.instance) {
            UserServiceImpl.instance = new UserServiceImpl();
        }
        return UserServiceImpl.instance;
    }

    async getUser() {
        return userMapperFactory(skipServerUtcToLocalConversion).fromDTO(
            _.get(await get(`${conciliumLink || ""}/api/user`), "data")
        );
    }

    async getCachedUser(): Promise<User> {
        if (!this._user) {
            this._user = await this.getUser();
        }
        return this._user;
    }

    // async login(email: string, password: string): Promise<User | undefined> {
    //     if (!email || !password) {
    //         return;
    //     }

    //     this.currentUser = UserMapperFactory().fromDTO(this.getData<UserDTO>(await post(`${apiURL}/api/v1/login`, { email, password })));
    //     return this.currentUser;
    // }

    // async logout(): Promise<void> {
    //     return this.getData(await get(`${apiURL}/api/v1/logout`));
    // }

    async registration(data: RegistrationForm): Promise<User> {
        const mapper = userMapperFactory(skipServerUtcToLocalConversion);
        const dto = mapper.toRegistrationDTO(data);
        return mapper.fromDTO(
            _.get(
                await post(`${conciliumLink || ""}/api/registration`, dto),
                "data"
            )
        );
    }

    async login(data: LoginForm): Promise<User> {
        const mapper = userMapperFactory(skipServerUtcToLocalConversion);
        this._user = mapper.fromDTO(
            _.get(
                await post(`${conciliumLink || ""}/api/login`, {
                    email: data.email,
                    password: data.password,
                    recaptchaToken: data.recaptchaToken,
                }),
                "data"
            )
        );
        return this._user;
    }
}

export function userServiceFactory(): UserService {
    return UserServiceImpl.getInstance();
}
