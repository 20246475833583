import * as Yup from "yup";
import {
    SchemaFactoryProps,
    SchemaFactoryData,
    FieldsData,
} from "../models/SchemaFactory";
import { isValidPhoneNumber } from "react-phone-number-input";
import { dateValidate } from "./DateTimeSchemas";
import { checkAge } from "../tools/DateTimeTools";
import { MIN_REGISTRATION_AGE } from "../domain/constants/Constants";

export const registrationSchemaFactory = (
    props: SchemaFactoryProps
): SchemaFactoryData => {
    const t = props.t;

    const fields: FieldsData = {
        name: {
            min: 2,
            max: 20,
            placeholder: t("registration.fields.name"),
        },
        surname: {
            min: 2,
            max: 20,
            placeholder: t("registration.fields.surname"),
        },
        patronimyc: {
            min: 2,
            max: 20,
            placeholder: t("registration.fields.patronimyc"),
        },
        email: {
            min: 4,
            max: 64,
            placeholder: t("registration.fields.email"),
        },
        phone: {
            min: 7,
            max: 20,
            placeholder: t("registration.fields.phone"),
        },
        city: {
            min: 2,
            max: 64,
            placeholder: t("registration.fields.city"),
        },
        countryId: {
            min: 1,
            max: 36,
            placeholder: t("registration.fields.country"),
        },
        currencyId: {
            min: 1,
            max: 36,
            placeholder: t("registration.fields.currency"),
        },
        languageId: {
            min: 1,
            max: 36,
            placeholder: t("registration.fields.language"),
        },
        timezone: {
            min: 1,
            max: 36,
            placeholder: t("registration.fields.timezone"),
        },
        birthday: {
            min: 1,
            max: 36,
            placeholder: t("registration.fields.birthday"),
        },
        password: {
            min: 1,
            max: 50,
            placeholder: t("registration.fields.password"),
        },
        confirmPassword: {
            min: 1,
            max: 50,
            placeholder: t("registration.fields.confirmPassword"),
        },
    };

    return {
        schema: Yup.object().shape({
            name: Yup.string()
                .min(
                    fields.name.min,
                    t("errors.commonErrors.min") + fields.name.min
                )
                .max(
                    fields.name.max,
                    t("errors.commonErrors.max") + fields.name.max
                )
                .required(t("errors.commonErrors.required")),
            surname: Yup.string()
                .min(
                    fields.surname.min,
                    t("errors.commonErrors.min") + fields.surname.min
                )
                .max(
                    fields.surname.max,
                    t("errors.commonErrors.max") + fields.surname.max
                )
                .required(t("errors.commonErrors.required")),
            patronimyc: Yup.string()
                .min(
                    fields.patronimyc.min,
                    t("errors.commonErrors.min") + fields.patronimyc.min
                )
                .max(
                    fields.patronimyc.max,
                    t("errors.commonErrors.max") + fields.patronimyc.max
                ),
            email: Yup.string()
                .email(t("errors.commonErrors.invalidEmail"))
                .min(
                    fields.email.min,
                    t("errors.commonErrors.min") + fields.email.min
                )
                .max(
                    fields.email.max,
                    t("errors.commonErrors.max") + fields.email.max
                )
                .required(t("errors.commonErrors.required")),
            phone: Yup.string()
                .test(
                    "phone",
                    t("errors.commonErrors.invalidPhoneNumber"),
                    function (value) {
                        return value && isValidPhoneNumber(value);
                    }
                )
                .min(
                    fields.phone.min,
                    t("errors.commonErrors.min") + fields.phone.min
                )
                .max(
                    fields.phone.max,
                    t("errors.commonErrors.max") + fields.phone.max
                )
                .required(t("errors.commonErrors.required")),
            city: Yup.string()
                .min(
                    fields.city.min,
                    t("errors.commonErrors.min") + fields.city.min
                )
                .max(
                    fields.city.max,
                    t("errors.commonErrors.max") + fields.city.max
                )
                .required(t("errors.commonErrors.required")),
            countryId: Yup.string()
                .min(
                    fields.countryId.min,
                    t("errors.commonErrors.min") + fields.countryId.min
                )
                .max(
                    fields.countryId.max,
                    t("errors.commonErrors.max") + fields.countryId.max
                )
                .required(t("errors.commonErrors.required")),
            currencyId: Yup.string()
                .min(
                    fields.currencyId.min,
                    t("errors.commonErrors.min") + fields.currencyId.min
                )
                .max(
                    fields.currencyId.max,
                    t("errors.commonErrors.max") + fields.currencyId.max
                )
                .required(t("errors.commonErrors.required")),
            languageId: Yup.string()
                .min(
                    fields.languageId.min,
                    t("errors.commonErrors.min") + fields.languageId.min
                )
                .max(
                    fields.languageId.max,
                    t("errors.commonErrors.max") + fields.languageId.max
                )
                .required(t("errors.commonErrors.required")),
            timezone: Yup.string()
                .min(
                    fields.timezone.min,
                    t("errors.commonErrors.min") + fields.timezone.min
                )
                .max(
                    fields.timezone.max,
                    t("errors.commonErrors.max") + fields.timezone.max
                )
                .required(t("errors.commonErrors.required")),
            birthday: dateValidate(props).test(
                "checkAge",
                props.t("registration.errors.age", {
                    age: MIN_REGISTRATION_AGE,
                }),
                function (value) {
                    return checkAge(value, MIN_REGISTRATION_AGE);
                }
            ),
            password: Yup.string()
                .min(
                    fields.password.min,
                    t("errors.commonErrors.min") + fields.password.min
                )
                .max(
                    fields.password.max,
                    t("errors.commonErrors.max") + fields.password.max
                )
                .required(t("errors.commonErrors.required")),
            confirmPassword: Yup.string()
                .min(
                    fields.confirmPassword.min,
                    t("errors.commonErrors.min") + fields.confirmPassword.min
                )
                .max(
                    fields.confirmPassword.max,
                    t("errors.commonErrors.max") + fields.confirmPassword.max
                )
                .required(t("errors.commonErrors.required"))
                .test(
                    "passwordsMatch",
                    t("registration.errors.passwordMismatch"),
                    function (value) {
                        return this.parent.password === value;
                    }
                ),
        }),
        fields,
    };
};
