import React, { FC } from "react";
import { withTranslation } from "react-i18next";
import { User } from "../../../../domain/models/User";
import MobileMenuButtons from "./MobileMenuButtons";
import RouterLink from "../../../shared/RouterLink";

interface MainHeaderMenuButtonsProps {
    t(key?: string, values?: object): string;
    user?: User;
}

const MainHeaderMenuButtons: FC<MainHeaderMenuButtonsProps> = (props) => {
    return (
        <>
            {props.user.isAuthenticated ? (
                <MobileMenuButtons user={props.user} />
            ) : (
                <div className="header__login-wrap">
                    <div className="header__login">
                        <RouterLink path="/login">{props.t("mainHeader.login")}</RouterLink>
                    </div>
                    <div className="header__reg">
                        <RouterLink path="/registration">{props.t("mainHeader.registration")}</RouterLink>
                    </div>
                </div>
            )}
        </>
    );
};

export default withTranslation()(MainHeaderMenuButtons);
