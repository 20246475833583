import { Widget } from "../domain/models/Widget";
import { Langs } from "../enums/langs-enum";
import { WidgetPages } from "../enums/widget-pages-enum";
import { BaseModelImpl } from "./BaseModelImpl";

export class WidgetModel extends BaseModelImpl<Widget> implements Widget {
    id: string;
    lang: Langs;
    page: WidgetPages;
    block: string;
    blockName: string;
    title: string;
    body: string;
    sort: string;
}
