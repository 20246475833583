import * as _ from "lodash";
import { ResultWithCount } from "../domain/models/BaseResult";
import { PaginationParams } from "../domain/enums/PaginationParams";

export class BaseServiceImpl implements BaseServiceImpl {
    getData<T>(obj: any, defaultValue?: T): T {
        return BaseServiceImpl.getData(obj, defaultValue);
    }

    /**
     *
     * @param entities
     * @param getEntities
     * @param params
     * @param {boolean} reloadCache - used to define if need to make a request. Is a part of && condition with check if all rows are loaded, if selected page is not the last of if cache is empty.
     * @param {boolean} updateData - set to true if need to replace data in the cache, otherwise new data will be added to the common list.
     * @returns
     */
    protected async getPaginationData<
        Model extends ResultWithCount,
        SortingFields
    >(
        entities: Model,
        getEntities: (
            params: PaginationParams<SortingFields>
        ) => Promise<Model>,
        params: PaginationParams<SortingFields>,
        reloadCache: boolean = false,
        updateData: boolean = false
    ): Promise<Model> {
        const totalPages = entities.pages;
        const isEmptyRows = _.isEmpty(entities.rows);
        const isMaxRows = entities.rows.length === entities.totalRows;

        if (
            isEmptyRows ||
            updateData ||
            (reloadCache &&
                !isMaxRows &&
                (totalPages >= params.page || isEmptyRows))
        ) {
            const data = await getEntities({
                page: params.page,
                limit: params.limit,
                offset: params.offset,
                sortingField: params.sortingField,
                sortingOrder: params.sortingOrder,
                getCount: true,
            });

            if (updateData) {
                entities = data;
            } else {
                entities = {
                    ...data,
                    rows: entities.rows.concat(data.rows),
                };
            }
        }

        return entities;
    }

    static getData<T>(obj: any, defaultValue?: T): T {
        return _.get(obj, "data", defaultValue);
    }
}
